const saleActions = {
  SALES_GET_SEARCH_WAREHOUSE_PRODUCT_LIST: 'SALES_GET_SEARCH_WAREHOUSE_PRODUCT_LIST',
  SALES_GET_MODAL_WAREHOUSE_PRODUCT_LIST: 'SALES_GET_MODAL_WAREHOUSE_PRODUCT_LIST',
  SALES_GET_SALES_WAREHOUSE_PRODUCT_LIST: 'SALES_GET_SALES_WAREHOUSE_PRODUCT_LIST',
  SALES_RESET_SALES_WAREHOUSE_PRODUCT_LIST: 'SALES_RESET_SALES_WAREHOUSE_PRODUCT_LIST',
  SALES_ADD_SALES_WAREHOUSE_PRODUCT: 'SALES_ADD_SALES_WAREHOUSE_PRODUCT',
  SALES_REMOVE_SALES_WAREHOUSE_PRODUCT: 'SALES_REMOVE_SALES_WAREHOUSE_PRODUCT',
  SALES_UPDATE_SALES_WAREHOUSE_PRODUCT: 'SALES_UPDATE_SALES_WAREHOUSE_PRODUCT',
  SALES_UPDATE_SALES_TOTAL_PRICE: 'SALES_UPDATE_SALES_TOTAL_PRICE',
  SALES_UPDATE_OLD_SALES_TOTAL_PRICE: 'SALES_UPDATE_OLD_SALES_TOTAL_PRICE',
  SALES_TOGGLE_STOCK_MODAL: "SALES_TOGGLE_STOCK_MODAL",
  getSearchWarehouseProducts: (searchWarehouseProducts) => {
    return (dispatch, getState) => {
      dispatch({
        type: saleActions.SALES_GET_SEARCH_WAREHOUSE_PRODUCT_LIST,
        searchWarehouseProducts
      });
    };
  },
  getSalesWarehouseProducts: (salesWarehouseProducts) => {
    return (dispatch, getState) => {
      dispatch({
        type: saleActions.SALES_GET_SALES_WAREHOUSE_PRODUCT_LIST,
        salesWarehouseProducts
      });
    };
  },
  resetSalesWarehouseProducts: () => {
    return (dispatch, getState) => {
      dispatch({
        type: saleActions.SALES_RESET_SALES_WAREHOUSE_PRODUCT_LIST
      });
    };
  },
  getModalWarehouseProducts: (warehouseProducts) => {
    return (dispatch, getState) => {
      dispatch({
        type: saleActions.SALES_GET_MODAL_WAREHOUSE_PRODUCT_LIST,
        warehouseProducts
      });
    };
  },
  addSaleProduct: (warehouseProduct) => {
    return (dispatch, getState) => {
      dispatch({
        type: saleActions.SALES_ADD_SALES_WAREHOUSE_PRODUCT,
        warehouseProduct
      })
    }
  },
  updateSaleProduct: (warehouseProduct) => {
    return (dispatch, getState) => {
      dispatch({
        type: saleActions.SALES_UPDATE_SALES_WAREHOUSE_PRODUCT,
        warehouseProduct
      })
    }
  },
  removeSaleProduct: (warehouseProduct) => {
    return (dispatch, getState) => {
      dispatch({
        type: saleActions.SALES_REMOVE_SALES_WAREHOUSE_PRODUCT,
        warehouseProduct
      })
    }
  },
  updateTotalSalesPrice: (totalSalesPrice) => {
    return (dispatch, getState) => {
      dispatch({
        type: saleActions.SALES_UPDATE_SALES_TOTAL_PRICE,
        totalSalesPrice
      })
    }
  },
  updateOldTotalSalesPrice: (oldTotalSalesPrice) => {
    return (dispatch, getState) => {
      dispatch({
        type: saleActions.SALES_UPDATE_OLD_SALES_TOTAL_PRICE,
        oldTotalSalesPrice
      })
    }
  },
  toggleModal: () => {
    return (dispatch, getState) => {
      dispatch({
        type: saleActions.SALES_TOGGLE_STOCK_MODAL
      })
    }
  }
};
export default saleActions;