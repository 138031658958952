const customerActions = {
    CREATE_CUSTOMER: 'CREATE_CUSTOMER',
    UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
    GET_CUSTOMER: 'GET_CUSTOMER',
    GET_CUSTOMER_LIST: 'GET_CUSTOMER_LIST',
    EDIT_CUSTOMER: 'EDIT_CUSTOMER',
    DELETE_CUSTOMER: 'DELETE_CUSTOMER',
    CUSTOMER_TOGGLE_HANDLE_MODAL: 'CUSTOMER_TOGGLE_HANDLE_MODAL',
    CHANGE_CUSTOMER_PAGINATION: 'CHANGE_CUSTOMER_PAGINATION',
    CHANGE_CUSTOMER_COUNT: 'CHANGE_CUSTOMER_COUNT',
    createCustomer: customer => {
      return (dispatch, getState) => {
        dispatch({
          type: customerActions.CREATE_CUSTOMER,
          customers: [customer, ...getState().customer.customers],
        });
      };
    },
    updateCustomer: customer => {
      return (dispatch, getState) => {
        dispatch({
          type: customerActions.UPDATE_CUSTOMER,
          customer,
        });
      };
    },
    editCustomer: customer => {
      return (dispatch, getState) => {
        dispatch({
          type: customerActions.EDIT_CUSTOMER,
          customer,
        });
      };
    },
    getCustomers: (customers, customersCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: customerActions.GET_CUSTOMER_LIST,
          customers,
          customersCount
        });
      };
    },
    deleteCustomer: id => {
      return (dispatch, getState) => {
        var currentCustomers = getState().customer.customers.filter(s => s.id !== id);
        dispatch({
          type: customerActions.DELETE_CUSTOMER,
          customers: currentCustomers,
        });
      };
    },
    toggleModal: (customer = null) => ({
      type: customerActions.CUSTOMER_TOGGLE_HANDLE_MODAL,
      customer,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: customerActions.CHANGE_CUSTOMER_PAGINATION,
          pagination,
        });
      };
    },
    updateCustomersCount: customersCount => {
      return (dispatch, getState) => {
        dispatch({
          type: customerActions.CHANGE_CUSTOMER_COUNT,
          customersCount,
        });
      };
    }
  
  };
  export default customerActions;