const alesta_CampaignTypeActions = {
    CREATE_ALESTA_CAMPAIGNTYPE: 'CREATE_ALESTA_CAMPAIGNTYPE',
    UPDATE_ALESTA_CAMPAIGNTYPE: 'UPDATE_ALESTA_CAMPAIGNTYPE',
    GET_ALESTA_CAMPAIGNTYPE: 'GET_ALESTA_CAMPAIGNTYPE',
    GET_ALESTA_CAMPAIGNTYPE_LIST: 'GET_ALESTA_CAMPAIGNTYPE_LIST',
    EDIT_ALESTA_CAMPAIGNTYPE: 'EDIT_ALESTA_CAMPAIGNTYPE',
    DELETE_ALESTA_CAMPAIGNTYPE: 'DELETE_ALESTA_CAMPAIGNTYPE',
    ALESTA_CAMPAIGNTYPE_TOGGLE_HANDLE_MODAL: 'ALESTA_CAMPAIGNTYPE_TOGGLE_HANDLE_MODAL',
    CHANGE_ALESTA_CAMPAIGNTYPE_PAGINATION: 'CHANGE_ALESTA_CAMPAIGNTYPE_PAGINATION',
    CHANGE_ALESTA_CAMPAIGNTYPE_COUNT: 'CHANGE_ALESTA_CAMPAIGNTYPE_COUNT',
    createAlesta_CampaignType: alesta_CampaignType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CampaignTypeActions.CREATE_ALESTA_CAMPAIGNTYPE,
          alesta_CampaignTypes: [alesta_CampaignType, ...getState().alesta_CampaignType.alesta_CampaignTypes],
        });
      };
    },
    updateAlesta_CampaignType: alesta_CampaignType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CampaignTypeActions.UPDATE_ALESTA_CAMPAIGNTYPE,
          alesta_CampaignType,
        });
      };
    },
    editAlesta_CampaignType: alesta_CampaignType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CampaignTypeActions.EDIT_ALESTA_CAMPAIGNTYPE,
          alesta_CampaignType,
        });
      };
    },
    getAlesta_CampaignTypes: (alesta_CampaignTypes, alesta_CampaignTypesCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CampaignTypeActions.GET_ALESTA_CAMPAIGNTYPE_LIST,
          alesta_CampaignTypes,
          alesta_CampaignTypesCount
        });
      };
    },
    deleteAlesta_CampaignType: id => {
      return (dispatch, getState) => {
        var currentAlesta_CampaignTypes = getState().alesta_CampaignType.alesta_CampaignTypes.filter(s => s.id !== id);
        dispatch({
          type: alesta_CampaignTypeActions.DELETE_ALESTA_CAMPAIGNTYPE,
          alesta_CampaignTypes: currentAlesta_CampaignTypes,
        });
      };
    },
    toggleModal: (alesta_CampaignType = null) => ({
      type: alesta_CampaignTypeActions.ALESTA_CAMPAIGNTYPE_TOGGLE_HANDLE_MODAL,
      alesta_CampaignType,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CampaignTypeActions.CHANGE_ALESTA_CAMPAIGNTYPE_PAGINATION,
          pagination,
        });
      };
    },
    updateAlesta_CampaignTypesCount: alesta_CampaignTypesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CampaignTypeActions.CHANGE_ALESTA_CAMPAIGNTYPE_COUNT,
          alesta_CampaignTypesCount,
        });
      };
    }
  
  };
  export default alesta_CampaignTypeActions;