const erpUserSettingsActions = {
  ERP_USER_SETTINGS_SET_USER_SETTINGS: 'ERP_USER_SETTINGS_SET_USER_SETTINGS',
  ERP_USER_SETTINGS_EDIT_USER: 'ERP_USER_SETTINGS_EDIT_USER',
  ERP_USER_SETTINGS_GET_USER_LIST: 'ERP_USER_SETTINGS_GET_USER_LIST',
  ERP_USER_SETTINGS_GET_WAREHOUSE_LIST: 'ERP_USER_SETTINGS_GET_WAREHOUSE_LIST',
  ERP_USER_SETTINGS_GET_CUSTOMER_LIST: 'ERP_USER_SETTINGS_GET_CUSTOMER_LIST',
  ERP_USER_SETTINGS_TOGGLE_HANDLE_MODAL: 'ERP_USER_SETTINGS_TOGGLE_HANDLE_MODAL',
  ERP_USER_SETTINGS_CHANGE_USER_PAGINATION: 'ERP_USER_SETTINGS_CHANGE_USER_PAGINATION',
  ERP_USER_SETTINGS_CHANGE_USER_COUNT: 'ERP_USER_SETTINGS_CHANGE_USER_COUNT',
  setUserSettings: user => {
    return (dispatch, getState) => {
      dispatch({
        type: erpUserSettingsActions.ERP_USER_SETTINGS_SET_USER_SETTINGS,
        user,
      });
    };
  },
  editUser: user => {
    return (dispatch, getState) => {
      dispatch({
        type: erpUserSettingsActions.ERP_USER_SETTINGS_EDIT_USER,
        user,
      });
    };
  },
  getUsers: (users, usersCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: erpUserSettingsActions.ERP_USER_SETTINGS_GET_USER_LIST,
        users,
        usersCount
      });
    };
  },
  getWarehouses: (warehouses) => {
    return (dispatch, getState) => {
      dispatch({
        type: erpUserSettingsActions.ERP_USER_SETTINGS_GET_WAREHOUSE_LIST,
        warehouses
      });
    };
  },
  getCustomers: (customers) => {
    return (dispatch, getState) => {
      dispatch({
        type: erpUserSettingsActions.ERP_USER_SETTINGS_GET_CUSTOMER_LIST,
        customers
      });
    };
  },
  toggleModal: (user = null) => ({
    type: erpUserSettingsActions.ERP_USER_SETTINGS_TOGGLE_HANDLE_MODAL,
    user,
  }),
  changePagination: pagination => {
    return (dispatch, getState) => {
      dispatch({
        type: erpUserSettingsActions.ERP_USER_SETTINGS_CHANGE_USER_PAGINATION,
        pagination,
      });
    };
  },
  updateCountriesCount: usersCount => {
    return (dispatch, getState) => {
      dispatch({
        type: erpUserSettingsActions.ERP_USER_SETTINGS_CHANGE_USER_COUNT,
        usersCount,
      });
    };
  }

};
export default erpUserSettingsActions;