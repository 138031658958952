import alesta_CompanyActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  alesta_Companys: [],
  alesta_CompanysCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id',
    descending:true,
  },
  alesta_Company: {
  }
};

export default function alesta_CompanyReducer(state = initState, action) {
  switch (action.type) {
    case alesta_CompanyActions.CREATE_ALESTA_COMPANY :
      initState.alesta_Company = {}
      return {
        ...state,
        alesta_Companys: action.alesta_Companys,
        alesta_Company: initState.alesta_Company
      };
    case alesta_CompanyActions.UPDATE_ALESTA_COMPANY :
      var alesta_CompanyIndex = state.alesta_Companys.findIndex(u => u.id === action.alesta_Company.id);
      var alesta_Companys = state.alesta_Companys;
      alesta_Companys[alesta_CompanyIndex] = action.alesta_Company;
      return {
        ...state,
        alesta_Companys:[...alesta_Companys]
      };
    case alesta_CompanyActions.EDIT_ALESTA_COMPANY :
      return {
        ...state,
        alesta_Company: action.alesta_Company,
      };
    case alesta_CompanyActions.GET_ALESTA_COMPANY_LIST:
      return {
        ...state,
        alesta_Companys: action.alesta_Companys,
        alesta_CompanysCount: action.alesta_CompanysCount,
      };
    case alesta_CompanyActions.DELETE_ALESTA_COMPANY :
      return {
        ...state,
        alesta_Companys: action.alesta_Companys,
      };
    case alesta_CompanyActions.ALESTA_COMPANY_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        alesta_Company: action.alesta_Company === null ? initState.alesta_Company : action.alesta_Company,
      };
    case alesta_CompanyActions.CHANGE_ALESTA_COMPANY_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case alesta_CompanyActions.CHANGE_ALESTA_COMPANY_COUNT:
      return {
        ...state,
        alesta_CompanysCount: action.alesta_CompanysCount,
      };
    default:
      return state;
  }
}
