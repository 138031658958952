const productActions = {
    CREATE_PRODUCT: 'CREATE_PRODUCT',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT',
    GET_PRODUCT: 'GET_PRODUCT',
    GET_PRODUCT_LIST: 'GET_PRODUCT_LIST',
    EDIT_PRODUCT: 'EDIT_PRODUCT',
    DELETE_PRODUCT: 'DELETE_PRODUCT',
    PRODUCT_TOGGLE_HANDLE_MODAL: 'PRODUCT_TOGGLE_HANDLE_MODAL',
    PRODUCT_TOGGLE2_HANDLE_MODAL: 'PRODUCT_TOGGLE2_HANDLE_MODAL',
    CHANGE_PRODUCT_PAGINATION: 'CHANGE_PRODUCT_PAGINATION',
    CHANGE_PRODUCT_COUNT: 'CHANGE_PRODUCT_COUNT',
    createProduct: product => {
        return (dispatch, getState) => {
            dispatch({
                type: productActions.CREATE_PRODUCT,
                products: [product, ...getState().product.products],
            });
        };
    },
    updateProduct: product => {
        return (dispatch, getState) => {
            dispatch({
                type: productActions.UPDATE_PRODUCT,
                product,
            });
        };
    },
    editProduct: product => {
        return (dispatch, getState) => {
            dispatch({
                type: productActions.EDIT_PRODUCT,
                product,
            });
        };
    },
    getProducts: (products, productsCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: productActions.GET_PRODUCT_LIST,
                products,
                productsCount
            });
        };
    },
    deleteProduct: id => {
        return (dispatch, getState) => {
            var currentProducts = getState().product.products.filter(s => s.id !== id);
            dispatch({
                type: productActions.DELETE_PRODUCT,
                products: currentProducts,
            });
        };
    },
    toggleModal: (product = null) => ({
        type: productActions.PRODUCT_TOGGLE_HANDLE_MODAL,
        product,
    }),
    toggleModal2: (product = null) => ({
        type: productActions.PRODUCT_TOGGLE2_HANDLE_MODAL,
        product,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: productActions.CHANGE_PRODUCT_PAGINATION,
                pagination,
            });
        };
    },
    updateProductsCount: productsCount => {
        return (dispatch, getState) => {
            dispatch({
                type: productActions.CHANGE_PRODUCT_COUNT,
                productsCount,
            });
        };
    }

};
export default productActions;