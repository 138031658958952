import wholesaleStockDistributeActions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  warehouseProducts: [],
  warehouses: [],
  sourceWarehouse: {},
  distributes: [],
  distributesCount: 0,
  pagination: {
    currentPage: 1,
    descending: false,
    skip: 0,
    take: 10,
    search: '',
    sort: 'name'
  },
  transfer: {}
};

export default function wholesaleStockDistributeReducer(state = initState, action) {
  switch (action.type) {
    case wholesaleStockDistributeActions.GET_DISTRIBUTE_WAREHOUSE_PRODUCTS:
      return {
        ...state,
        warehouseProducts: action.warehouseProducts
      };
    case wholesaleStockDistributeActions.GET_DISTRIBUTE_WAREHOUSE_LIST:
      return {
        ...state,
        warehouses: action.warehouses
      };
    case wholesaleStockDistributeActions.GET_DISTRIBUTE_LIST:
      return {
        ...state,
        distributes: action.distributes
      };
    case wholesaleStockDistributeActions.GET_SOURCE_WAREHOUSE:
      return {
        ...state,
        sourceWarehouse: action.sourceWarehouse
      };
    case wholesaleStockDistributeActions.ADD_DISTRIBUTE:
      return {
        ...state,
        distributes: [action.distribute, ...state.distributes],
        distributesCount: (state.distributesCount + 1)
      };
    case wholesaleStockDistributeActions.UPDATE_DISTRIBUTE:
      let newTransfers = state.distributes.map(distribute => {
        if (distribute.id === action.distribute.id) {
          return Object.assign(distribute, action.distribute)
        } else {
         return distribute
        }
      })
      return {
        ...state,
        distributes: newTransfers
      };
    case wholesaleStockDistributeActions.REMOVE_DISTRIBUTE:
      let distributes = state.distributes.filter(distribute => distribute.id !== action.distribute.id)
      return {
        ...state,
        distributes,
        distributesCount: (state.distributes - 1)
      }
    case wholesaleStockDistributeActions.DISTRIBUTE_TOGGLE_STOCK_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive
      }
    default:
      return state;
  }
}
