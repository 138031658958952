const clientActions = {
    CREATE_CLIENT: 'CREATE_CLIENT',
    UPDATE_CLIENT: 'UPDATE_CLIENT',
    GET_CLIENT: 'GET_CLIENT',
    GET_CLIENT_LIST: 'GET_CLIENT_LIST',
    EDIT_CLIENT: 'EDIT_CLIENT',
    DELETE_CLIENT: 'DELETE_CLIENT',
    CLIENT_TOGGLE_HANDLE_MODAL: 'CLIENT_TOGGLE_HANDLE_MODAL',
    CHANGE_CLIENT_PAGINATION: 'CHANGE_CLIENT_PAGINATION',
    CHANGE_CLIENT_COUNT: 'CHANGE_CLIENT_COUNT',
    createClient: client => {
      return (dispatch, getState) => {
        dispatch({
          type: clientActions.CREATE_CLIENT,
          clients: [client, ...getState().client.clients],
        });
      };
    },
    updateClient: client => {
      return (dispatch, getState) => {
        dispatch({
          type: clientActions.UPDATE_CLIENT,
          client,
        });
      };
    },
    editClient: client => {
      return (dispatch, getState) => {
        dispatch({
          type: clientActions.EDIT_CLIENT,
          client,
        });
      };
    },
    getClients: (clients, clientsCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: clientActions.GET_CLIENT_LIST,
          clients,
          clientsCount
        });
      };
    },
    deleteClient: id => {
      return (dispatch, getState) => {
        var currentClients = getState().client.clients.filter(s => s.id !== id);
        dispatch({
          type: clientActions.DELETE_CLIENT,
          clients: currentClients,
        });
      };
    },
    toggleModal: (client = null) => ({
      type: clientActions.CLIENT_TOGGLE_HANDLE_MODAL,
      client,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: clientActions.CHANGE_CLIENT_PAGINATION,
          pagination,
        });
      };
    },
    updateClientsCount: clientsCount => {
      return (dispatch, getState) => {
        dispatch({
          type: clientActions.CHANGE_CLIENT_COUNT,
          clientsCount,
        });
      };
    }
  
  };
  export default clientActions;