const alesta_CountryActions = {
    CREATE_ALESTA_COUNTRY: 'CREATE_ALESTA_COUNTRY',
    UPDATE_ALESTA_COUNTRY: 'UPDATE_ALESTA_COUNTRY',
    GET_ALESTA_COUNTRY: 'GET_ALESTA_COUNTRY',
    GET_ALESTA_COUNTRY_LIST: 'GET_ALESTA_COUNTRY_LIST',
    EDIT_ALESTA_COUNTRY: 'EDIT_ALESTA_COUNTRY',
    DELETE_ALESTA_COUNTRY: 'DELETE_ALESTA_COUNTRY',
    ALESTA_COUNTRY_TOGGLE_HANDLE_MODAL: 'ALESTA_COUNTRY_TOGGLE_HANDLE_MODAL',
    CHANGE_ALESTA_COUNTRY_PAGINATION: 'CHANGE_ALESTA_COUNTRY_PAGINATION',
    CHANGE_ALESTA_COUNTRY_COUNT: 'CHANGE_ALESTA_COUNTRY_COUNT',
    createAlesta_Country: alesta_Country => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CountryActions.CREATE_ALESTA_COUNTRY,
          alesta_Countrys: [alesta_Country, ...getState().alesta_Country.alesta_Countrys],
        });
      };
    },
    updateAlesta_Country: alesta_Country => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CountryActions.UPDATE_ALESTA_COUNTRY,
          alesta_Country,
        });
      };
    },
    editAlesta_Country: alesta_Country => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CountryActions.EDIT_ALESTA_COUNTRY,
          alesta_Country,
        });
      };
    },
    getAlesta_Countrys: (alesta_Countrys, alesta_CountrysCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CountryActions.GET_ALESTA_COUNTRY_LIST,
          alesta_Countrys,
          alesta_CountrysCount
        });
      };
    },
    deleteAlesta_Country: id => {
      return (dispatch, getState) => {
        var currentAlesta_Countrys = getState().alesta_Country.alesta_Countrys.filter(s => s.id !== id);
        dispatch({
          type: alesta_CountryActions.DELETE_ALESTA_COUNTRY,
          alesta_Countrys: currentAlesta_Countrys,
        });
      };
    },
    toggleModal: (alesta_Country = null) => ({
      type: alesta_CountryActions.ALESTA_COUNTRY_TOGGLE_HANDLE_MODAL,
      alesta_Country,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CountryActions.CHANGE_ALESTA_COUNTRY_PAGINATION,
          pagination,
        });
      };
    },
    updateAlesta_CountrysCount: alesta_CountrysCount => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CountryActions.CHANGE_ALESTA_COUNTRY_COUNT,
          alesta_CountrysCount,
        });
      };
    }
  
  };
  export default alesta_CountryActions;