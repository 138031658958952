const orderActions = {
  CREATE_ORDER: 'CREATE_ORDER',
  UPDATE_ORDER: 'UPDATE_ORDER',
  UPDATE_DATETIME_RANGE_PICKER: 'UPDATE_DATETIME_RANGE_PICKER',
  GET_ORDER: 'GET_ORDER',
  GET_ORDER_LIST: 'GET_ORDER_LIST',
  GET_ORDER_CUSTOMERS_LIST: 'GET_ORDER_CUSTOMERS_LIST',
  GET_ORDER_WAREHOUSES_LIST: 'GET_ORDER_WAREHOUSES_LIST',
  EDIT_ORDER: 'EDIT_ORDER',
  DELETE_ORDER: 'DELETE_ORDER',
  ORDER_TOGGLE_HANDLE_MODAL: 'ORDER_TOGGLE_HANDLE_MODAL',
  CHANGE_ORDER_PAGINATION: 'CHANGE_ORDER_PAGINATION',
  CHANGE_ORDER_COUNT: 'CHANGE_ORDER_COUNT',
  createOrder: order => {
    return (dispatch, getState) => {
      dispatch({
        type: orderActions.CREATE_ORDER,
        orders: [order, ...getState().order.orders],
      });
    };
  },
  updateOrder: order => {
    return (dispatch, getState) => {
      dispatch({
        type: orderActions.UPDATE_ORDER,
        order,
      });
    };
  },
  updateDateTimeRangePicker: dateTimeRangePicker => {
    return (dispatch, getState) => {
      dispatch({
        type: orderActions.UPDATE_DATETIME_RANGE_PICKER,
        dateTimeRangePicker,
      });
    };
  },
  editOrder: order => {
    return (dispatch, getState) => {
      dispatch({
        type: orderActions.EDIT_ORDER,
        order,
      });
    };
  },
  getOrders: (orders, ordersCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: orderActions.GET_ORDER_LIST,
        orders,
        ordersCount
      });
    };
  },
  getCustomers: (customers) => {
    return (dispatch, getState) => {
      dispatch({
        type: orderActions.GET_ORDER_CUSTOMERS_LIST,
        customers
      });
    };
  },
  getWarehouses: (warehouses) => {
    return (dispatch, getState) => {
      dispatch({
        type: orderActions.GET_ORDER_WAREHOUSES_LIST,
        warehouses
      });
    };
  },
  deleteOrder: id => {
    return (dispatch, getState) => {
      let currentCountries = getState().order.orders.filter(s => s.id !== id);
      dispatch({
        type: orderActions.DELETE_ORDER,
        orders: currentCountries,
      });
    };
  },
  toggleModal: (order = null) => ({
    type: orderActions.ORDER_TOGGLE_HANDLE_MODAL,
    order,
  }),
  changePagination: pagination => {
    return (dispatch, getState) => {
      dispatch({
        type: orderActions.CHANGE_ORDER_PAGINATION,
        pagination,
      });
    };
  },
  updateOrdersCount: ordersCount => {
    return (dispatch, getState) => {
      dispatch({
        type: orderActions.CHANGE_ORDER_COUNT,
        ordersCount,
      });
    };
  }

};
export default orderActions;