const locationActions = {
    CREATE_LOCATION: 'CREATE_LOCATION',
    UPDATE_LOCATION: 'UPDATE_LOCATION',
    GET_LOCATION: 'GET_LOCATION',
    GET_LOCATION_LIST: 'GET_LOCATION_LIST',
    EDIT_LOCATION: 'EDIT_LOCATION',
    DELETE_LOCATION: 'DELETE_LOCATION',
    LOCATION_TOGGLE_HANDLE_MODAL: 'LOCATION_TOGGLE_HANDLE_MODAL',
    LOCATION_TOGGLE2_HANDLE_MODAL: 'LOCATION_TOGGLE2_HANDLE_MODAL',
    CHANGE_LOCATION_PAGINATION: 'CHANGE_LOCATION_PAGINATION',
    CHANGE_LOCATION_COUNT: 'CHANGE_LOCATION_COUNT',
    createLocation: location => {
        return (dispatch, getState) => {
            dispatch({
                type: locationActions.CREATE_LOCATION,
                locations: [location, ...getState().location.locations],
            });
        };
    },
    updateLocation: location => {
        return (dispatch, getState) => {
            dispatch({
                type: locationActions.UPDATE_LOCATION,
                location,
            });
        };
    },
    editLocation: location => {
        return (dispatch, getState) => {
            dispatch({
                type: locationActions.EDIT_LOCATION,
                location,
            });
        };
    },
    getLocations: (locations, locationsCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: locationActions.GET_LOCATION_LIST,
                locations,
                locationsCount
            });
        };
    },
    deleteLocation: id => {
        return (dispatch, getState) => {
            var currentLocations = getState().location.locations.filter(s => s.id !== id);
            dispatch({
                type: locationActions.DELETE_LOCATION,
                locations: currentLocations,
            });
        };
    },
    toggleModal: (location = null) => ({
        type: locationActions.LOCATION_TOGGLE_HANDLE_MODAL,
        location,
    }),
    toggleModal2: (location = null) => ({
        type: locationActions.LOCATION_TOGGLE2_HANDLE_MODAL,
        location,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: locationActions.CHANGE_LOCATION_PAGINATION,
                pagination,
            });
        };
    },
    updateLocationsCount: locationsCount => {
        return (dispatch, getState) => {
            dispatch({
                type: locationActions.CHANGE_LOCATION_COUNT,
                locationsCount,
            });
        };
    }
}
export default locationActions;