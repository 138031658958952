import medicineActions from "./actions";
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  medicines: [],
  medicinesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: "",
    sort: "id",
    desc: true,
  },
  medicine: {},
};

export default function medicineReducer(state = initState, action) {
  switch (action.type) {
    case medicineActions.CREATE_MEDICINE:
      initState.medicine = {};
      return {
        ...state,
        medicines: action.medicines,
        medicine: initState.medicine,
      };
    case medicineActions.UPDATE_MEDICINE:
      var medicineIndex = state.medicines.findIndex((u) => u.id === action.medicine.id);
      var medicines = state.medicines;
      medicines[medicineIndex] = action.medicine;
      return {
        ...state,
        medicines: [...medicines],
      };
    case medicineActions.EDIT_MEDICINE:
      return {
        ...state,
        medicine: action.medicine,
      };
    case medicineActions.GET_MEDICINE_LIST:
      return {
        ...state,
        medicines: action.medicines,
        medicinesCount: action.medicinesCount,
      };
    case medicineActions.DELETE_MEDICINE:
      return {
        ...state,
        medicines: action.medicines,
      };
    case medicineActions.MEDICINE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        medicine: action.medicine === null ? initState.medicine : action.medicine,
      };
    case medicineActions.CHANGE_MEDICINE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case medicineActions.CHANGE_MEDICINE_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}

