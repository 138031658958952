const productActions = {
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  GET_PRODUCT: 'GET_PRODUCT',
  GET_PRODUCT_LIST: 'GET_PRODUCT_LIST',
  GET_PRODUCT_TAG_LIST: 'GET_PRODUCT_TAG_LIST',
  GET_PRODUCT_UNIT_TYPE_LIST: 'GET_PRODUCT_UNIT_TYPE_LIST',
  GET_PRODUCT_WAREHOUSE_LIST: 'GET_PRODUCT_WAREHOUSE_LIST',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  RESET_PRODUCT: 'RESET_PRODUCT',
  PRODUCT_TOGGLE_HANDLE_MODAL: 'PRODUCT_TOGGLE_HANDLE_MODAL',
  CHANGE_PRODUCT_PAGINATION: 'CHANGE_PRODUCT_PAGINATION',
  CHANGE_PRODUCT_COUNT: 'CHANGE_PRODUCT_COUNT',
  createProduct: product => {
    return (dispatch, getState) => {
      dispatch({
        type: productActions.CREATE_PRODUCT,
        products: [product, ...getState().product.products],
      });
    };
  },
  updateProduct: product => {
    return (dispatch, getState) => {
      dispatch({
        type: productActions.UPDATE_PRODUCT,
        product,
      });
    };
  },
  editProduct: product => {
    return (dispatch, getState) => {
      dispatch({
        type: productActions.EDIT_PRODUCT,
        product,
      });
    };
  },
  getProducts: (products, productsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: productActions.GET_PRODUCT_LIST,
        products,
        productsCount
      });
    };
  },
  getTags: (tags) => {
    return (dispatch, getState) => {
      dispatch({
        type: productActions.GET_PRODUCT_TAG_LIST,
        tags,
      });
    };
  },
  getUnitTypes: (unitTypes) => {
    return (dispatch, getState) => {
      dispatch({
        type: productActions.GET_PRODUCT_UNIT_TYPE_LIST,
        unitTypes,
      });
    };
  },
  getWarehouses: (warehouses) => {
    return (dispatch, getState) => {
      dispatch({
        type: productActions.GET_PRODUCT_WAREHOUSE_LIST,
        warehouses
      });
    };
  },
  deleteProduct: id => {
    return (dispatch, getState) => {
      let currentProducts = getState().product.products.filter(s => s.id !== id);
      dispatch({
        type: productActions.DELETE_PRODUCT,
        products: currentProducts,
      });
    };
  },
  resetProduct: () => {
    return (dispatch, getState) => {
      dispatch({
        type: productActions.RESET_PRODUCT,
      });
    };
  },
  toggleModal: (product = null) => ({
    type: productActions.PRODUCT_TOGGLE_HANDLE_MODAL,
    product,
  }),
  changePagination: pagination => {
    return (dispatch, getState) => {
      dispatch({
        type: productActions.CHANGE_PRODUCT_PAGINATION,
        pagination,
      });
    };
  },
  updateProductsCount: productsCount => {
    return (dispatch, getState) => {
      dispatch({
        type: productActions.CHANGE_PRODUCT_COUNT,
        productsCount,
      });
    };
  }

};
export default productActions;