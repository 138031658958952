const logActions = {
    GET_LOG: 'GET_LOG',
    GET_LOG_LIST: 'GET_LOG_LIST',
    CHANGE_LOG_PAGINATION: 'CHANGE_LOG_PAGINATION',
    CHANGE_LOG_COUNT: 'CHANGE_LOG_COUNT',
    LOG_TOGGLE_HANDLE_MODAL: 'LOG_TOGGLE_HANDLE_MODAL',
    EDIT_LOG: 'EDIT_LOG',

    getLogs: (logs, logsCount) => {
        return(dispatch, getState) => {
            dispatch({
                type: logActions.GET_LOG_LIST,
                logs,
                logsCount
            });
        };
    },
    changePagination: pagination => {
        return(dispatch, getState) => {
            dispatch({
                type: logActions.CHANGE_LOG_PAGINATION,
                pagination,
            });
        };
    },
    toggleModal: (log = null) => ({
        type: logActions.LOG_TOGGLE_HANDLE_MODAL,
        log,
    }),
    updateLogsCount: logsCount => {
        return  (dispatch, getState) => {
            dispatch({
                type: logActions.CHANGE_LOG_COUNT,
                logsCount,
            });
        };
    },
    editLogs: log => {
        return (dispatch, getState) => {
            dispatch({
                type: logActions.EDIT_LOG,
                log,
            });
        };
    }
}    
export default logActions;