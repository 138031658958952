import alesta_WarehouseActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  alesta_Warehouses: [],
  alesta_WarehousesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id',
    descending:true,
  },
  alesta_Warehouse: {
  }
};

export default function alesta_WarehouseReducer(state = initState, action) {
  switch (action.type) {
    case alesta_WarehouseActions.CREATE_ALESTA_WAREHOUSE :
      initState.alesta_Warehouse = {}
      return {
        ...state,
        alesta_Warehouses: action.alesta_Warehouses,
        alesta_Warehouse: initState.alesta_Warehouse
      };
    case alesta_WarehouseActions.UPDATE_ALESTA_WAREHOUSE :
      var alesta_WarehouseIndex = state.alesta_Warehouses.findIndex(u => u.id === action.alesta_Warehouse.id);
      var alesta_Warehouses = state.alesta_Warehouses;
      alesta_Warehouses[alesta_WarehouseIndex] = action.alesta_Warehouse;
      return {
        ...state,
        alesta_Warehouses:[...alesta_Warehouses]
      };
    case alesta_WarehouseActions.EDIT_ALESTA_WAREHOUSE :
      return {
        ...state,
        alesta_Warehouse: action.alesta_Warehouse,
      };
    case alesta_WarehouseActions.GET_ALESTA_WAREHOUSE_LIST:
      
      return {
        ...state,
        alesta_Warehouses: action.alesta_Warehouses,
        alesta_WarehousesCount: action.alesta_WarehousesCount,
      };
    case alesta_WarehouseActions.DELETE_ALESTA_WAREHOUSE :
      return {
        ...state,
        alesta_Warehouses: action.alesta_Warehouses,
      };
    case alesta_WarehouseActions.ALESTA_WAREHOUSE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        alesta_Warehouse: action.alesta_Warehouse === null ? initState.alesta_Warehouse : action.alesta_Warehouse,
      };
    case alesta_WarehouseActions.CHANGE_ALESTA_WAREHOUSE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case alesta_WarehouseActions.CHANGE_ALESTA_WAREHOUSE_COUNT:
      return {
        ...state,
        alesta_WarehousesCount: action.alesta_WarehousesCount,
      };
    default:
      return state;
  }
}
