const sidebarmenuActions = {
    CREATE_SIDEBARMENU: 'CREATE_SIDEBARMENU',
    UPDATE_SIDEBARMENU: 'UPDATE_SIDEBARMENU',
    GET_SIDEBARMENU: 'GET_SIDEBARMENU',
    GET_SIDEBARMENU_LIST: 'GET_SIDEBARMENU_LIST',
    GET_ALLSIDEBARMENU_LIST: 'GET_ALLSIDEBARMENU_LIST',
    GET_SIDEBARMENU_TREE: 'GET_SIDEBARMENU_TREE',
    EDIT_SIDEBARMENU: 'EDIT_SIDEBARMENU',
    DELETE_SIDEBARMENU: 'DELETE_SIDEBARMENU',
    SIDEBARMENU_TOGGLE_HANDLE_MODAL: 'SIDEBARMENU_TOGGLE_HANDLE_MODAL',
    CHANGE_SIDEBARMENU_PAGINATION: 'CHANGE_SIDEBARMENU_PAGINATION',
    CHANGE_SIDEBARMENU_COUNT: 'CHANGE_SIDEBARMENU_COUNT',
    createSidebarMenu: sidebarmenu => {
      return (dispatch, getState) => {
        dispatch({
          type: sidebarmenuActions.CREATE_SIDEBARMENU,
          sidebarmenus: [sidebarmenu, ...getState().sidebarmenu.sidebarmenus],
        });
      };
    },
    updateSidebarMenu: sidebarmenu => {
      return (dispatch, getState) => {
        dispatch({
          type: sidebarmenuActions.UPDATE_SIDEBARMENU,
          sidebarmenu,
        });
      };
    },
    editSidebarMenu: sidebarmenu => {
      return (dispatch, getState) => {
        dispatch({
          type: sidebarmenuActions.EDIT_SIDEBARMENU,
          sidebarmenu,
        });
      };
    },
    getSidebarMenus: (sidebarmenus, sidebarmenusCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: sidebarmenuActions.GET_SIDEBARMENU_LIST,
          sidebarmenus,
          sidebarmenusCount
        });
      };
    },
    getAllSidebarMenus: (sidebarmenus, sidebarmenusCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: sidebarmenuActions.GET_ALLSIDEBARMENU_LIST,
          sidebarmenus
        });
      };
    },
    getSidebarMenuTrees: (sidebarmenus) => {
      return (dispatch, getState) => {
        dispatch({
          type: sidebarmenuActions.GET_SIDEBARMENU_TREE,
          sidebarmenus
        });
      };
    },
    deleteSidebarMenu: id => {
      return (dispatch, getState) => {
        var currentSidebarMenus = getState().sidebarmenu.sidebarmenus.filter(s => s.id !== id);
        dispatch({
          type: sidebarmenuActions.DELETE_SIDEBARMENU,
          sidebarmenus: currentSidebarMenus,
        });
      };
    },
    toggleModal: (sidebarmenu = null) => ({
      type: sidebarmenuActions.SIDEBARMENU_TOGGLE_HANDLE_MODAL,
      sidebarmenu,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: sidebarmenuActions.CHANGE_SIDEBARMENU_PAGINATION,
          pagination,
        });
      };
    },
    updateSidebarMenusCount: sidebarmenusCount => {
      return (dispatch, getState) => {
        dispatch({
          type: sidebarmenuActions.CHANGE_SIDEBARMENU_COUNT,
          sidebarmenusCount,
        });
      };
    }
  
  };
  export default sidebarmenuActions;