const warehouseActions = {
  CREATE_WAREHOUSE: 'CREATE_WAREHOUSE',
  UPDATE_WAREHOUSE: 'UPDATE_WAREHOUSE',
  GET_WAREHOUSE: 'GET_WAREHOUSE',
  GET_WAREHOUSE_LIST: 'GET_WAREHOUSE_LIST',
  EDIT_WAREHOUSE: 'EDIT_WAREHOUSE',
  DELETE_WAREHOUSE: 'DELETE_WAREHOUSE',
  WAREHOUSE_TOGGLE_HANDLE_MODAL: 'WAREHOUSE_TOGGLE_HANDLE_MODAL',
  CHANGE_WAREHOUSE_PAGINATION: 'CHANGE_WAREHOUSE_PAGINATION',
  CHANGE_WAREHOUSE_COUNT: 'CHANGE_WAREHOUSE_COUNT',
  createWarehouse: warehouse => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseActions.CREATE_WAREHOUSE,
        warehouses: [warehouse, ...getState().warehouse.warehouses],
      });
    };
  },
  updateWarehouse: warehouse => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseActions.UPDATE_WAREHOUSE,
        warehouse,
      });
    };
  },
  editWarehouse: warehouse => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseActions.EDIT_WAREHOUSE,
        warehouse,
      });
    };
  },
  getWarehouses: (warehouses, warehousesCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseActions.GET_WAREHOUSE_LIST,
        warehouses,
        warehousesCount
      });
    };
  },
  deleteWarehouse: id => {
    return (dispatch, getState) => {
      let currentCountries = getState().warehouse.warehouses.filter(s => s.id !== id);
      dispatch({
        type: warehouseActions.DELETE_WAREHOUSE,
        warehouses: currentCountries,
      });
    };
  },
  toggleModal: (warehouse = null) => ({
    type: warehouseActions.WAREHOUSE_TOGGLE_HANDLE_MODAL,
    warehouse,
  }),
  changePagination: pagination => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseActions.CHANGE_WAREHOUSE_PAGINATION,
        pagination,
      });
    };
  },
  updateWarehousesCount: warehousesCount => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseActions.CHANGE_WAREHOUSE_COUNT,
        warehousesCount,
      });
    };
  },
};
export default warehouseActions;