import userActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  users: [],
  usersCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: ''
  },
  user: {
    roleIds: [],
    roleGroupIds: []
  }
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case userActions.CREATE_USER:
      return {
        ...state,
        users: action.users,
        user: initState.user
      };
    case userActions.UPDATE_USER:
      let userIndex = state.users.findIndex(u => u.id === action.user.id);
      let users = state.users;
      users[userIndex] = action.user;
      return {
        ...state,
        users:[...users]
      };
    case userActions.EDIT_USER:
      return {
        ...state,
        user: action.user,
      };
    case userActions.GET_USER_LIST:
      return {
        ...state,
        users: action.users,
        usersCount: action.usersCount,
      };
    case userActions.DELETE_USER:
      return {
        ...state,
        users: action.users,
      };
    case userActions.USER_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        user: action.user === null ? initState.user : action.user,
      };
    case userActions.CHANGE_USER_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case userActions.CHANGE_USER_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
