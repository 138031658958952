import guideActions from './actions';
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    guides: [],
    guidesCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    guide: {
    }
};

export default function guideReducer(state = initState, action) {
    switch (action.type) {
        case guideActions.CREATE_GUIDE:
            initState.guide = {}
            return {
                ...state,
                guides: action.guides,
                guide: initState.guide
            };
        case guideActions.UPDATE_GUIDE:
            var guideIndex = state.guides.findIndex(u => u.id === action.guide.id);
            var guides = state.guides;
            guides[guideIndex] = action.guide;
            return {
                ...state,
                guides: [...guides]
            };
        case guideActions.EDIT_GUIDE:
            return {
                ...state,
                guide: action.guide,
            };
        case guideActions.GET_GUIDE_LIST:
            return {
                ...state,
                guides: action.guides,
                guidesCount: action.guidesCount,
            };
        case guideActions.DELETE_GUIDE:
            return {
                ...state,
                guides: action.guides,
            };
        case guideActions.GUIDE_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                guide: action.guide === null ? initState.guide : action.guide,
            };
        case guideActions.GUIDE_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                guide: action.guide === null ? initState.guide : action.guide,

            };
        case guideActions.CHANGE_GUIDE_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case guideActions.CHANGE_GUIDE_COUNT:
            return {
                ...state,
                guidesCount: action.guidesCount,
            };
        default:
            return state;
    }
}