import alesta_DeliveryTypeActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  alesta_DeliveryTypes: [],
  alesta_DeliveryTypesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id',
    descending:true,
  },
  alesta_DeliveryType: {
  }
};

export default function alesta_DeliveryTypeReducer(state = initState, action) {
  switch (action.type) {
    case alesta_DeliveryTypeActions.CREATE_ALESTA_DELIVERYTYPE :
      initState.alesta_DeliveryType = {}
      return {
        ...state,
        alesta_DeliveryTypes: action.alesta_DeliveryTypes,
        alesta_DeliveryType: initState.alesta_DeliveryType
      };
    case alesta_DeliveryTypeActions.UPDATE_ALESTA_DELIVERYTYPE :
      var alesta_DeliveryTypeIndex = state.alesta_DeliveryTypes.findIndex(u => u.id === action.alesta_DeliveryType.id);
      var alesta_DeliveryTypes = state.alesta_DeliveryTypes;
      alesta_DeliveryTypes[alesta_DeliveryTypeIndex] = action.alesta_DeliveryType;
      return {
        ...state,
        alesta_DeliveryTypes:[...alesta_DeliveryTypes]
      };
    case alesta_DeliveryTypeActions.EDIT_ALESTA_DELIVERYTYPE :
      return {
        ...state,
        alesta_DeliveryType: action.alesta_DeliveryType,
      };
    case alesta_DeliveryTypeActions.GET_ALESTA_DELIVERYTYPE_LIST:
      return {
        ...state,
        alesta_DeliveryTypes: action.alesta_DeliveryTypes,
        alesta_DeliveryTypesCount: action.alesta_DeliveryTypesCount,
      };
    case alesta_DeliveryTypeActions.DELETE_ALESTA_DELIVERYTYPE :
      return {
        ...state,
        alesta_DeliveryTypes: action.alesta_DeliveryTypes,
      };
    case alesta_DeliveryTypeActions.ALESTA_DELIVERYTYPE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        alesta_DeliveryType: action.alesta_DeliveryType === null ? initState.alesta_DeliveryType : action.alesta_DeliveryType,
      };
    case alesta_DeliveryTypeActions.CHANGE_ALESTA_DELIVERYTYPE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case alesta_DeliveryTypeActions.CHANGE_ALESTA_DELIVERYTYPE_COUNT:
      return {
        ...state,
        alesta_DeliveryTypesCount: action.alesta_DeliveryTypesCount,
      };
    default:
      return state;
  }
}
