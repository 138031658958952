import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import { getToken, clearToken } from "@iso/lib/helpers/utility";
import actions from "./actions";
import SuperFetch from "../../library/helpers/superFetch";
import jwtConfig from "../../config/jwt.config";
import CustomNotification from "../../componentsCustom/Notification/CustomNotification";
import { deleteFirestoreToken, requestPermission } from "../../config/firebase.config";

const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT
const apiCall = true;
const signUpRequestMethod = async (user) =>
  await SuperFetch.post("/Auth/register", user, jwtConfig.fetchUrl)
    .then((response) => response)
    .catch((error) => error);
const loginRequestMethod = async (fields) =>
  await SuperFetch.post("/auth/token", fields, jwtConfig.fetchUrl)
    .then((response) => response)
    .catch((error) => error);

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    const { token, fields, user } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        user: user,
      });
    } else if (apiCall) {
      const response = yield call(loginRequestMethod, fields);
      if (response === undefined) {
        yield put({ type: actions.LOGIN_ERROR, payload: { message: "Something is wrong, please try again later." } });
      } else if (response.success) {
        console.log(response);

        yield put({
          type: actions.LOGIN_SUCCESS,
          token: response.data.token,
          user: response.data.user,
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR, payload: { message: response.message ?? "Unknown error!" } });
      }
    } else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: "secret token",
          profile: "Profile",
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem("id_token", payload.token);
    yield localStorage.setItem("user", JSON.stringify(payload.user));
    payload.user.defaultClientId && requestPermission(payload.user.defaultClientId);
  });
  yield takeEvery(actions.CHANGE_LOCAL_USER, function* (payload) {
    yield localStorage.setItem("user", JSON.stringify(payload.user));
  });
}
export function* signUpSuccess() {
  yield takeEvery(actions.SIGNUP_SUCCESS, function* ({ payload }) {
    yield put({
      type: actions.IS_SIGNUP_SUCCESS,
      success: true,
    });
    CustomNotification("success", payload.message, "Success!");

    history.push("/");
  });
}
export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    deleteFirestoreToken(JSON.parse(localStorage.getItem("user")).id);
    yield clearToken();
    history.push("/");
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("idToken");
    const user = getToken().get("user");
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        user,
      });
    }
  });
}

export function* signUpRequest() {
  yield takeEvery("SIGNUP_REQUEST", function* (props) {
    const { user } = props.payload;
    const response = yield call(signUpRequestMethod, user);

    if (response === undefined) {
      yield put({ type: actions.SIGNUP_ERROR, payload: { message: "Something is wrong, please try again later." } });
    } else if (response.success) {
      yield put({ type: actions.SIGNUP_SUCCESS, payload: { message: response.message ?? "User Created !" } });
    } else {
      yield put({ type: actions.SIGNUP_ERROR, payload: { message: response.message ?? "Unknown error!" } });
    }
  });
}
export function* signUpError() {
  yield takeEvery(actions.SIGNUP_ERROR, function* ({ payload }) {
    CustomNotification("error", "error", payload.message, "Error");
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(signUpRequest),
    fork(signUpError),
    fork(signUpSuccess),
  ]);
}

