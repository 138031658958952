import alesta_CurrencyTypeActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  alesta_CurrencyTypes: [],
  alesta_CurrencyTypesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id',
    descending:true,
  },
  alesta_CurrencyType: {
  }
};

export default function alesta_CurrencyTypeReducer(state = initState, action) {
  switch (action.type) {
    case alesta_CurrencyTypeActions.CREATE_ALESTA_CURRENCYTYPE :
      initState.alesta_CurrencyType = {}
      return {
        ...state,
        alesta_CurrencyTypes: action.alesta_CurrencyTypes,
        alesta_CurrencyType: initState.alesta_CurrencyType
      };
    case alesta_CurrencyTypeActions.UPDATE_ALESTA_CURRENCYTYPE :
      var alesta_CurrencyTypeIndex = state.alesta_CurrencyTypes.findIndex(u => u.id === action.alesta_CurrencyType.id);
      var alesta_CurrencyTypes = state.alesta_CurrencyTypes;
      alesta_CurrencyTypes[alesta_CurrencyTypeIndex] = action.alesta_CurrencyType;
      return {
        ...state,
        alesta_CurrencyTypes:[...alesta_CurrencyTypes]
      };
    case alesta_CurrencyTypeActions.EDIT_ALESTA_CURRENCYTYPE :
      return {
        ...state,
        alesta_CurrencyType: action.alesta_CurrencyType,
      };
    case alesta_CurrencyTypeActions.GET_ALESTA_CURRENCYTYPE_LIST:
      return {
        ...state,
        alesta_CurrencyTypes: action.alesta_CurrencyTypes,
        alesta_CurrencyTypesCount: action.alesta_CurrencyTypesCount,
      };
    case alesta_CurrencyTypeActions.DELETE_ALESTA_CURRENCYTYPE :
      return {
        ...state,
        alesta_CurrencyTypes: action.alesta_CurrencyTypes,
      };
    case alesta_CurrencyTypeActions.ALESTA_CURRENCYTYPE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        alesta_CurrencyType: action.alesta_CurrencyType === null ? initState.alesta_CurrencyType : action.alesta_CurrencyType,
      };
    case alesta_CurrencyTypeActions.CHANGE_ALESTA_CURRENCYTYPE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case alesta_CurrencyTypeActions.CHANGE_ALESTA_CURRENCYTYPE_COUNT:
      return {
        ...state,
        alesta_CurrencyTypesCount: action.alesta_CurrencyTypesCount,
      };
    default:
      return state;
  }
}
