import alesta_CountryActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  alesta_Countrys: [],
  alesta_CountrysCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id',
    descending:true,
  },
  alesta_Country: {
  }
};

export default function alesta_CountryReducer(state = initState, action) {
  switch (action.type) {
    case alesta_CountryActions.CREATE_ALESTA_COUNTRY :
      initState.alesta_Country = {}
      return {
        ...state,
        alesta_Countrys: action.alesta_Countrys,
        alesta_Country: initState.alesta_Country
      };
    case alesta_CountryActions.UPDATE_ALESTA_COUNTRY :
      var alesta_CountryIndex = state.alesta_Countrys.findIndex(u => u.id === action.alesta_Country.id);
      var alesta_Countrys = state.alesta_Countrys;
      alesta_Countrys[alesta_CountryIndex] = action.alesta_Country;
      return {
        ...state,
        alesta_Countrys:[...alesta_Countrys]
      };
    case alesta_CountryActions.EDIT_ALESTA_COUNTRY :
      return {
        ...state,
        alesta_Country: action.alesta_Country,
      };
    case alesta_CountryActions.GET_ALESTA_COUNTRY_LIST:
      return {
        ...state,
        alesta_Countrys: action.alesta_Countrys,
        alesta_CountrysCount: action.alesta_CountrysCount,
      };
    case alesta_CountryActions.DELETE_ALESTA_COUNTRY :
      return {
        ...state,
        alesta_Countrys: action.alesta_Countrys,
      };
    case alesta_CountryActions.ALESTA_COUNTRY_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        alesta_Country: action.alesta_Country === null ? initState.alesta_Country : action.alesta_Country,
      };
    case alesta_CountryActions.CHANGE_ALESTA_COUNTRY_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case alesta_CountryActions.CHANGE_ALESTA_COUNTRY_COUNT:
      return {
        ...state,
        alesta_CountrysCount: action.alesta_CountrysCount,
      };
    default:
      return state;
  }
}
