const wholesaleStockDistributeActions = {
  GET_DISTRIBUTE_WAREHOUSE_PRODUCTS: 'GET_DISTRIBUTE_WAREHOUSE_PRODUCTS',
  GET_SOURCE_WAREHOUSE: 'GET_SOURCE_WAREHOUSE',
  GET_DISTRIBUTE_WAREHOUSE_LIST: 'GET_DISTRIBUTE_WAREHOUSE_LIST',
  GET_DISTRIBUTE_LIST: 'GET_DISTRIBUTE_LIST',
  ADD_DISTRIBUTE: 'ADD_DISTRIBUTE',
  REMOVE_DISTRIBUTE: 'REMOVE_DISTRIBUTE',
  UPDATE_DISTRIBUTE: 'UPDATE_DISTRIBUTE',
  DISTRIBUTE_TOGGLE_STOCK_MODAL: "DISTRIBUTE_TOGGLE_STOCK_MODAL",
  getWarehouseProducts: (warehouseProducts) => {
    return (dispatch, _) => {
      dispatch({
        type: wholesaleStockDistributeActions.GET_DISTRIBUTE_WAREHOUSE_PRODUCTS,
        warehouseProducts
      });
    };
  },
  getDistributes: (distributes) => {
    return (dispatch, _) => {
      dispatch({
        type: wholesaleStockDistributeActions.GET_DISTRIBUTE_LIST,
        distributes
      });
    };
  },
  getWarehouses: (warehouses) => {
    return (dispatch, _) => {
      dispatch({
        type: wholesaleStockDistributeActions.GET_DISTRIBUTE_WAREHOUSE_LIST,
        warehouses
      });
    };
  },
  getSourceWarehouse: (sourceWarehouse) => {
    return (dispatch, _) => {
      dispatch({
        type: wholesaleStockDistributeActions.GET_SOURCE_WAREHOUSE,
        sourceWarehouse
      });
    };
  },
  addDistribute: (distribute) => {
    return (dispatch, _) => {
      dispatch({
        type: wholesaleStockDistributeActions.ADD_DISTRIBUTE,
        distribute
      })
    }
  },
  updateDistribute: (distribute) => {
    return (dispatch, _) => {
      dispatch({
        type: wholesaleStockDistributeActions.UPDATE_DISTRIBUTE,
        distribute
      })
    }
  },
  removeDistribute: (distribute) => {
    return (dispatch, _) => {
      dispatch({
        type: wholesaleStockDistributeActions.REMOVE_DISTRIBUTE,
        distribute
      })
    }
  },
  toggleModal: () => {
    return (dispatch, _) => {
      dispatch({
        type: wholesaleStockDistributeActions.DISTRIBUTE_TOGGLE_STOCK_MODAL
      })
    }
  }
};
export default wholesaleStockDistributeActions;