import alesta_CategoryActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  alesta_Categorys: [],
  alesta_CategorysCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id',
    descending:true,
  },
  alesta_Category: {
  }
};

export default function alesta_CategoryReducer(state = initState, action) {
  switch (action.type) {
    case alesta_CategoryActions.CREATE_ALESTA_CATEGORY :
      initState.alesta_Category = {}
      return {
        ...state,
        alesta_Categorys: action.alesta_Categorys,
        alesta_Category: initState.alesta_Category
      };
    case alesta_CategoryActions.UPDATE_ALESTA_CATEGORY :
      var alesta_CategoryIndex = state.alesta_Categorys.findIndex(u => u.id === action.alesta_Category.id);
      var alesta_Categorys = state.alesta_Categorys;
      alesta_Categorys[alesta_CategoryIndex] = action.alesta_Category;
      return {
        ...state,
        alesta_Categorys:[...alesta_Categorys]
      };
    case alesta_CategoryActions.EDIT_ALESTA_CATEGORY :
      return {
        ...state,
        alesta_Category: action.alesta_Category,
      };
    case alesta_CategoryActions.GET_ALESTA_CATEGORY_LIST:
      return {
        ...state,
        alesta_Categorys: action.alesta_Categorys,
        alesta_CategorysCount: action.alesta_CategorysCount,
      };
    case alesta_CategoryActions.DELETE_ALESTA_CATEGORY :
      return {
        ...state,
        alesta_Categorys: action.alesta_Categorys,
      };
    case alesta_CategoryActions.ALESTA_CATEGORY_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        alesta_Category: action.alesta_Category === null ? initState.alesta_Category : action.alesta_Category,
      };
    case alesta_CategoryActions.CHANGE_ALESTA_CATEGORY_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case alesta_CategoryActions.CHANGE_ALESTA_CATEGORY_COUNT:
      return {
        ...state,
        alesta_CategorysCount: action.alesta_CategorysCount,
      };
    default:
      return state;
  }
}
