const placeActions = {
  CREATE_PLACE: "CREATE_PLACE",
  UPDATE_PLACE: "UPDATE_PLACE",
  GET_PLACE: "GET_PLACE",
  GET_PLACE_LIST: "GET_PLACE_LIST",
  EDIT_PLACE: "EDIT_PLACE",
  DELETE_PLACE: "DELETE_PLACE",
  PLACE_TOGGLE_HANDLE_MODAL: "PLACE_TOGGLE_HANDLE_MODAL",
  PLACE_TOGGLE2_HANDLE_MODAL: "PLACE_TOGGLE2_HANDLE_MODAL",
  CHANGE_PLACE_PAGINATION: "CHANGE_PLACE_PAGINATION",
  CHANGE_PLACE_COUNT: "CHANGE_PLACE_COUNT",
  createPlace: (place) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeActions.CREATE_PLACE,
        places: [place, ...getState().place.places],
      });
    };
  },
  updatePlace: (place) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeActions.UPDATE_PLACE,
        place,
      });
    };
  },
  editPlace: (place) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeActions.EDIT_PLACE,
        place,
      });
    };
  },
  getPlaces: (places, placesCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeActions.GET_PLACE_LIST,
        places,
        placesCount,
      });
    };
  },
  deletePlace: (id) => {
    return (dispatch, getState) => {
      var currentPlaces = getState().place.places.filter((s) => s.id !== id);
      dispatch({
        type: placeActions.DELETE_PLACE,
        places: currentPlaces,
      });
    };
  },
  toggleModal: (place = null) => ({
    type: placeActions.PLACE_TOGGLE_HANDLE_MODAL,
    place,
  }),
  toggleModal2: (place = null) => ({
    type: placeActions.PLACE_TOGGLE2_HANDLE_MODAL,
    place,
  }),
  changePagination: (pagination) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeActions.CHANGE_PLACE_PAGINATION,
        pagination,
      });
    };
  },
  updatePlacesCount: (placesCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeActions.CHANGE_PLACE_COUNT,
        placesCount,
      });
    };
  },
};
export default placeActions;
