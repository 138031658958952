import qualityfileActions from "./actions";
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  qualityfiles: [],
  qualityfilesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: "",
    sort: "id",
    desc: true,
  },
  qualityfile: {},
};

export default function qualityfileReducer(state = initState, action) {
  switch (action.type) {
    case qualityfileActions.CREATE_QUALITYFILE:
      initState.qualityfile = {};
      return {
        ...state,
        qualityfiles: action.qualityfiles,
        qualityfile: initState.qualityfile,
      };
    case qualityfileActions.UPDATE_QUALITYFILE:
      var qualityfileIndex = state.qualityfiles.findIndex((u) => u.id === action.qualityfile.id);
      var qualityfiles = state.qualityfiles;
      qualityfiles[qualityfileIndex] = action.qualityfile;
      return {
        ...state,
        qualityfiles: [...qualityfiles],
      };
    case qualityfileActions.EDIT_QUALITYFILE:
      return {
        ...state,
        qualityfile: action.qualityfile,
      };
    case qualityfileActions.GET_QUALITYFILE_LIST:
      return {
        ...state,
        qualityfiles: action.qualityfiles,
        qualityfilesCount: action.qualityfilesCount,
      };
    case qualityfileActions.DELETE_QUALITYFILE:
      return {
        ...state,
        qualityfiles: action.qualityfiles,
      };
    case qualityfileActions.QUALITYFILE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        qualityfile: action.qualityfile === null ? initState.qualityfile : action.qualityfile,
      };
    case qualityfileActions.CHANGE_QUALITYFILE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case qualityfileActions.CHANGE_QUALITYFILE_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}

