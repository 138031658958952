import saleActions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  searchWarehouseProducts: [],
  salesWarehouseProducts: [],
  salesWarehouseProductsCount: 0,
  modalWarehouseProducts: [],
  products: [],
  warehouses: [],
  oldTotalSalesPrice: 0,
  totalSalesPrice: 0,
  pagination: {
    currentPage: 1,
    descending: false,
    skip: 0,
    take: 10,
    search: '',
    sort: 'name'
  }
};

export default function saleReducer(state = initState, action) {
  switch (action.type) {
    case saleActions.SALES_GET_SEARCH_WAREHOUSE_PRODUCT_LIST:
      return {
        ...state,
        searchWarehouseProducts: action.searchWarehouseProducts
      };
    case saleActions.SALES_GET_SALES_WAREHOUSE_PRODUCT_LIST:
      return {
        ...state,
        salesWarehouseProducts:  action.salesWarehouseProducts
      };
    case saleActions.SALES_RESET_SALES_WAREHOUSE_PRODUCT_LIST:
      return {
        ...state,
        salesWarehouseProducts: [],
        salesWarehouseProductsCount: 0
      };
    case saleActions.SALES_GET_MODAL_WAREHOUSE_PRODUCT_LIST:
      return {
        ...state,
        modalWarehouseProducts: action.warehouseProducts
      };
    case saleActions.SALES_ADD_SALES_WAREHOUSE_PRODUCT:
      return {
        ...state,
        salesWarehouseProducts: [action.warehouseProduct, ...state.salesWarehouseProducts],
        salesWarehouseProductsCount: (state.salesWarehouseProductsCount + 1)
      };
    case saleActions.SALES_UPDATE_SALES_WAREHOUSE_PRODUCT:
      let newSalesWarehouseProducts = state.salesWarehouseProducts.map(saleWarehouseProduct => {
        if (saleWarehouseProduct.id === action.warehouseProduct.id) {
          return Object.assign(saleWarehouseProduct, action.warehouseProduct)
        } else {
         return saleWarehouseProduct
        }
      })
      return {
        ...state,
        salesWarehouseProducts: newSalesWarehouseProducts
      };
    case saleActions.SALES_REMOVE_SALES_WAREHOUSE_PRODUCT:
      let salesWarehouseProducts = state.salesWarehouseProducts.filter(sale => sale.id !== action.warehouseProduct.id)
      return {
        ...state,
        salesWarehouseProducts,
        salesWarehouseProductsCount: (state.salesWarehouseProductsCount - 1)
      }
    case saleActions.SALES_UPDATE_SALES_TOTAL_PRICE:
      return {
        ...state,
        totalSalesPrice: action.totalSalesPrice
      }
    case saleActions.SALES_UPDATE_OLD_SALES_TOTAL_PRICE:
      return {
        ...state,
        oldTotalSalesPrice: action.oldTotalSalesPrice
      }
    case saleActions.SALES_TOGGLE_STOCK_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive
      }
    default:
      return state;
  }
}
