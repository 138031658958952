const tagActions = {
    CREATE_TAG: 'CREATE_TAG',
    UPDATE_TAG: 'UPDATE_TAG',
    GET_TAG: 'GET_TAG',
    GET_TAG_LIST: 'GET_TAG_LIST',
    EDIT_TAG: 'EDIT_TAG',
    DELETE_TAG: 'DELETE_TAG',
    TAG_TOGGLE_HANDLE_MODAL: 'TAG_TOGGLE_HANDLE_MODAL',
    CHANGE_TAG_PAGINATION: 'CHANGE_TAG_PAGINATION',
    CHANGE_TAG_COUNT: 'CHANGE_TAG_COUNT',
    createTag: tag => {
      return (dispatch, getState) => {
        dispatch({
          type: tagActions.CREATE_TAG,
          tags: [tag, ...getState().tag.tags],
        });
      };
    },
    updateTag: tag => {
      return (dispatch, getState) => {
        dispatch({
          type: tagActions.UPDATE_TAG,
          tag,
        });
      };
    },
    editTag: tag => {
      return (dispatch, getState) => {
        dispatch({
          type: tagActions.EDIT_TAG,
          tag,
        });
      };
    },
    getTags: (tags, tagsCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: tagActions.GET_TAG_LIST,
          tags,
          tagsCount
        });
      };
    },
    deleteTag: id => {
      return (dispatch, getState) => {
        let currentCountries = getState().tag.tags.filter(s => s.id !== id);
        dispatch({
          type: tagActions.DELETE_TAG,
          tags: currentCountries,
        });
      };
    },
    toggleModal: (tag = null) => ({
      type: tagActions.TAG_TOGGLE_HANDLE_MODAL,
      tag,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: tagActions.CHANGE_TAG_PAGINATION,
          pagination,
        });
      };
    },
    updateTagsCount: tagsCount => {
      return (dispatch, getState) => {
        dispatch({
          type: tagActions.CHANGE_TAG_COUNT,
          tagsCount,
        });
      };
    }
  
  };
  export default tagActions;