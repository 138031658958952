const warehouseProductActions = {
  CREATE_WAREHOUSE_PRODUCT: 'CREATE_WAREHOUSE_PRODUCT',
  UPDATE_WAREHOUSE_PRODUCT: 'UPDATE_WAREHOUSE_PRODUCT',
  GET_WAREHOUSE_PRODUCT: 'GET_WAREHOUSE_PRODUCT',
  GET_WAREHOUSE_PRODUCT_LIST: 'GET_WAREHOUSE_PRODUCT_LIST',
  GET_WAREHOUSE_PRODUCT_PRODUCT_LIST: 'GET_WAREHOUSE_PRODUCT_PRODUCT_LIST',
  GET_WAREHOUSE_PRODUCT_WAREHOUSE_LIST: 'GET_WAREHOUSE_PRODUCT_WAREHOUSE_LIST',
  EDIT_WAREHOUSE_PRODUCT: 'EDIT_WAREHOUSE_PRODUCT',
  DELETE_WAREHOUSE_PRODUCT: 'DELETE_WAREHOUSE_PRODUCT',
  WAREHOUSE_PRODUCT_TOGGLE_HANDLE_MODAL: 'WAREHOUSE_PRODUCT_TOGGLE_HANDLE_MODAL',
  CHANGE_WAREHOUSE_PRODUCT_PAGINATION: 'CHANGE_WAREHOUSE_PRODUCT_PAGINATION',
  CHANGE_WAREHOUSE_PRODUCT_COUNT: 'CHANGE_WAREHOUSE_PRODUCT_COUNT',
  createWarehouseProduct: warehouseProduct => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductActions.CREATE_WAREHOUSE_PRODUCT,
        warehouseProducts: [warehouseProduct, ...getState().warehouseProduct.warehouseProducts],
      });
    };
  },
  updateWarehouseProduct: warehouseProduct => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductActions.UPDATE_WAREHOUSE_PRODUCT,
        warehouseProduct,
      });
    };
  },
  editWarehouseProduct: warehouseProduct => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductActions.EDIT_WAREHOUSE_PRODUCT,
        warehouseProduct,
      });
    };
  },
  getWarehouseProducts: (warehouseProducts, warehouseProductsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductActions.GET_WAREHOUSE_PRODUCT_LIST,
        warehouseProducts,
        warehouseProductsCount
      });
    };
  },
  getProducts: (products) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductActions.GET_WAREHOUSE_PRODUCT_PRODUCT_LIST,
        products,
      });
    };
  },
  getWarehouses: (warehouses) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductActions.GET_WAREHOUSE_PRODUCT_WAREHOUSE_LIST,
        warehouses,
      });
    };
  },
  deleteWarehouseProduct: id => {
    return (dispatch, getState) => {
      let currentCountries = getState().warehouseProduct.warehouseProducts.filter(s => s.id !== id);
      dispatch({
        type: warehouseProductActions.DELETE_WAREHOUSE_PRODUCT,
        warehouseProducts: currentCountries,
      });
    };
  },
  toggleModal: (warehouseProduct = null) => ({
    type: warehouseProductActions.WAREHOUSE_PRODUCT_TOGGLE_HANDLE_MODAL,
    warehouseProduct,
  }),
  changePagination: pagination => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductActions.CHANGE_WAREHOUSE_PRODUCT_PAGINATION,
        pagination,
      });
    };
  },
  updateWarehouseProductsCount: warehouseProductsCount => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductActions.CHANGE_WAREHOUSE_PRODUCT_COUNT,
        warehouseProductsCount,
      });
    };
  },

};
export default warehouseProductActions;