import timelineformActions from "./actions";
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  timelineforms: [],
  timelineformsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: "",
    sort: "id",
    desc: true,
  },
  timelineform: {},
};

export default function timelineformReducer(state = initState, action) {
  switch (action.type) {
    case timelineformActions.CREATE_TIMELINEFORM:
      initState.timelineform = {};
      return {
        ...state,
        timelineforms: action.timelineforms,
        timelineform: initState.timelineform,
      };
    case timelineformActions.UPDATE_TIMELINEFORM:
      var timelineformIndex = state.timelineforms.findIndex((u) => u.id === action.timelineform.id);
      var timelineforms = state.timelineforms;
      timelineforms[timelineformIndex] = action.timelineform;
      return {
        ...state,
        timelineforms: [...timelineforms],
      };
    case timelineformActions.EDIT_TIMELINEFORM:
      return {
        ...state,
        timelineform: action.timelineform,
      };
    case timelineformActions.GET_TIMELINEFORM_LIST:
      return {
        ...state,
        timelineforms: action.timelineforms,
        timelineformsCount: action.timelineformsCount,
      };
    case timelineformActions.DELETE_TIMELINEFORM:
      return {
        ...state,
        timelineforms: action.timelineforms,
      };
    case timelineformActions.TIMELINEFORM_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        timelineform: action.timelineform === null ? initState.timelineform : action.timelineform,
      };
    case timelineformActions.CHANGE_TIMELINEFORM_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case timelineformActions.CHANGE_TIMELINEFORM_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}

