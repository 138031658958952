import sssActions from "./actions";
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    ssss: [],
    ssssCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    sss: {
    }
};

export default function sssReducer(state = initState, action) {
    switch (action.type) {
        case sssActions.CREATE_SSS:
            initState.sss = {}
            return {
                ...state,
                ssss: action.ssss,
                sss: initState.sss
            };
        case sssActions.UPDATE_SSS:
            var sssIndex = state.ssss.findIndex(u => u.id === action.sss.id);
            var ssss = state.ssss;
            ssss[sssIndex] = action.sss;
            return {
                ...state,
                ssss: [...ssss]
            };
        case sssActions.EDIT_SSS:
            return {
                ...state,
                sss: action.sss,
            };
        case sssActions.GET_SSS_LIST:
            return {
                ...state,
                ssss: action.ssss,
                ssssCount: action.ssssCount,
            };
        case sssActions.DELETE_SSS:
            return {
                ...state,
                ssss: action.ssss,
            };
        case sssActions.SSS_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                sss: action.sss === null ? initState.sss : action.sss,
            };
        case sssActions.SSS_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                sss: action.sss === null ? initState.sss : action.sss,

            };
        case sssActions.CHANGE_SSS_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case sssActions.CHANGE_SSS_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}