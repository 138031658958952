const unitTypeActions = {
    CREATE_UNITTYPE: 'CREATE_UNITTYPE',
    UPDATE_UNITTYPE: 'UPDATE_UNITTYPE',
    GET_UNITTYPE: 'GET_UNITTYPE',
    GET_UNITTYPE_LIST: 'GET_UNITTYPE_LIST',
    EDIT_UNITTYPE: 'EDIT_UNITTYPE',
    DELETE_UNITTYPE: 'DELETE_UNITTYPE',
    UNITTYPE_TOGGLE_HANDLE_MODAL: 'UNITTYPE_TOGGLE_HANDLE_MODAL',
    CHANGE_UNITTYPE_PAGINATION: 'CHANGE_UNITTYPE_PAGINATION',
    CHANGE_UNITTYPE_COUNT: 'CHANGE_UNITTYPE_COUNT',
    createUnitType: unitType => {
      return (dispatch, getState) => {
        dispatch({
          type: unitTypeActions.CREATE_UNITTYPE,
          unitTypes: [unitType, ...getState().unitType.unitTypes],
        });
      };
    },
    updateUnitType: unitType => {
      return (dispatch, getState) => {
        dispatch({
          type: unitTypeActions.UPDATE_UNITTYPE,
          unitType,
        });
      };
    },
    editUnitType: unitType => {
      return (dispatch, getState) => {
        dispatch({
          type: unitTypeActions.EDIT_UNITTYPE,
          unitType,
        });
      };
    },
    getUnitTypes: (unitTypes, unitTypesCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: unitTypeActions.GET_UNITTYPE_LIST,
          unitTypes,
          unitTypesCount
        });
      };
    },
    deleteUnitType: id => {
      return (dispatch, getState) => {
        let currentCountries = getState().unitType.unitTypes.filter(s => s.id !== id);
        dispatch({
          type: unitTypeActions.DELETE_UNITTYPE,
          unitTypes: currentCountries,
        });
      };
    },
    toggleModal: (unitType = null) => ({
      type: unitTypeActions.UNITTYPE_TOGGLE_HANDLE_MODAL,
      unitType,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: unitTypeActions.CHANGE_UNITTYPE_PAGINATION,
          pagination,
        });
      };
    },
    updateUnitTypesCount: unitTypesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: unitTypeActions.CHANGE_UNITTYPE_COUNT,
          unitTypesCount,
        });
      };
    }
  
  };
  export default unitTypeActions;