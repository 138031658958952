import devicelogActions from './actions';
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    devicelogs: [],
    devicelogsCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    devicelog: {
    }
};

export default function devicelogReducer(state = initState, action) {
    switch (action.type) {
        case devicelogActions.CREATE_DEVICELOG:
            initState.devicelog = {}
            return {
                ...state,
                devicelogs: action.devicelogs,
                devicelog: initState.devicelog
            };
        case devicelogActions.UPDATE_DEVICELOG:
            var devicelogIndex = state.devicelogs.findIndex(u => u.id === action.devicelog.id);
            var devicelogs = state.devicelogs;
            devicelogs[devicelogIndex] = action.devicelog;
            return {
                ...state,
                devicelogs: [...devicelogs]
            };
        case devicelogActions.EDIT_DEVICELOG:
            return {
                ...state,
                devicelog: action.devicelog,
            };
        case devicelogActions.GET_DEVICELOG_LIST:
            return {
                ...state,
                devicelogs: action.devicelogs,
                devicelogsCount: action.devicelogsCount,
            };
        case devicelogActions.DELETE_DEVICELOG:
            return {
                ...state,
                devicelogs: action.devicelogs,
            };
        case devicelogActions.DEVICELOG_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                devicelog: action.devicelog === null ? initState.devicelog : action.devicelog,
            };
        case devicelogActions.DEVICELOG_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                devicelog: action.devicelog === null ? initState.devicelog : action.devicelog,

            };
        case devicelogActions.CHANGE_DEVICELOG_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case devicelogActions.CHANGE_DEVICELOG_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}