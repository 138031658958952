import suggestionActions from "./actions";
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  suggestions: [],
  suggestionsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: "",
    sort: "id",
    desc: true,
  },
  suggestion: {},
};

export default function suggestionReducer(state = initState, action) {
  switch (action.type) {
    case suggestionActions.CREATE_SUGGESTION:
      initState.suggestion = {};
      return {
        ...state,
        suggestions: action.suggestions,
        suggestion: initState.suggestion,
      };
    case suggestionActions.UPDATE_SUGGESTION:
      var suggestionIndex = state.suggestions.findIndex((u) => u.id === action.suggestion.id);
      var suggestions = state.suggestions;
      suggestions[suggestionIndex] = action.suggestion;
      return {
        ...state,
        suggestions: [...suggestions],
      };
    case suggestionActions.EDIT_SUGGESTION:
      return {
        ...state,
        suggestion: action.suggestion,
      };
    case suggestionActions.GET_SUGGESTION_LIST:
      return {
        ...state,
        suggestions: action.suggestions,
        suggestionsCount: action.suggestionsCount,
      };
    case suggestionActions.DELETE_SUGGESTION:
      return {
        ...state,
        suggestions: action.suggestions,
      };
    case suggestionActions.SUGGESTION_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        suggestion: action.suggestion === null ? initState.suggestion : action.suggestion,
      };
    case suggestionActions.CHANGE_SUGGESTION_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case suggestionActions.CHANGE_SUGGESTION_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}

