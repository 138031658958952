const medicineActions = {
  CREATE_MEDICINE: "CREATE_MEDICINE",
  UPDATE_MEDICINE: "UPDATE_MEDICINE",
  GET_MEDICINE: "GET_MEDICINE",
  GET_MEDICINE_LIST: "GET_MEDICINE_LIST",
  EDIT_MEDICINE: "EDIT_MEDICINE",
  DELETE_MEDICINE: "DELETE_MEDICINE",
  MEDICINE_TOGGLE_HANDLE_MODAL: "MEDICINE_TOGGLE_HANDLE_MODAL",
  CHANGE_MEDICINE_PAGINATION: "CHANGE_MEDICINE_PAGINATION",
  CHANGE_MEDICINE_COUNT: "CHANGE_MEDICINE_COUNT",
  createMedicine: (medicine) => {
    return (dispatch, getState) => {
      dispatch({
        type: medicineActions.CREATE_MEDICINE,
        medicines: [medicine, ...getState().medicine.medicines],
      });
    };
  },
  updateMedicine: (medicine) => {
    return (dispatch, getState) => {
      dispatch({
        type: medicineActions.UPDATE_MEDICINE,
        medicine,
      });
    };
  },
  editMedicine: (medicine) => {
    return (dispatch, getState) => {
      dispatch({
        type: medicineActions.EDIT_MEDICINE,
        medicine,
      });
    };
  },
  getMedicines: (medicines, medicinesCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: medicineActions.GET_MEDICINE_LIST,
        medicines,
        medicinesCount,
      });
    };
  },
  deleteMedicine: (id) => {
    return (dispatch, getState) => {
      var currentMedicines = getState().medicine.medicines.filter((s) => s.id !== id);
      dispatch({
        type: medicineActions.DELETE_MEDICINE,
        medicines: currentMedicines,
      });
    };
  },
  toggleModal: (medicine = null) => ({
    type: medicineActions.MEDICINE_TOGGLE_HANDLE_MODAL,
    medicine,
  }),
  changePagination: (pagination) => {
    return (dispatch, getState) => {
      dispatch({
        type: medicineActions.CHANGE_MEDICINE_PAGINATION,
        pagination,
      });
    };
  },
  updateMedicinesCount: (medicinesCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: medicineActions.CHANGE_MEDICINE_COUNT,
        medicinesCount,
      });
    };
  },
};
export default medicineActions;
