import antdTR from 'antd/lib/locale-provider/tr_TR';
import enMessages from '../locales/tr_TR.json';

const TrLang = {
  messages: {
    ...enMessages,
  },
  antd: antdTR,
  locale: 'tr-TR',
};
export default TrLang;
