const sssActions = {
    CREATE_SSS: 'CREATE_SSS',
    UPDATE_SSS: 'UPDATE_SSS',
    GET_SSS: 'GET_SSS',
    GET_SSS_LIST: 'GET_SSS_LIST',
    EDIT_SSS: 'EDIT_SSS',
    DELETE_SSS: 'DELETE_SSS',
    SSS_TOGGLE_HANDLE_MODAL: 'SSS_TOGGLE_HANDLE_MODAL',
    SSS_TOGGLE2_HANDLE_MODAL: 'SSS_TOGGLE2_HANDLE_MODAL',
    CHANGE_SSS_PAGINATION: 'CHANGE_SSS_PAGINATION',
    CHANGE_SSS_COUNT: 'CHANGE_SSS_COUNT',
    createSss: sss => {
        return (dispatch, getState) => {
            dispatch({
                type: sssActions.CREATE_SSS,
                ssss: [sss, ...getState().sss.ssss],
            });
        };
    },
    updateSss: sss => {
        return (dispatch, getState) => {
            dispatch({
                type: sssActions.UPDATE_SSS,
                sss,
            });
        };
    },
    editSss: sss => {
        return (dispatch, getState) => {
            dispatch({
                type: sssActions.EDIT_SSS,
                sss,
            });
        };
    },
    getSsss: (ssss, ssssCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: sssActions.GET_SSS_LIST,
                ssss,
                ssssCount
            });
        };
    },
    deleteSss: id => {
        return (dispatch, getState) => {
            var currentSsss = getState().sss.ssss.filter(s => s.id !== id);
            dispatch({
                type: sssActions.DELETE_SSS,
                ssss: currentSsss,
            });
        };
    },
    toggleModal: (sss = null) => ({
        type: sssActions.SSS_TOGGLE_HANDLE_MODAL,
        sss,
    }),
    toggleModal2: (sss = null) => ({
        type: sssActions.SSS_TOGGLE2_HANDLE_MODAL,
        sss,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: sssActions.CHANGE_SSS_PAGINATION,
                pagination,
            });
        };
    },
    updateSsssCount: ssssCount => {
        return (dispatch, getState) => {
            dispatch({
                type: sssActions.CHANGE_SSS_COUNT,
                ssssCount,
            });
        };
    }
}
export default sssActions;