import feedbackcategoryActions from './actions';
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    feedbackcategorys: [],
    feedbackcategorysCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    feedbackcategory: {
    }
};

export default function feedbackcategoryReducer(state = initState, action) {
    switch (action.type) {
        case feedbackcategoryActions.CREATE_FEEDBACKCATEGORY:
            initState.feedbackcategory = {}
            return {
                ...state,
                feedbackcategorys: action.feedbackcategorys,
                feedbackcategory: initState.feedbackcategory
            };
        case feedbackcategoryActions.UPDATE_FEEDBACKCATEGORY:
            var feedbackcategoryIndex = state.feedbackcategorys.findIndex(u => u.id === action.feedbackcategory.id);
            var feedbackcategorys = state.feedbackcategorys;
            feedbackcategorys[feedbackcategoryIndex] = action.feedbackcategory;
            return {
                ...state,
                feedbackcategorys: [...feedbackcategorys]
            };
        case feedbackcategoryActions.EDIT_FEEDBACKCATEGORY:
            return {
                ...state,
                feedbackcategory: action.feedbackcategory,
            };
        case feedbackcategoryActions.GET_FEEDBACKCATEGORY_LIST:
            return {
                ...state,
                feedbackcategorys: action.feedbackcategorys,
                feedbackcategorysCount: action.feedbackcategorysCount,
            };
        case feedbackcategoryActions.DELETE_FEEDBACKCATEGORY:
            return {
                ...state,
                feedbackcategorys: action.feedbackcategorys,
            };
        case feedbackcategoryActions.FEEDBACKCATEGORY_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                feedbackcategory: action.feedbackcategory === null ? initState.feedbackcategory : action.feedbackcategory,
            };
        case feedbackcategoryActions.FEEDBACKCATEGORY_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                feedbackcategory: action.feedbackcategory === null ? initState.feedbackcategory : action.feedbackcategory,

            };
        case feedbackcategoryActions.CHANGE_FEEDBACKCATEGORY_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case feedbackcategoryActions.CHANGE_FEEDBACKCATEGORY_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}