const productimageActions = {
    CREATE_PRODUCTIMAGE: 'CREATE_PRODUCTIMAGE',
    UPDATE_PRODUCTIMAGE: 'UPDATE_PRODUCTIMAGE',
    GET_PRODUCTIMAGE: 'GET_PRODUCTIMAGE',
    GET_PRODUCTIMAGE_LIST: 'GET_PRODUCTIMAGE_LIST',
    EDIT_PRODUCTIMAGE: 'EDIT_PRODUCTIMAGE',
    DELETE_PRODUCTIMAGE: 'DELETE_PRODUCTIMAGE',
    PRODUCTIMAGE_TOGGLE_HANDLE_MODAL: 'PRODUCTIMAGE_TOGGLE_HANDLE_MODAL',
    PRODUCTIMAGE_TOGGLE2_HANDLE_MODAL: 'PRODUCTIMAGE_TOGGLE2_HANDLE_MODAL',
    CHANGE_PRODUCTIMAGE_PAGINATION: 'CHANGE_PRODUCTIMAGE_PAGINATION',
    CHANGE_PRODUCTIMAGE_COUNT: 'CHANGE_PRODUCTIMAGE_COUNT',
    createProductImage: productimage => {
        return (dispatch, getState) => {
            dispatch({
                type: productimageActions.CREATE_PRODUCTIMAGE,
                productimages: [productimage, ...getState().productimage.productimages],
            });
        };
    },
    updateProductImage: productimage => {
        return (dispatch, getState) => {
            dispatch({
                type: productimageActions.UPDATE_PRODUCTIMAGE,
                productimage,
            });
        };
    },
    editProductImage: productimage => {
        return (dispatch, getState) => {
            dispatch({
                type: productimageActions.EDIT_PRODUCTIMAGE,
                productimage,
            });
        };
    },
    getProductImages: (productimages, productimagesCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: productimageActions.GET_PRODUCTIMAGE_LIST,
                productimages,
                productimagesCount
            });
        };
    },
    deleteProductImage: id => {
        return (dispatch, getState) => {
            var currentProductImages = getState().productimage.productimages.filter(s => s.id !== id);
            dispatch({
                type: productimageActions.DELETE_PRODUCTIMAGE,
                productimages: currentProductImages,
            });
        };
    },
    toggleModal: (productimage = null) => ({
        type: productimageActions.PRODUCTIMAGE_TOGGLE_HANDLE_MODAL,
        productimage,
    }),
    toggleModal2: (productimage = null) => ({
        type: productimageActions.PRODUCTIMAGE_TOGGLE2_HANDLE_MODAL,
        productimage,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: productimageActions.CHANGE_PRODUCTIMAGE_PAGINATION,
                pagination,
            });
        };
    },
    updateProductImagesCount: productimagesCount => {
        return (dispatch, getState) => {
            dispatch({
                type: productimageActions.CHANGE_PRODUCTIMAGE_COUNT,
                productimagesCount,
            });
        };
    }

};
export default productimageActions;