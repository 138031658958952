import warehouseProductTransferActions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  warehouseProducts: [],
  warehouses: [],
  transfers: [],
  transfersCount: 0,
  transferFromWarehouse: {},
  transferToWarehouse: {},
  pagination: {
    currentPage: 1,
    descending: false,
    skip: 0,
    take: 10,
    search: '',
    sort: 'name'
  },
  transfer: {}
};

export default function warehouseProductTransferReducer(state = initState, action) {
  switch (action.type) {
    case warehouseProductTransferActions.GET_TRANSFER_WAREHOUSE_PRODUCTS:
      return {
        ...state,
        warehouseProducts: action.warehouseProducts
      };
    case warehouseProductTransferActions.GET_TRANSFER_WAREHOUSES:
      return {
        ...state,
        warehouses: action.warehouses
      };
    case warehouseProductTransferActions.GET_TRANSFERS_LIST:
      return {
        ...state,
        transfers: action.transfers
      };
    case warehouseProductTransferActions.GET_TRANSFER_FROM_WAREHOUSE:
      return {
        ...state,
        transferFromWarehouse: action.transferFromWarehouse
      };
    case warehouseProductTransferActions.GET_TRANSFER_TO_WAREHOUSE:
      return {
        ...state,
        transferToWarehouse: action.transferToWarehouse
      };
    case warehouseProductTransferActions.ADD_TRANSFER:
      return {
        ...state,
        transfers: [action.transfer, ...state.transfers],
        transfersCount: (state.transfersCount + 1)
      };
    case warehouseProductTransferActions.UPDATE_TRANSFER:
      let newTransfers = state.transfers.map(transfer => {
        if (transfer.id === action.transfer.id) {
          return Object.assign(transfer, action.transfer)
        } else {
         return transfer
        }
      })
      return {
        ...state,
        transfers: newTransfers
      };
    case warehouseProductTransferActions.REMOVE_TRANSFER:
      let transfers = state.transfers.filter(transfer => transfer.id !== action.transfer.id)
      return {
        ...state,
        transfers,
        transfersCount: (state.transfers - 1)
      }
    case warehouseProductTransferActions.TRANSFER_TOGGLE_STOCK_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive
      }
    default:
      return state;
  }
}
