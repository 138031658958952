import orderDetailActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  orderDetails: [],
  orderDetailsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: ''
  },
  orderDetail: {
  }
};

export default function orderDetailReducer(state = initState, action) {
  switch (action.type) {
    case orderDetailActions.CREATE_ORDER_DETAIL:
      initState.orderDetail = {}
      return {
        ...state,
        orderDetails: action.orderDetails,
        orderDetail: initState.orderDetail
      };
    case orderDetailActions.UPDATE_ORDER_DETAIL:
      let orderDetailIndex = state.orderDetails.findIndex(u => u.id === action.orderDetail.id);
      let orderDetails = state.orderDetails;
      orderDetails[orderDetailIndex] = action.orderDetail;
      return {
        ...state,
        orderDetails
      };
    case orderDetailActions.EDIT_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.orderDetail,
      };
    case orderDetailActions.GET_ORDER_DETAIL_LIST:
      return {
        ...state,
        orderDetails: action.orderDetails,
        orderDetailsCount: action.orderDetailsCount,
      };
    case orderDetailActions.DELETE_ORDER_DETAIL:
      return {
        ...state,
        orderDetails: action.orderDetails,
      };
    case orderDetailActions.ORDER_DETAIL_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        orderDetail: action.orderDetail === null ? initState.orderDetail : action.orderDetail,
      };
    case orderDetailActions.CHANGE_ORDER_DETAIL_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case orderDetailActions.CHANGE_ORDER_DETAIL_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
