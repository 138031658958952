const feedbackcategoryActions = {
    CREATE_FEEDBACKCATEGORY: 'CREATE_FEEDBACKCATEGORY',
    UPDATE_FEEDBACKCATEGORY: 'UPDATE_FEEDBACKCATEGORY',
    GET_FEEDBACKCATEGORY: 'GET_FEEDBACKCATEGORY',
    GET_FEEDBACKCATEGORY_LIST: 'GET_FEEDBACKCATEGORY_LIST',
    EDIT_FEEDBACKCATEGORY: 'EDIT_FEEDBACKCATEGORY',
    DELETE_FEEDBACKCATEGORY: 'DELETE_FEEDBACKCATEGORY',
    FEEDBACKCATEGORY_TOGGLE_HANDLE_MODAL: 'FEEDBACKCATEGORY_TOGGLE_HANDLE_MODAL',
    FEEDBACKCATEGORY_TOGGLE2_HANDLE_MODAL: 'FEEDBACKCATEGORY_TOGGLE2_HANDLE_MODAL',
    CHANGE_FEEDBACKCATEGORY_PAGINATION: 'CHANGE_FEEDBACKCATEGORY_PAGINATION',
    CHANGE_FEEDBACKCATEGORY_COUNT: 'CHANGE_FEEDBACKCATEGORY_COUNT',
    createFeedBackCategory: feedbackcategory => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackcategoryActions.CREATE_FEEDBACKCATEGORY,
                feedbackcategorys: [feedbackcategory, ...getState().feedbackcategory.feedbackcategorys],
            });
        };
    },
    updateFeedBackCategory: feedbackcategory => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackcategoryActions.UPDATE_FEEDBACKCATEGORY,
                feedbackcategory,
            });
        };
    },
    editFeedBackCategory: feedbackcategory => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackcategoryActions.EDIT_FEEDBACKCATEGORY,
                feedbackcategory,
            });
        };
    },
    getFeedBackCategorys: (feedbackcategorys, feedbackcategorysCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackcategoryActions.GET_FEEDBACKCATEGORY_LIST,
                feedbackcategorys,
                feedbackcategorysCount
            });
        };
    },
    deleteFeedBackCategory: id => {
        return (dispatch, getState) => {
            var currentFeedBackCategorys = getState().feedbackcategory.feedbackcategorys.filter(s => s.id !== id);
            dispatch({
                type: feedbackcategoryActions.DELETE_FEEDBACKCATEGORY,
                feedbackcategorys: currentFeedBackCategorys,
            });
        };
    },
    toggleModal: (feedbackcategory = null) => ({
        type: feedbackcategoryActions.FEEDBACKCATEGORY_TOGGLE_HANDLE_MODAL,
        feedbackcategory,
    }),
    toggleModal2: (feedbackcategory = null) => ({
        type: feedbackcategoryActions.FEEDBACKCATEGORY_TOGGLE2_HANDLE_MODAL,
        feedbackcategory,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackcategoryActions.CHANGE_FEEDBACKCATEGORY_PAGINATION,
                pagination,
            });
        };
    },
    updateFeedBackCategorysCount: feedbackcategorysCount => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackcategoryActions.CHANGE_FEEDBACKCATEGORY_COUNT,
                feedbackcategorysCount,
            });
        };
    }

};
export default feedbackcategoryActions;