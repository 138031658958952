import productMediaActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  productMedias: [],
  productMediasCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: ''
  },
  productMedia: {
  }
};

export default function productMediaReducer(state = initState, action) {
  switch (action.type) {
    case productMediaActions.CREATE_PRODUCT_MEDIA:
      initState.productMedia = {}
      return {
        ...state,
        productMedias: action.productMedias,
        productMedia: initState.productMedia
      };
    case productMediaActions.UPDATE_PRODUCT_MEDIA:
      let productMediaIndex = state.productMedias.findIndex(u => u.id === action.productMedia.id);
      let productMedias = state.productMedias;
      productMedias[productMediaIndex] = action.productMedia;
      return {
        ...state,
        productMedias
      };
    case productMediaActions.EDIT_PRODUCT_MEDIA:
      return {
        ...state,
        productMedia: action.productMedia,
      };
    case productMediaActions.GET_PRODUCT_MEDIA_LIST:
      return {
        ...state,
        productMedias: action.productMedias,
        productMediasCount: action.productMediasCount,
      };
    case productMediaActions.DELETE_PRODUCT_MEDIA:
      return {
        ...state,
        productMedias: action.productMedias,
      };
    case productMediaActions.PRODUCT_MEDIA_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        productMedia: action.productMedia === null ? initState.productMedia : action.productMedia,
      };
    case productMediaActions.CHANGE_PRODUCT_MEDIA_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case productMediaActions.CHANGE_PRODUCT_MEDIA_COUNT:
      return {
        ...state,
        productMediasCount: action.productMediasCount,
      };
    default:
      return state;
  }
}
