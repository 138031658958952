const timelineformActions = {
  CREATE_TIMELINEFORM: "CREATE_TIMELINEFORM",
  UPDATE_TIMELINEFORM: "UPDATE_TIMELINEFORM",
  GET_TIMELINEFORM: "GET_TIMELINEFORM",
  GET_SUMMARY: "GET_SUMMARY",
  GET_TIMELINEFORM_LIST: "GET_TIMELINEFORM_LIST",
  EDIT_TIMELINEFORM: "EDIT_TIMELINEFORM",
  DELETE_TIMELINEFORM: "DELETE_TIMELINEFORM",
  TIMELINEFORM_TOGGLE_HANDLE_MODAL: "TIMELINEFORM_TOGGLE_HANDLE_MODAL",
  CHANGE_TIMELINEFORM_PAGINATION: "CHANGE_TIMELINEFORM_PAGINATION",
  CHANGE_TIMELINEFORM_COUNT: "CHANGE_TIMELINEFORM_COUNT",
  createTimelineform: (timelineform) => {
    return (dispatch, getState) => {
      dispatch({
        type: timelineformActions.CREATE_TIMELINEFORM,
        timelineforms: [timelineform, ...getState().timelineform.timelineforms],
      });
    };
  },
  updateTimelineform: (timelineform) => {
    return (dispatch, getState) => {
      dispatch({
        type: timelineformActions.UPDATE_TIMELINEFORM,
        timelineform,
      });
    };
  },
  editTimelineform: (timelineform) => {
    return (dispatch, getState) => {
      dispatch({
        type: timelineformActions.EDIT_TIMELINEFORM,
        timelineform,
      });
    };
  },
  getTimelineforms: (timelineforms, timelineformsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: timelineformActions.GET_TIMELINEFORM_LIST,
        timelineforms,
        timelineformsCount,
      });
    };
  },
  getSummary: (count, time) => {
    return (dispatch, getState) => {
      dispatch({
        type: timelineformActions.GET_TIMELINEFORM_LIST,
        count,
        time,
      });
    };
  },
  deleteTimelineform: (id) => {
    return (dispatch, getState) => {
      var currentTimelineforms = getState().timelineform.timelineforms.filter((s) => s.id !== id);
      dispatch({
        type: timelineformActions.DELETE_TIMELINEFORM,
        timelineforms: currentTimelineforms,
      });
    };
  },
  toggleModal: (timelineform = null) => ({
    type: timelineformActions.TIMELINEFORM_TOGGLE_HANDLE_MODAL,
    timelineform,
  }),
  changePagination: (pagination) => {
    return (dispatch, getState) => {
      dispatch({
        type: timelineformActions.CHANGE_TIMELINEFORM_PAGINATION,
        pagination,
      });
    };
  },
  updateTimelineformsCount: (timelineformsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: timelineformActions.CHANGE_TIMELINEFORM_COUNT,
        timelineformsCount,
      });
    };
  },
};
export default timelineformActions;

