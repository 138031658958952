import commentActions from "./actions";
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    comments: [],
    commentsCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    comment: {
    }
};

export default function commentReducer(state = initState, action) {
    switch (action.type) {
        case commentActions.CREATE_COMMENT:
            initState.comment = {}
            return {
                ...state,
                comments: action.comments,
                comment: initState.comment
            };
        case commentActions.UPDATE_COMMENT:
            var commentIndex = state.comments.findIndex(u => u.id === action.comment.id);
            var comments = state.comments;
            comments[commentIndex] = action.comment;
            return {
                ...state,
                comments: [...comments]
            };
        case commentActions.EDIT_COMMENT:
            return {
                ...state,
                comment: action.comment,
            };
        case commentActions.GET_COMMENT_LIST:
            return {
                ...state,
                comments: action.comments,
                commentsCount: action.commentsCount,
            };
        case commentActions.DELETE_COMMENT:
            return {
                ...state,
                comments: action.comments,
            };
        case commentActions.COMMENT_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                comment: action.comment === null ? initState.comment : action.comment,
            };
        case commentActions.COMMENT_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                comment: action.comment === null ? initState.comment : action.comment,

            };
        case commentActions.CHANGE_COMMENT_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case commentActions.CHANGE_COMMENT_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}