import logActions from './actions';

const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    logs: [],
    logsCount: 0,
    pagination: {
        currentpage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: '',
    },
    log: {
        
    }  
};

export default function logReducer(state = initState, action) {
    switch (action.type) {
        case logActions.GET_LOG_LIST:
            return {
                ...state,
                logs: action.logs,
                logsCount: action.logsCount,
        };
        case logActions.CHANGE_LOG_PAGINATION:
            return {
                ...state,
                pagination: {...action.pagination},
        };
        case logActions.CHANGE_LOG_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
        };
        case logActions.LOG_TOGGLE_HANDLE_MODAL:
            return {
                modalActive: !state.modalActive,
                log : action.log === null ? initState.log : action.log,
        };
        case logActions.EDIT_LOG:
            return {
                ...state,
                log: action.log,
        };    
        default:
            return state;
    }
}