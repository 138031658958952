const gatewayActions = {
    CREATE_GATEWAY: 'CREATE_GATEWAY',
    UPDATE_GATEWAY: 'UPDATE_GATEWAY',
    GET_GATEWAY: 'GET_GATEWAY',
    GET_GATEWAY_LIST: 'GET_GATEWAY_LIST',
    EDIT_GATEWAY: 'EDIT_GATEWAY',
    DELETE_GATEWAY: 'DELETE_GATEWAY',
    GATEWAY_TOGGLE_HANDLE_MODAL: 'GATEWAY_TOGGLE_HANDLE_MODAL',
    GATEWAY_TOGGLE2_HANDLE_MODAL: 'GATEWAY_TOGGLE2_HANDLE_MODAL',
    CHANGE_GATEWAY_PAGINATION: 'CHANGE_GATEWAY_PAGINATION',
    CHANGE_GATEWAY_COUNT: 'CHANGE_GATEWAY_COUNT',
    createGateway: gateway => {
        return (dispatch, getState) => {
            dispatch({
                type: gatewayActions.CREATE_GATEWAY,
                gateways: [gateway, ...getState().gateway.gateways],
            });
        };
    },
    updateGateway: gateway => {
        return (dispatch, getState) => {
            dispatch({
                type: gatewayActions.UPDATE_GATEWAY,
                gateway,
            });
        };
    },
    editGateway: gateway => {
        return (dispatch, getState) => {
            dispatch({
                type: gatewayActions.EDIT_GATEWAY,
                gateway,
            });
        };
    },
    getGateways: (gateways, gatewaysCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: gatewayActions.GET_GATEWAY_LIST,
                gateways,
                gatewaysCount
            });
        };
    },
    deleteGateway: id => {
        return (dispatch, getState) => {
            var currentGateways = getState().gateway.gateways.filter(s => s.id !== id);
            dispatch({
                type: gatewayActions.DELETE_GATEWAY,
                gateways: currentGateways,
            });
        };
    },
    toggleModal: (gateway = null) => ({
        type: gatewayActions.GATEWAY_TOGGLE_HANDLE_MODAL,
        gateway,
    }),
    toggleModal2: (gateway = null) => ({
        type: gatewayActions.GATEWAY_TOGGLE2_HANDLE_MODAL,
        gateway,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: gatewayActions.CHANGE_GATEWAY_PAGINATION,
                pagination,
            });
        };
    },
    updateGatewaysCount: gatewaysCount => {
        return (dispatch, getState) => {
            dispatch({
                type: gatewayActions.CHANGE_GATEWAY_COUNT,
                gatewaysCount,
            });
        };
    }

};
export default gatewayActions;