import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import Mails from "@iso/redux/mail/reducer";
import Calendar from "@iso/redux/calendar/reducer";
import Box from "@iso/redux/box/reducer";
import Notes from "@iso/redux/notes/reducer";
import Todos from "@iso/redux/todos/reducer";
import Contacts from "@iso/redux/contacts/reducer";
import Cards from "@iso/redux/card/reducer";
import DynamicChartComponent from "@iso/redux/dynamicEchart/reducer";
import Ecommerce from "@iso/redux/ecommerce/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import Invoices from "@iso/redux/invoice/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import YoutubeSearch from "@iso/redux/youtubeSearch/reducers";
import scrumBoard from "@iso/redux/scrumBoard/reducer";
import drawer from "@iso/redux/drawer/reducer";
import modal from "@iso/redux/modal/reducer";
import profile from "@iso/redux/profile/reducer";
import githubSearch from "@iso/redux/githubSearch/reducers";
import quiz from "@iso/redux/quiz/reducer";
import user from "@iso/redux/user/reducer";
import announcement from "@iso/redux/announcement/reducer";
import client from "@iso/redux/client/reducer";
import module from "@iso/redux/module/reducer";
import sidebarmenu from "@iso/redux/sidebarmenu/reducer";
import role from "@iso/redux/role/reducer";
import roleGroup from "@iso/redux/roleGroup/reducer";
import customer from "@iso/redux/customer/reducer";
import apps from "@iso/redux/apps/reducer";
import contract from "@iso/redux/contract/reducer";
import log from "@iso/redux/log/reducer";

//small erp
import country from "@iso/redux/erp/country/reducer";
import unitType from "@iso/redux/erp/unitType/reducer";
// import customer from '@iso/redux/erp/customer/reducer'
import product from "@iso/redux/erp/product/reducer";
import productMedia from "@iso/redux/erp/productMedia/reducer";
import warehouse from "@iso/redux/erp/warehouse/reducer";
import warehouseProduct from "@iso/redux/erp/warehouseProduct/reducer";
import sales from "@iso/redux/erp/sales/reducer";
import retailSales from "@iso/redux/erp/retailSales/reducer";
import purchase from "@iso/redux/erp/purchases/reducer";
import erpUserSettings from "@iso/redux/erp/erpUserSettings/reducer";
import erpClientSettings from "@iso/redux/erp/erpClientSettings/reducer";
import warehouseProductTransfer from "@iso/redux/erp/warehouseProductTransfer/reducer";
import wholesaleStockDistribute from "@iso/redux/erp/wholesaleStockDistribute/reducer";
import order from "@iso/redux/erp/order/reducer";
import orderDetail from "@iso/redux/erp/orderDetail/reducer";
import tag from "@iso/redux/erp/tag/reducer";

//form.api
import evidence from "@iso/redux/pestomat/evidence/reducer";
import form from "@iso/redux/pestomat/form/reducer";
import medicine from "@iso/redux/pestomat/medicine/reducer";
import place from "@iso/redux/pestomat/place/reducer";
import location from "@iso/redux/pestomat/location/reducer";
import customers from "@iso/redux/pestomat/customer/reducer";
import logging from "@iso/redux/logging/reducer";
import unit from "@iso/redux/pestomat/unit/reducer";
import suggestion from "@iso/redux/pestomat/suggestion/reducer";
import placeFB from "@iso/redux/pestomat/placeFB/reducer";
import qualityfile from "@iso/redux/pestomat/qualityfile/reducer";
import timelineform from "@iso/redux/pestomat/timelineform/reducer";

//feeder
import feedback from "@iso/redux/feeder/feedback/reducer";
import feedbackcategory from "@iso/redux/feeder/feedbackcategory/reducer";
import comment from "@iso/redux/feeder/comment/reducer";
import device from "@iso/redux/feeder/device/reducer";
import products from "@iso/redux/feeder/product/reducer";
import sss from "@iso/redux/feeder/sss/reducer";
import guide from "@iso/redux/feeder/guide/reducer";
import productimage from "@iso/redux/feeder/productimage/reducer";
import slider from "@iso/redux/feeder/slider/reducer";

//TRAP
import trapdevice from "@iso/redux/trap/device/reducer";
import devicelog from "@iso/redux/trap/devicelog/reducer";
import gateway from "@iso/redux/trap/gateway/reducer";

//alesta
import alesta_Country from "@iso/redux/alesta/country/reducer";
import alesta_CurrencyType from "@iso/redux/alesta/currencyType/reducer";
import alesta_DeliveryType from "@iso/redux/alesta/deliveryType/reducer";
import alesta_FicheType from "@iso/redux/alesta/ficheType/reducer";
import alesta_CampaignType from "@iso/redux/alesta/campaignType/reducer";
import alesta_Company from "@iso/redux/alesta/company/reducer";
import alesta_Category from "@iso/redux/alesta/category/reducer";
import alesta_Warehouse from "@iso/redux/alesta/warehouse/reducer";
import alesta_City from "@iso/redux/alesta/city/reducer";
import alesta_Document from "@iso/redux/alesta/document/reducer";

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  Contacts,
  Cards,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  YoutubeSearch,
  scrumBoard,
  modal,
  drawer,
  profile,
  githubSearch,
  quiz,
  user,
  client,
  sidebarmenu,
  role,
  module,
  roleGroup,
  customer,
  evidence,
  form,
  medicine,
  place,
  location,
  customers,
  country,
  unitType,
  suggestion,
  timelineform,
  placeFB,
  qualityfile,
  product,
  productMedia,
  warehouse,
  warehouseProduct,
  tag,
  order,
  orderDetail,
  sales,
  retailSales,
  purchase,
  erpUserSettings,
  erpClientSettings,
  warehouseProductTransfer,
  wholesaleStockDistribute,
  alesta_Country,
  alesta_CurrencyType,
  alesta_DeliveryType,
  alesta_FicheType,
  alesta_CampaignType,
  alesta_Company,
  alesta_Category,
  alesta_Warehouse,
  alesta_City,
  alesta_Document,
  feedback,
  feedbackcategory,
  comment,
  device,
  products,
  sss,
  guide,
  productimage,
  trapdevice,
  devicelog,
  gateway,
  slider,
  apps,
  contract,
  log,
  unit,
  announcement,
  logging,
});

