const contractActions = {
    CREATE_CONTRACT: 'CREATE_CONTRACT',
    UPDATE_CONTRACT: 'UPDATE_CONTRACT',
    GET_CONTRACT: 'GET_CONTRACT',
    GET_CONTRACT_LIST: 'GET_CONTRACT_LIST',
    EDIT_CONTRACT: 'EDIT_CONTRACT',
    DELETE_CONTRACT: 'DELETE_CONTRACT',
    CONTRACT_TOGGLE_HANDLE_MODAL: 'CONTRACT_TOGGLE_HANDLE_MODAL',
    CHANGE_CONTRACT_PAGINATION: 'CHANGE_CONTRACT_PAGINATION',
    CHANGE_CONTRACT_COUNT: 'CHANGE_CONTRACT_COUNT',
    createContract: contract => {
        return (dispatch, getState) => {
            dispatch({
                type: contractActions.CREATE_CONTRACT,
                contracts: [contract, ...getState().contract.contracts],
            });
        };
    },
    updateContract: contract => {
        return (dispatch, getState) => {
            dispatch({
                type: contractActions.UPDATE_CONTRACT,
                contract,
            });
        };
    },
    editContract: contract => {
        return (dispatch, getState) => {
            dispatch({
                type: contractActions.EDIT_CONTRACT,
                contract,
            });
        };
    },
    getContracts: (contracts, contractsCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: contractActions.GET_CONTRACT_LIST,
                contracts,
                contractsCount
            });
        };
    },
    deleteContract: id => {
        return (dispatch, getState) => {
            var currentContracts = getState().contract.contracts.filter(s => s.id !== id);
            dispatch({
                type: contractActions.DELETE_CONTRACT,
                contracts: currentContracts,
            });
        };
    },
    toggleModal: (contract = null) => ({
        type: contractActions.CONTRACT_TOGGLE_HANDLE_MODAL,
        contract,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: contractActions.CHANGE_CONTRACT_PAGINATION,
                pagination,
            });
        };
    },
    updateContractsCount: contractsCount => {
        return (dispatch, getState) => {
            dispatch({
                type: contractActions.CHANGE_CONTRACT_COUNT,
                contractsCount,
            });
        };
    }

};
export default contractActions;