import warehouseActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  warehouses: [],
  warehousesCount: 0,
  pagination: {
    currentPage: 1,
    descending: true,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id'
  },
  warehouse: {
  }
};

export default function warehouseReducer(state = initState, action) {
  switch (action.type) {
    case warehouseActions.CREATE_WAREHOUSE:
      initState.warehouse = {}
      return {
        ...state,
        warehouses: action.warehouses,
        warehouse: initState.warehouse
      };
    case warehouseActions.UPDATE_WAREHOUSE:
      let warehouseIndex = state.warehouses.findIndex(u => u.id === action.warehouse.id);
      let warehouses = state.warehouses;
      warehouses[warehouseIndex] = action.warehouse;
      return {
        ...state,
        warehouses
      };
    case warehouseActions.EDIT_WAREHOUSE:
      return {
        ...state,
        warehouse: action.warehouse,
      };
    case warehouseActions.GET_WAREHOUSE_LIST:
      return {
        ...state,
        warehouses: action.warehouses,
        warehousesCount: action.warehousesCount,
      };
    case warehouseActions.DELETE_WAREHOUSE:
      return {
        ...state,
        warehouses: action.warehouses,
      };
    case warehouseActions.WAREHOUSE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        warehouse: action.warehouse === null ? initState.warehouse : action.warehouse,
      };
    case warehouseActions.CHANGE_WAREHOUSE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case warehouseActions.CHANGE_WAREHOUSE_COUNT:
      return {
        ...state,
        warehousesCount: action.warehousesCount,
      };
    default:
      return state;
  }
}
