const suggestionActions = {
  CREATE_SUGGESTION: "CREATE_SUGGESTION",
  UPDATE_SUGGESTION: "UPDATE_SUGGESTION",
  GET_SUGGESTION: "GET_SUGGESTION",
  GET_SUGGESTION_LIST: "GET_SUGGESTION_LIST",
  EDIT_SUGGESTION: "EDIT_SUGGESTION",
  DELETE_SUGGESTION: "DELETE_SUGGESTION",
  SUGGESTION_TOGGLE_HANDLE_MODAL: "SUGGESTION_TOGGLE_HANDLE_MODAL",
  CHANGE_SUGGESTION_PAGINATION: "CHANGE_SUGGESTION_PAGINATION",
  CHANGE_SUGGESTION_COUNT: "CHANGE_SUGGESTION_COUNT",
  createSuggestion: (suggestion) => {
    return (dispatch, getState) => {
      dispatch({
        type: suggestionActions.CREATE_SUGGESTION,
        suggestions: [suggestion, ...getState().suggestion.suggestions],
      });
    };
  },
  updateSuggestion: (suggestion) => {
    return (dispatch, getState) => {
      dispatch({
        type: suggestionActions.UPDATE_SUGGESTION,
        suggestion,
      });
    };
  },
  editSuggestion: (suggestion) => {
    return (dispatch, getState) => {
      dispatch({
        type: suggestionActions.EDIT_SUGGESTION,
        suggestion,
      });
    };
  },
  getSuggestions: (suggestions, suggestionsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: suggestionActions.GET_SUGGESTION_LIST,
        suggestions,
        suggestionsCount,
      });
    };
  },
  deleteSuggestion: (id) => {
    return (dispatch, getState) => {
      var currentSuggestions = getState().suggestion.suggestions.filter((s) => s.id !== id);
      dispatch({
        type: suggestionActions.DELETE_SUGGESTION,
        suggestions: currentSuggestions,
      });
    };
  },
  toggleModal: (suggestion = null) => ({
    type: suggestionActions.SUGGESTION_TOGGLE_HANDLE_MODAL,
    suggestion,
  }),
  changePagination: (pagination) => {
    return (dispatch, getState) => {
      dispatch({
        type: suggestionActions.CHANGE_SUGGESTION_PAGINATION,
        pagination,
      });
    };
  },
  updateSuggestionsCount: (suggestionsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: suggestionActions.CHANGE_SUGGESTION_COUNT,
        suggestionsCount,
      });
    };
  },
};
export default suggestionActions;

