const roleActions = {
    CREATE_ROLE: 'CREATE_ROLE',
    UPDATE_ROLE: 'UPDATE_ROLE',
    GET_ROLE: 'GET_ROLE',
    GET_ROLE_LIST: 'GET_ROLE_LIST',
    EDIT_ROLE: 'EDIT_ROLE',
    DELETE_ROLE: 'DELETE_ROLE',
    ROLE_TOGGLE_HANDLE_MODAL: 'ROLE_TOGGLE_HANDLE_MODAL',
    CHANGE_ROLE_PAGINATION: 'CHANGE_ROLE_PAGINATION',
    CHANGE_ROLE_COUNT: 'CHANGE_ROLE_COUNT',
    createRole: role => {
      return (dispatch, getState) => {
        dispatch({
          type: roleActions.CREATE_ROLE,
          roles: [role, ...getState().role.roles],
        });
      };
    },
    updateRole: role => {
      return (dispatch, getState) => {
        dispatch({
          type: roleActions.UPDATE_ROLE,
          role,
        });
      };
    },
    editRole: role => {
      return (dispatch, getState) => {
        dispatch({
          type: roleActions.EDIT_ROLE,
          role,
        });
      };
    },
    getRoles: (roles, rolesCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: roleActions.GET_ROLE_LIST,
          roles,
          rolesCount
        });
      };
    },
    deleteRole: id => {
      return (dispatch, getState) => {
        var currentRoles = getState().role.roles.filter(s => s.id !== id);
        dispatch({
          type: roleActions.DELETE_ROLE,
          roles: currentRoles,
        });
      };
    },
    toggleModal: (role = null) => ({
      type: roleActions.ROLE_TOGGLE_HANDLE_MODAL,
      role,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: roleActions.CHANGE_ROLE_PAGINATION,
          pagination,
        });
      };
    },
    updateRolesCount: rolesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: roleActions.CHANGE_ROLE_COUNT,
          rolesCount,
        });
      };
    }
  
  };
  export default roleActions;