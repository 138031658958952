import locationActions from "./actions";
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    locations: [],
    locationsCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,
    },
    location: {
    }
};

export default function locationReducer(state = initState, action) {
    switch (action.type) {
        case locationActions.CREATE_LOCATION:
            initState.location = {}
            return {
                ...state,
                locations: action.locations,
                location: initState.location
            };
        case locationActions.UPDATE_LOCATION:
            var locationIndex = state.locations.findIndex(u => u.id === action.location.id);
            var locations = state.locations;
            locations[locationIndex] = action.location;
            return {
                ...state,
                locations: [...locations]
            };
        case locationActions.EDIT_LOCATION:
            return {
                ...state,
                location: action.location,
            };
        case locationActions.GET_LOCATION_LIST:
            return {
                ...state,
                locations: action.locations,
                locationsCount: action.locationsCount,
            };
        case locationActions.DELETE_LOCATION:
            return {
                ...state,
                locations: action.locations,
            };
        case locationActions.LOCATION_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                location: action.location === null ? initState.location : action.location,
            };
        case locationActions.LOCATION_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                location: action.location === null ? initState.location : action.location,

            };
        case locationActions.CHANGE_LOCATION_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case locationActions.CHANGE_LOCATION_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}