const alesta_CurrencyTypeActions = {
    CREATE_ALESTA_CURRENCYTYPE: 'CREATE_ALESTA_CURRENCYTYPE',
    UPDATE_ALESTA_CURRENCYTYPE: 'UPDATE_ALESTA_CURRENCYTYPE',
    GET_ALESTA_CURRENCYTYPE: 'GET_ALESTA_CURRENCYTYPE',
    GET_ALESTA_CURRENCYTYPE_LIST: 'GET_ALESTA_CURRENCYTYPE_LIST',
    EDIT_ALESTA_CURRENCYTYPE: 'EDIT_ALESTA_CURRENCYTYPE',
    DELETE_ALESTA_CURRENCYTYPE: 'DELETE_ALESTA_CURRENCYTYPE',
    ALESTA_CURRENCYTYPE_TOGGLE_HANDLE_MODAL: 'ALESTA_CURRENCYTYPE_TOGGLE_HANDLE_MODAL',
    CHANGE_ALESTA_CURRENCYTYPE_PAGINATION: 'CHANGE_ALESTA_CURRENCYTYPE_PAGINATION',
    CHANGE_ALESTA_CURRENCYTYPE_COUNT: 'CHANGE_ALESTA_CURRENCYTYPE_COUNT',
    createAlesta_CurrencyType: alesta_CurrencyType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CurrencyTypeActions.CREATE_ALESTA_CURRENCYTYPE,
          alesta_CurrencyTypes: [alesta_CurrencyType, ...getState().alesta_CurrencyType.alesta_CurrencyTypes],
        });
      };
    },
    updateAlesta_CurrencyType: alesta_CurrencyType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CurrencyTypeActions.UPDATE_ALESTA_CURRENCYTYPE,
          alesta_CurrencyType,
        });
      };
    },
    editAlesta_CurrencyType: alesta_CurrencyType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CurrencyTypeActions.EDIT_ALESTA_CURRENCYTYPE,
          alesta_CurrencyType,
        });
      };
    },
    getAlesta_CurrencyTypes: (alesta_CurrencyTypes, alesta_CurrencyTypesCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CurrencyTypeActions.GET_ALESTA_CURRENCYTYPE_LIST,
          alesta_CurrencyTypes,
          alesta_CurrencyTypesCount
        });
      };
    },
    deleteAlesta_CurrencyType: id => {
      return (dispatch, getState) => {
        var currentAlesta_CurrencyTypes = getState().alesta_CurrencyType.alesta_CurrencyTypes.filter(s => s.id !== id);
        dispatch({
          type: alesta_CurrencyTypeActions.DELETE_ALESTA_CURRENCYTYPE,
          alesta_CurrencyTypes: currentAlesta_CurrencyTypes,
        });
      };
    },
    toggleModal: (alesta_CurrencyType = null) => ({
      type: alesta_CurrencyTypeActions.ALESTA_CURRENCYTYPE_TOGGLE_HANDLE_MODAL,
      alesta_CurrencyType,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CurrencyTypeActions.CHANGE_ALESTA_CURRENCYTYPE_PAGINATION,
          pagination,
        });
      };
    },
    updateAlesta_CurrencyTypesCount: alesta_CurrencyTypesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CurrencyTypeActions.CHANGE_ALESTA_CURRENCYTYPE_COUNT,
          alesta_CurrencyTypesCount,
        });
      };
    }
  
  };
  export default alesta_CurrencyTypeActions;