import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { notification } from "antd";
const firebaseConfig = {
  apiKey: "AIzaSyDf0XDBfH9zmtCtxAzv2jxcRYz4u_oiXu4",
  authDomain: "codebefore-project.firebaseapp.com",
  projectId: "codebefore-project",
  storageBucket: "codebefore-project.appspot.com",
  messagingSenderId: "431774333691",
  appId: "1:431774333691:web:03e893a3e7dfe187f90e5e",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = (clientId) => {
  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      return getToken(messaging, {
        vapidKey: `BKTgQrao-L10Qk1d8BnGWo4ogjqS3SwFVGlOrnr6VQeyvD66ijsi2DkO4GMD68bzYJusiu4vMbeZitZu4BD_xiU`,
      })
        .then((currentToken) => {
          if (currentToken) {
            try {
              //add token to firestore
              const db = getFirestore();

              const docRef = doc(db, "tokens", clientId.toString());

              getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                  console.log("Doc exists", docSnap.data());
                  if (docSnap.data().tokens.includes(currentToken.toString())) {
                    console.log("Token already exist");
                  } else {
                    setDoc(docRef, {
                      //token need to be array of tokens
                      tokens: [...docSnap.data().tokens, currentToken],
                      notifications: docSnap.data().notifications,
                    }).then(() => {
                      console.log("Document token successfully written!");
                    });
                  }
                } else {
                  setDoc(docRef, {
                    //token need to be array of tokens
                    tokens: [currentToken],
                    notifications: [],
                  }).then(() => {
                    console.log("Document token successfully written!");
                  });
                }
              });
            } catch (error) {
              console.error("Error writing document: ", error);
            }
          } else {
            console.log("Failed to generate the app registration token.");
          }
        })
        .catch((err) => {
          console.log("An error occurred when requesting to receive the token.", err);
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

//get firestore data and export

export const getFirestoreData = async (clientId) => {
  const db = getFirestore();
  const docRef = doc(db, "tokens", clientId.toString());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data().notifications;
  } else {
    console.log("No such document!");
  }
};

//change isReaded last 5 notification
export const changeIsReaded = async (clientId) => {
  const db = getFirestore();
  const docRef = doc(db, "tokens", clientId.toString());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const notifications = docSnap.data().notifications;
    const lastFiveNotifications = notifications.slice(-5);
    const updatedNotifications = notifications.map((notification) => {
      if (lastFiveNotifications.includes(notification)) {
        return { ...notification, isReaded: true };
      } else {
        return notification;
      }
    });
    setDoc(docRef, {
      //token need to be array of tokens
      tokens: docSnap.data().tokens,
      notifications: updatedNotifications,
    }).then(() => {
      console.log("Document write last notifications successfully written!");
    });
  } else {
    console.log("No such document!");
  }
};

export const deleteFirestoreToken = async (clientId) => {
  const db = getFirestore();
  //get currentToken
  const currentToken = await getToken(messaging, {
    vapidKey: `BKTgQrao-L10Qk1d8BnGWo4ogjqS3SwFVGlOrnr6VQeyvD66ijsi2DkO4GMD68bzYJusiu4vMbeZitZu4BD_xiU`,
  });

  const docRef = doc(db, "tokens", clientId.toString());
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const tokens = docSnap.data().tokens;
    const updatedTokens = tokens.filter((token) => token !== currentToken);
    setDoc(docRef, {
      //token need to be array of tokens
      tokens: updatedTokens,
      notifications: docSnap.data().notifications,
    }).then(() => {
      console.log("Document deleting successfully!");
    });
  } else {
    console.log("No such document!");
  }
};

