import roleGroupActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  roleGroups: [],
  roleGroupsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: ''
  },
  roleGroup: {
  }
};

export default function roleGroupReducer(state = initState, action) {
  switch (action.type) {
    case roleGroupActions.CREATE_ROLEGROUP:
      initState.roleGroup = {}
      return {
        ...state,
        roleGroups: action.roleGroups,
        roleGroup: initState.roleGroup
      };
    case roleGroupActions.UPDATE_ROLEGROUP:
      var roleGroupIndex = state.roleGroups.findIndex(u => u.id === action.roleGroup.id);
      var roleGroups = state.roleGroups;
      roleGroups[roleGroupIndex] = action.roleGroup;
      return {
        ...state,
        roleGroups:[...roleGroups]
      };
    case roleGroupActions.EDIT_ROLEGROUP:
      return {
        ...state,
        roleGroup: action.roleGroup,
      };
    case roleGroupActions.GET_ROLEGROUP_LIST:
      return {
        ...state,
        roleGroups: action.roleGroups,
        roleGroupsCount: action.roleGroupsCount,
      };
    case roleGroupActions.DELETE_ROLEGROUP:
      return {
        ...state,
        roleGroups: action.roleGroups,
      };
    case roleGroupActions.ROLEGROUP_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        roleGroup: action.roleGroup === null ? initState.roleGroup : action.roleGroup,
      };
    case roleGroupActions.CHANGE_ROLEGROUP_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case roleGroupActions.CHANGE_ROLEGROUP_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
