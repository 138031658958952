import evidenceActions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  evidences: [],
  evidencesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id',
    desc: true,
  },
  evidence: {
  }
};

export default function evidenceReducer(state = initState, action) {
  switch (action.type) {
    case evidenceActions.CREATE_EVIDENCE:
      initState.evidence = {}
      return {
        ...state,
        evidences: action.evidences,
        evidence: initState.evidence
      };
    case evidenceActions.UPDATE_EVIDENCE:
      var evidenceIndex = state.evidences.findIndex(u => u.id === action.evidence.id);
      var evidences = state.evidences;
      evidences[evidenceIndex] = action.evidence;
      return {
        ...state,
        evidences: [...evidences]
      };
    case evidenceActions.EDIT_EVIDENCE:
      return {
        ...state,
        evidence: action.evidence,
      };
    case evidenceActions.GET_EVIDENCE_LIST:
      return {
        ...state,
        evidences: action.evidences,
        evidencesCount: action.evidencesCount,
      };
    case evidenceActions.DELETE_EVIDENCE:
      return {
        ...state,
        evidences: action.evidences,
      };
    case evidenceActions.EVIDENCE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        evidence: action.evidence === null ? initState.evidence : action.evidence,
      };
    case evidenceActions.CHANGE_EVIDENCE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case evidenceActions.CHANGE_EVIDENCE_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
