import orderActions from './actions';
import moment from 'moment';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  orders: [],
  customers: [],
  warehouses: [],
  dateTimeRangePicker: [
    moment().seconds(0).minutes(0).hours(0),
    moment().seconds(59).minutes(59).hours(23)
  ],
  ordersCount: 0,
  pagination: {
    currentPage: 1,
    descending: true,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id'
  },
  order: {
  }
};

export default function orderReducer(state = initState, action) {
  switch (action.type) {
    case orderActions.CREATE_ORDER:
      initState.order = {}
      return {
        ...state,
        orders: action.orders,
        order: initState.order
      };
    case orderActions.UPDATE_ORDER:
      let orderIndex = state.orders.findIndex(u => u.id === action.order.id);
      let orders = state.orders;
      orders[orderIndex] = action.order;
      return {
        ...state,
        orders
      };
    case orderActions.EDIT_ORDER:
      return {
        ...state,
        order: action.order,
      };
    case orderActions.UPDATE_DATETIME_RANGE_PICKER:
      return {
        ...state,
        dateTimeRangePicker: action.dateTimeRangePicker,
      };
    case orderActions.GET_ORDER_LIST:
      return {
        ...state,
        orders: action.orders,
        ordersCount: action.ordersCount,
      };
    case orderActions.GET_ORDER_CUSTOMERS_LIST:
      return {
        ...state,
        customers: action.customers
      };
    case orderActions.GET_ORDER_WAREHOUSES_LIST:
      return {
        ...state,
        warehouses: action.warehouses
      };
    case orderActions.DELETE_ORDER:
      return {
        ...state,
        orders: action.orders,
      };
    case orderActions.ORDER_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        order: action.order === null ? initState.order : action.order,
      };
    case orderActions.CHANGE_ORDER_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case orderActions.CHANGE_ORDER_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
