const deviceActions = {
    CREATE_DEVICE: 'CREATE_DEVICE',
    UPDATE_DEVICE: 'UPDATE_DEVICE',
    GET_DEVICE: 'GET_DEVICE',
    GET_DEVICE_LIST: 'GET_DEVICE_LIST',
    EDIT_DEVICE: 'EDIT_DEVICE',
    DELETE_DEVICE: 'DELETE_DEVICE',
    DEVICE_TOGGLE_HANDLE_MODAL: 'DEVICE_TOGGLE_HANDLE_MODAL',
    DEVICE_TOGGLE2_HANDLE_MODAL: 'DEVICE_TOGGLE2_HANDLE_MODAL',
    CHANGE_DEVICE_PAGINATION: 'CHANGE_DEVICE_PAGINATION',
    CHANGE_DEVICE_COUNT: 'CHANGE_DEVICE_COUNT',
    createDevice: device => {
        return (dispatch, getState) => {
            dispatch({
                type: deviceActions.CREATE_DEVICE,
                devices: [device, ...getState().device.devices],
            });
        };
    },
    updateDevice: device => {
        return (dispatch, getState) => {
            dispatch({
                type: deviceActions.UPDATE_DEVICE,
                device,
            });
        };
    },
    editDevice: device => {
        return (dispatch, getState) => {
            dispatch({
                type: deviceActions.EDIT_DEVICE,
                device,
            });
        };
    },
    getDevices: (devices, devicesCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: deviceActions.GET_DEVICE_LIST,
                devices,
                devicesCount
            });
        };
    },
    deleteDevice: id => {
        return (dispatch, getState) => {
            var currentDevices = getState().device.devices.filter(s => s.id !== id);
            dispatch({
                type: deviceActions.DELETE_DEVICE,
                devices: currentDevices,
            });
        };
    },
    toggleModal: (device = null) => ({
        type: deviceActions.DEVICE_TOGGLE_HANDLE_MODAL,
        device,
    }),
    toggleModal2: (device = null) => ({
        type: deviceActions.DEVICE_TOGGLE2_HANDLE_MODAL,
        device,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: deviceActions.CHANGE_DEVICE_PAGINATION,
                pagination,
            });
        };
    },
    updateDevicesCount: devicesCount => {
        return (dispatch, getState) => {
            dispatch({
                type: deviceActions.CHANGE_DEVICE_COUNT,
                devicesCount,
            });
        };
    }

};
export default deviceActions;