import gatewayActions from './actions';
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    gateways: [],
    gatewaysCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    gateway: {
    }
};

export default function gatewayReducer(state = initState, action) {
    switch (action.type) {
        case gatewayActions.CREATE_GATEWAY:
            initState.gateway = {}
            return {
                ...state,
                gateways: action.gateways,
                gateway: initState.gateway
            };
        case gatewayActions.UPDATE_GATEWAY:
            var gatewayIndex = state.gateways.findIndex(u => u.id === action.gateway.id);
            var gateways = state.gateways;
            gateways[gatewayIndex] = action.gateway;
            return {
                ...state,
                gateways: [...gateways]
            };
        case gatewayActions.EDIT_GATEWAY:
            return {
                ...state,
                gateway: action.gateway,
            };
        case gatewayActions.GET_GATEWAY_LIST:
            return {
                ...state,
                gateways: action.gateways,
                gatewaysCount: action.gatewaysCount,
            };
        case gatewayActions.DELETE_GATEWAY:
            return {
                ...state,
                gateways: action.gateways,
            };
        case gatewayActions.GATEWAY_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                gateway: action.gateway === null ? initState.gateway : action.gateway,
            };
        case gatewayActions.GATEWAY_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                gateway: action.gateway === null ? initState.gateway : action.gateway,

            };
        case gatewayActions.CHANGE_GATEWAY_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case gatewayActions.CHANGE_GATEWAY_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}