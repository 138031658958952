import placeActions from "./actions";
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  modal2Active: false,
  places: [],
  placesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: "",
    sort: "id",
    desc: true,
    customerId: undefined,
  },
  place: {},
};

export default function placeReducer(state = initState, action) {
  switch (action.type) {
    case placeActions.CREATE_PLACE:
      initState.place = {};
      return {
        ...state,
        places: action.places,
        place: initState.place,
      };
    case placeActions.UPDATE_PLACE:
      var placeIndex = state.places.findIndex((u) => u.id === action.place.id);
      var places = state.places;
      places[placeIndex] = action.place;
      return {
        ...state,
        places: [...places],
      };
    case placeActions.EDIT_PLACE:
      return {
        ...state,
        place: action.place,
      };
    case placeActions.GET_PLACE_LIST:
      return {
        ...state,
        places: action.places,
        placesCount: action.placesCount,
      };
    case placeActions.DELETE_PLACE:
      return {
        ...state,
        places: action.places,
      };
    case placeActions.PLACE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        place: action.place === null ? initState.place : action.place,
      };
    case placeActions.PLACE_TOGGLE2_HANDLE_MODAL:
      return {
        ...state,
        modal2Active: !state.modal2Active,
        place: action.place === null ? initState.place : action.place,
      };
    case placeActions.CHANGE_PLACE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case placeActions.CHANGE_PLACE_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}

