var browserLanguage = navigator.language;
switch (browserLanguage) {
    case "en-US":
        browserLanguage = "english"
        break;
        case "tr-TR":
            browserLanguage = "turkish"
            break;
    default:
        browserLanguage = "english"
        break;
}
const language = localStorage.getItem('language') || browserLanguage;
export const langDir = 'ltr';
export default language;
