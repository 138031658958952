const loggingActions = {
  CREATE_LOGGING: "CREATE_LOGGING",
  UPDATE_LOGGING: "UPDATE_LOGGING",
  GET_LOGGING: "GET_LOGGING",
  GET_LOGGING_LIST: "GET_LOGGING_LIST",
  EDIT_LOGGING: "EDIT_LOGGING",
  DELETE_LOGGING: "DELETE_LOGGING",
  LOGGING_TOGGLE_HANDLE_MODAL: "LOGGING_TOGGLE_HANDLE_MODAL",
  CHANGE_LOGGING_PAGINATION: "CHANGE_LOGGING_PAGINATION",
  CHANGE_LOGGING_COUNT: "CHANGE_LOGGING_COUNT",
  createLogging: (logging) => {
    return (dispatch, getState) => {
      dispatch({
        type: loggingActions.CREATE_LOGGING,
        loggings: [logging, ...getState().logging.loggings],
      });
    };
  },
  updateLogging: (logging) => {
    return (dispatch, getState) => {
      dispatch({
        type: loggingActions.UPDATE_LOGGING,
        logging,
      });
    };
  },
  editLogging: (logging) => {
    return (dispatch, getState) => {
      dispatch({
        type: loggingActions.EDIT_LOGGING,
        logging,
      });
    };
  },
  getLoggings: (loggings, loggingsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: loggingActions.GET_LOGGING_LIST,
        loggings,
        loggingsCount,
      });
    };
  },
  deleteLogging: (id) => {
    return (dispatch, getState) => {
      var currentLoggings = getState().logging.loggings.filter((s) => s.id !== id);
      dispatch({
        type: loggingActions.DELETE_LOGGING,
        loggings: currentLoggings,
      });
    };
  },
  toggleModal: (logging = null) => ({
    type: loggingActions.LOGGING_TOGGLE_HANDLE_MODAL,
    logging,
  }),
  changePagination: (pagination) => {
    return (dispatch, getState) => {
      dispatch({
        type: loggingActions.CHANGE_LOGGING_PAGINATION,
        pagination,
      });
    };
  },
  updateLoggingsCount: (loggingsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: loggingActions.CHANGE_LOGGING_COUNT,
        loggingsCount,
      });
    };
  },
};
export default loggingActions;

