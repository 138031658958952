const sliderActions = {
    CREATE_SLIDER: 'CREATE_SLIDER',
    UPDATE_SLIDER: 'UPDATE_SLIDER',
    GET_SLIDER: 'GET_SLIDER',
    GET_SLIDER_LIST: 'GET_SLIDER_LIST',
    EDIT_SLIDER: 'EDIT_SLIDER',
    DELETE_SLIDER: 'DELETE_SLIDER',
    SLIDER_TOGGLE_HANDLE_MODAL: 'SLIDER_TOGGLE_HANDLE_MODAL',
    SLIDER_TOGGLE2_HANDLE_MODAL: 'SLIDER_TOGGLE2_HANDLE_MODAL',
    CHANGE_SLIDER_PAGINATION: 'CHANGE_SLIDER_PAGINATION',
    CHANGE_SLIDER_COUNT: 'CHANGE_SLIDER_COUNT',
    createSlider: slider => {
        return (dispatch, getState) => {
            dispatch({
                type: sliderActions.CREATE_SLIDER,
                sliders: [slider, ...getState().slider.sliders],
            });
        };
    },
    updateSlider: slider => {
        return (dispatch, getState) => {
            dispatch({
                type: sliderActions.UPDATE_SLIDER,
                slider,
            });
        };
    },
    editSlider: slider => {
        return (dispatch, getState) => {
            dispatch({
                type: sliderActions.EDIT_SLIDER,
                slider,
            });
        };
    },
    getSliders: (sliders, slidersCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: sliderActions.GET_SLIDER_LIST,
                sliders,
                slidersCount
            });
        };
    },
    deleteSlider: id => {
        return (dispatch, getState) => {
            var currentSliders = getState().slider.sliders.filter(s => s.id !== id);
            dispatch({
                type: sliderActions.DELETE_SLIDER,
                sliders: currentSliders,
            });
        };
    },
    toggleModal: (slider = null) => ({
        type: sliderActions.SLIDER_TOGGLE_HANDLE_MODAL,
        slider,
    }),
    toggleModal2: (slider = null) => ({
        type: sliderActions.SLIDER_TOGGLE2_HANDLE_MODAL,
        slider,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: sliderActions.CHANGE_SLIDER_PAGINATION,
                pagination,
            });
        };
    },
    updateSlidersCount: slidersCount => {
        return (dispatch, getState) => {
            dispatch({
                type: sliderActions.CHANGE_SLIDER_COUNT,
                slidersCount,
            });
        };
    }

};
export default sliderActions;