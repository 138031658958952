import announcementActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  announcements: [],
  announcementsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: ''
  },
  announcement: {
  }
};

export default function announcementReducer(state = initState, action) {
  switch (action.type) {
    case announcementActions.CREATE_ANNOUNCEMENT:
      return {
        ...state,
        announcements: action.announcements,
        announcement: initState.announcement
      };
    case announcementActions.UPDATE_ANNOUNCEMENT:
      let announcementIndex = state.announcements.findIndex(u => u.id === action.announcement.id);
      let announcements = state.announcements;
      announcements[announcementIndex] = action.announcement;
      return {
        ...state,
        announcements: [...announcements]
      };
    case announcementActions.EDIT_ANNOUNCEMENT:
      return {
        ...state,
        announcement: action.announcement,
      };
    case announcementActions.GET_ANNOUNCEMENT_LIST:
      return {
        ...state,
        announcements: action.announcements,
        announcementsCount: action.announcementsCount,
      };
    case announcementActions.DELETE_ANNOUNCEMENT:
      return {
        ...state,
        announcements: action.announcements,
      };
    case announcementActions.ANNOUNCEMENT_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        announcement: action.announcement === null ? initState.announcement : action.announcement,
      };
    case announcementActions.CHANGE_ANNOUNCEMENT_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case announcementActions.CHANGE_ANNOUNCEMENT_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
