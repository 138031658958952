import productActions from './actions';
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    products: [],
    productsCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    product: {
    }
};

export default function productReducer(state = initState, action) {
    switch (action.type) {
        case productActions.CREATE_PRODUCT:
            initState.product = {}
            return {
                ...state,
                products: action.products,
                product: initState.product
            };
        case productActions.UPDATE_PRODUCT:
            var productIndex = state.products.findIndex(u => u.id === action.product.id);
            var products = state.products;
            products[productIndex] = action.product;
            return {
                ...state,
                products: [...products]
            };
        case productActions.EDIT_PRODUCT:
            return {
                ...state,
                product: action.product,
            };
        case productActions.GET_PRODUCT_LIST:
            return {
                ...state,
                products: action.products,
                productsCount: action.productsCount,
            };
        case productActions.DELETE_PRODUCT:
            return {
                ...state,
                products: action.products,
            };
        case productActions.PRODUCT_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                product: action.product === null ? initState.product : action.product,
            };
        case productActions.PRODUCT_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                product: action.product === null ? initState.product : action.product,

            };
        case productActions.CHANGE_PRODUCT_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case productActions.CHANGE_PRODUCT_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}