const alesta_DeliveryTypeActions = {
    CREATE_ALESTA_DELIVERYTYPE: 'CREATE_ALESTA_DELIVERYTYPE',
    UPDATE_ALESTA_DELIVERYTYPE: 'UPDATE_ALESTA_DELIVERYTYPE',
    GET_ALESTA_DELIVERYTYPE: 'GET_ALESTA_DELIVERYTYPE',
    GET_ALESTA_DELIVERYTYPE_LIST: 'GET_ALESTA_DELIVERYTYPE_LIST',
    EDIT_ALESTA_DELIVERYTYPE: 'EDIT_ALESTA_DELIVERYTYPE',
    DELETE_ALESTA_DELIVERYTYPE: 'DELETE_ALESTA_DELIVERYTYPE',
    ALESTA_DELIVERYTYPE_TOGGLE_HANDLE_MODAL: 'ALESTA_DELIVERYTYPE_TOGGLE_HANDLE_MODAL',
    CHANGE_ALESTA_DELIVERYTYPE_PAGINATION: 'CHANGE_ALESTA_DELIVERYTYPE_PAGINATION',
    CHANGE_ALESTA_DELIVERYTYPE_COUNT: 'CHANGE_ALESTA_DELIVERYTYPE_COUNT',
    createAlesta_DeliveryType: alesta_DeliveryType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_DeliveryTypeActions.CREATE_ALESTA_DELIVERYTYPE,
          alesta_DeliveryTypes: [alesta_DeliveryType, ...getState().alesta_DeliveryType.alesta_DeliveryTypes],
        });
      };
    },
    updateAlesta_DeliveryType: alesta_DeliveryType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_DeliveryTypeActions.UPDATE_ALESTA_DELIVERYTYPE,
          alesta_DeliveryType,
        });
      };
    },
    editAlesta_DeliveryType: alesta_DeliveryType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_DeliveryTypeActions.EDIT_ALESTA_DELIVERYTYPE,
          alesta_DeliveryType,
        });
      };
    },
    getAlesta_DeliveryTypes: (alesta_DeliveryTypes, alesta_DeliveryTypesCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_DeliveryTypeActions.GET_ALESTA_DELIVERYTYPE_LIST,
          alesta_DeliveryTypes,
          alesta_DeliveryTypesCount
        });
      };
    },
    deleteAlesta_DeliveryType: id => {
      return (dispatch, getState) => {
        var currentAlesta_DeliveryTypes = getState().alesta_DeliveryType.alesta_DeliveryTypes.filter(s => s.id !== id);
        dispatch({
          type: alesta_DeliveryTypeActions.DELETE_ALESTA_DELIVERYTYPE,
          alesta_DeliveryTypes: currentAlesta_DeliveryTypes,
        });
      };
    },
    toggleModal: (alesta_DeliveryType = null) => ({
      type: alesta_DeliveryTypeActions.ALESTA_DELIVERYTYPE_TOGGLE_HANDLE_MODAL,
      alesta_DeliveryType,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_DeliveryTypeActions.CHANGE_ALESTA_DELIVERYTYPE_PAGINATION,
          pagination,
        });
      };
    },
    updateAlesta_DeliveryTypesCount: alesta_DeliveryTypesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_DeliveryTypeActions.CHANGE_ALESTA_DELIVERYTYPE_COUNT,
          alesta_DeliveryTypesCount,
        });
      };
    }
  
  };
  export default alesta_DeliveryTypeActions;