import unitTypeActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  unitTypes: [],
  unitTypesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    descending: false,
    take: 10,
    search: '',
    sort: 'id'
  },
  unitType: {
  }
};

export default function unitTypeReducer(state = initState, action) {
  switch (action.type) {
    case unitTypeActions.CREATE_UNITTYPE:
      initState.unitType = {}
      return {
        ...state,
        unitTypes: action.unitTypes,
        unitType: initState.unitType
      };
    case unitTypeActions.UPDATE_UNITTYPE:
      let unitTypeIndex = state.unitTypes.findIndex(u => u.id === action.unitType.id);
      let unitTypes = state.unitTypes;
      unitTypes[unitTypeIndex] = action.unitType;
      return {
        ...state,
        unitTypes
      };
    case unitTypeActions.EDIT_UNITTYPE:
      return {
        ...state,
        unitType: action.unitType,
      };
    case unitTypeActions.GET_UNITTYPE_LIST:
      return {
        ...state,
        unitTypes: action.unitTypes,
        unitTypesCount: action.unitTypesCount,
      };
    case unitTypeActions.DELETE_UNITTYPE:
      return {
        ...state,
        unitTypes: action.unitTypes,
      };
    case unitTypeActions.UNITTYPE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        unitType: action.unitType === null ? initState.unitType : action.unitType,
      };
    case unitTypeActions.CHANGE_UNITTYPE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case unitTypeActions.CHANGE_UNITTYPE_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
