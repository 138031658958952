import erpClientSettingsActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  clients: [],
  clientsCount: 0,
  pagination: {
    currentPage: 1,
    descending: false,
    skip: 0,
    take: 10,
    search: '',
    sort: 'name'
  },
  client: {}
};

export default function erpClientSettingsReducer(state = initState, action) {
  switch (action.type) {
    case erpClientSettingsActions.ERP_CLIENT_SETTINGS_SET_CLIENT_SETTINGS:
      let clientIndex = state.clients.findIndex(u => u.id === action.user.id);
      let clients = state.clients;
      clients[clientIndex] = action.client;
      return {
        ...state,
        clients
      };
    case erpClientSettingsActions.ERP_CLIENT_SETTINGS_EDIT_CLIENT:
      return {
        ...state,
        client: action.client,
      };
    case erpClientSettingsActions.ERP_CLIENT_SETTINGS_GET_CLIENT_LIST:
      return {
        ...state,
        clients: action.clients,
        clientsCount: action.clientsCount,
      };
    case erpClientSettingsActions.ERP_CLIENT_SETTINGS_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        client: action.client === null ? initState.client : action.client,
      };
    case erpClientSettingsActions.ERP_CLIENT_SETTINGS_CHANGE_CLIENT_PAGINATION:
      return {
        ...state,
        pagination: {...action.pagination},
      };
    case erpClientSettingsActions.ERP_CLIENT_SETTINGS_CHANGE_CLIENT_COUNT:
      return {
        ...state,
        clientsCount: action.clientsCount,
      };
    default:
      return state;
  }
}
