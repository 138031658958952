const productMediaActions = {
    CREATE_PRODUCT_MEDIA: 'CREATE_PRODUCT_MEDIA',
    UPDATE_PRODUCT_MEDIA: 'UPDATE_PRODUCT_MEDIA',
    GET_PRODUCT_MEDIA: 'GET_PRODUCT_MEDIA',
    GET_PRODUCT_MEDIA_LIST: 'GET_PRODUCT_MEDIA_LIST',
    EDIT_PRODUCT_MEDIA: 'EDIT_PRODUCT_MEDIA',
    DELETE_PRODUCT_MEDIA: 'DELETE_PRODUCT_MEDIA',
    PRODUCT_MEDIA_TOGGLE_HANDLE_MODAL: 'PRODUCT_MEDIA_TOGGLE_HANDLE_MODAL',
    CHANGE_PRODUCT_MEDIA_PAGINATION: 'CHANGE_PRODUCT_MEDIA_PAGINATION',
    CHANGE_PRODUCT_MEDIA_COUNT: 'CHANGE_PRODUCT_MEDIA_COUNT',
    createProductMedia: productMedia => {
      return (dispatch, getState) => {
        dispatch({
          type: productMediaActions.CREATE_PRODUCT_MEDIA,
          productMedias: [productMedia, ...getState().productMedia.productMedias],
        });
      };
    },
    updateProductMedia: productMedia => {
      return (dispatch, getState) => {
        dispatch({
          type: productMediaActions.UPDATE_PRODUCT_MEDIA,
          productMedia,
        });
      };
    },
    editProductMedia: productMedia => {
      return (dispatch, getState) => {
        dispatch({
          type: productMediaActions.EDIT_PRODUCT_MEDIA,
          productMedia,
        });
      };
    },
    getProductMedias: (productMedias, productMediasCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: productMediaActions.GET_PRODUCT_MEDIA_LIST,
          productMedias,
          productMediasCount
        });
      };
    },
    deleteProductMedia: id => {
      return (dispatch, getState) => {
        let currentProductMedias = getState().productMedia.productMedias.filter(s => s.id !== id);
        dispatch({
          type: productMediaActions.DELETE_PRODUCT_MEDIA,
          productMedias: currentProductMedias,
        });
      };
    },
    toggleModal: (productMedia = null) => ({
      type: productMediaActions.PRODUCT_MEDIA_TOGGLE_HANDLE_MODAL,
      productMedia,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: productMediaActions.CHANGE_PRODUCT_MEDIA_PAGINATION,
          pagination,
        });
      };
    },
    updateProductMediasCount: productMediasCount => {
      return (dispatch, getState) => {
        dispatch({
          type: productMediaActions.CHANGE_PRODUCT_MEDIA_COUNT,
          productMediasCount,
        });
      };
    }
  
  };
  export default productMediaActions;