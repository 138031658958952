const alesta_CompanyActions = {
    CREATE_ALESTA_COMPANY: 'CREATE_ALESTA_COMPANY',
    UPDATE_ALESTA_COMPANY: 'UPDATE_ALESTA_COMPANY',
    GET_ALESTA_COMPANY: 'GET_ALESTA_COMPANY',
    GET_ALESTA_COMPANY_LIST: 'GET_ALESTA_COMPANY_LIST',
    EDIT_ALESTA_COMPANY: 'EDIT_ALESTA_COMPANY',
    DELETE_ALESTA_COMPANY: 'DELETE_ALESTA_COMPANY',
    ALESTA_COMPANY_TOGGLE_HANDLE_MODAL: 'ALESTA_COMPANY_TOGGLE_HANDLE_MODAL',
    CHANGE_ALESTA_COMPANY_PAGINATION: 'CHANGE_ALESTA_COMPANY_PAGINATION',
    CHANGE_ALESTA_COMPANY_COUNT: 'CHANGE_ALESTA_COMPANY_COUNT',
    createAlesta_Company: alesta_Company => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CompanyActions.CREATE_ALESTA_COMPANY,
          alesta_Companys: [alesta_Company, ...getState().alesta_Company.alesta_Companys],
        });
      };
    },
    updateAlesta_Company: alesta_Company => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CompanyActions.UPDATE_ALESTA_COMPANY,
          alesta_Company,
        });
      };
    },
    editAlesta_Company: alesta_Company => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CompanyActions.EDIT_ALESTA_COMPANY,
          alesta_Company,
        });
      };
    },
    getAlesta_Companys: (alesta_Companys, alesta_CompanysCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CompanyActions.GET_ALESTA_COMPANY_LIST,
          alesta_Companys,
          alesta_CompanysCount
        });
      };
    },
    deleteAlesta_Company: id => {
      return (dispatch, getState) => {
        var currentAlesta_Companys = getState().alesta_Company.alesta_Companys.filter(s => s.id !== id);
        dispatch({
          type: alesta_CompanyActions.DELETE_ALESTA_COMPANY,
          alesta_Companys: currentAlesta_Companys,
        });
      };
    },
    toggleModal: (alesta_Company = null) => ({
      type: alesta_CompanyActions.ALESTA_COMPANY_TOGGLE_HANDLE_MODAL,
      alesta_Company,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CompanyActions.CHANGE_ALESTA_COMPANY_PAGINATION,
          pagination,
        });
      };
    },
    updateAlesta_CompanysCount: alesta_CompanysCount => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CompanyActions.CHANGE_ALESTA_COMPANY_COUNT,
          alesta_CompanysCount,
        });
      };
    }
  
  };
  export default alesta_CompanyActions;