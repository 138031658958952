const alesta_FicheTypeActions = {
    CREATE_ALESTA_FICHETYPE: 'CREATE_ALESTA_FICHETYPE',
    UPDATE_ALESTA_FICHETYPE: 'UPDATE_ALESTA_FICHETYPE',
    GET_ALESTA_FICHETYPE: 'GET_ALESTA_FICHETYPE',
    GET_ALESTA_FICHETYPE_LIST: 'GET_ALESTA_FICHETYPE_LIST',
    EDIT_ALESTA_FICHETYPE: 'EDIT_ALESTA_FICHETYPE',
    DELETE_ALESTA_FICHETYPE: 'DELETE_ALESTA_FICHETYPE',
    ALESTA_FICHETYPE_TOGGLE_HANDLE_MODAL: 'ALESTA_FICHETYPE_TOGGLE_HANDLE_MODAL',
    CHANGE_ALESTA_FICHETYPE_PAGINATION: 'CHANGE_ALESTA_FICHETYPE_PAGINATION',
    CHANGE_ALESTA_FICHETYPE_COUNT: 'CHANGE_ALESTA_FICHETYPE_COUNT',
    createAlesta_FicheType: alesta_FicheType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_FicheTypeActions.CREATE_ALESTA_FICHETYPE,
          alesta_FicheTypes: [alesta_FicheType, ...getState().alesta_FicheType.alesta_FicheTypes],
        });
      };
    },
    updateAlesta_FicheType: alesta_FicheType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_FicheTypeActions.UPDATE_ALESTA_FICHETYPE,
          alesta_FicheType,
        });
      };
    },
    editAlesta_FicheType: alesta_FicheType => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_FicheTypeActions.EDIT_ALESTA_FICHETYPE,
          alesta_FicheType,
        });
      };
    },
    getAlesta_FicheTypes: (alesta_FicheTypes, alesta_FicheTypesCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_FicheTypeActions.GET_ALESTA_FICHETYPE_LIST,
          alesta_FicheTypes,
          alesta_FicheTypesCount
        });
      };
    },
    deleteAlesta_FicheType: id => {
      return (dispatch, getState) => {
        var currentAlesta_FicheTypes = getState().alesta_FicheType.alesta_FicheTypes.filter(s => s.id !== id);
        dispatch({
          type: alesta_FicheTypeActions.DELETE_ALESTA_FICHETYPE,
          alesta_FicheTypes: currentAlesta_FicheTypes,
        });
      };
    },
    toggleModal: (alesta_FicheType = null) => ({
      type: alesta_FicheTypeActions.ALESTA_FICHETYPE_TOGGLE_HANDLE_MODAL,
      alesta_FicheType,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_FicheTypeActions.CHANGE_ALESTA_FICHETYPE_PAGINATION,
          pagination,
        });
      };
    },
    updateAlesta_FicheTypesCount: alesta_FicheTypesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_FicheTypeActions.CHANGE_ALESTA_FICHETYPE_COUNT,
          alesta_FicheTypesCount,
        });
      };
    }
  
  };
  export default alesta_FicheTypeActions;