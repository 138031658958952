import warehouseProductActions from './actions';
import warehouseActions from "../warehouse/actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  warehouseProducts: [],
  warehouseProductsCount: 0,
  products: [],
  warehouses: [],
  pagination: {
    currentPage: 1,
    descending: true,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id'
  },
  warehouseProduct: {
  }
};

export default function warehouseProductReducer(state = initState, action) {
  switch (action.type) {
    case warehouseProductActions.CREATE_WAREHOUSE_PRODUCT:
      initState.warehouseProduct = {}
      return {
        ...state,
        warehouseProducts: action.warehouseProducts,
        warehouseProduct: initState.warehouseProduct
      };
    case warehouseProductActions.UPDATE_WAREHOUSE_PRODUCT:
      let warehouseProductIndex = state.warehouseProducts.findIndex(u => u.id === action.warehouseProduct.id);
      let warehouseProducts = state.warehouseProducts;
      warehouseProducts[warehouseProductIndex] = action.warehouseProduct;
      return {
        ...state,
        warehouseProducts
      };
    case warehouseProductActions.EDIT_WAREHOUSE_PRODUCT:
      return {
        ...state,
        warehouseProduct: action.warehouseProduct,
      };
    case warehouseProductActions.GET_WAREHOUSE_PRODUCT_LIST:
      return {
        ...state,
        warehouseProducts: action.warehouseProducts,
        warehouseProductsCount: action.warehouseProductsCount,
      };
    case warehouseProductActions.GET_WAREHOUSE_PRODUCT_WAREHOUSE_LIST:
      return {
        ...state,
        warehouses: action.warehouses,
      };
    case warehouseProductActions.GET_WAREHOUSE_PRODUCT_PRODUCT_LIST:
      return {
        ...state,
        products: action.products,
      };
    case warehouseProductActions.DELETE_WAREHOUSE_PRODUCT:
      return {
        ...state,
        warehouseProducts: action.warehouseProducts,
      };
    case warehouseProductActions.WAREHOUSE_PRODUCT_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        warehouseProduct: action.warehouseProduct === null ? initState.warehouseProduct : action.warehouseProduct,
      };
    case warehouseProductActions.CHANGE_WAREHOUSE_PRODUCT_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case warehouseProductActions.CHANGE_WAREHOUSE_PRODUCT_COUNT:
      return {
        ...state,
        warehouseProductsCount: action.warehouseProductsCount,
      };
    default:
      return state;
  }
}
