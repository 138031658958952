import sliderActions from './actions';
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    sliders: [],
    slidersCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    slider: {
    }
};

export default function sliderReducer(state = initState, action) {
    switch (action.type) {
        case sliderActions.CREATE_SLIDER:
            initState.slider = {}
            return {
                ...state,
                sliders: action.sliders,
                slider: initState.slider
            };
        case sliderActions.UPDATE_SLIDER:
            var sliderIndex = state.sliders.findIndex(u => u.id === action.slider.id);
            var sliders = state.sliders;
            sliders[sliderIndex] = action.slider;
            return {
                ...state,
                sliders: [...sliders]
            };
        case sliderActions.EDIT_SLIDER:
            return {
                ...state,
                slider: action.slider,
            };
        case sliderActions.GET_SLIDER_LIST:
            return {
                ...state,
                sliders: action.sliders,
                slidersCount: action.slidersCount,
            };
        case sliderActions.DELETE_SLIDER:
            return {
                ...state,
                sliders: action.sliders,
            };
        case sliderActions.SLIDER_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                slider: action.slider === null ? initState.slider : action.slider,
            };
        case sliderActions.SLIDER_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                slider: action.slider === null ? initState.slider : action.slider,

            };
        case sliderActions.CHANGE_SLIDER_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case sliderActions.CHANGE_SLIDER_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}