import loggingActions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  loggings: [],
  loggingsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: "",
    sort: "",
  },
  logging: {},
};

export default function loggingReducer(state = initState, action) {
  switch (action.type) {
    case loggingActions.CREATE_LOGGING:
      initState.logging = {};
      return {
        ...state,
        loggings: action.loggings,
        logging: initState.logging,
      };
    case loggingActions.UPDATE_LOGGING:
      var loggingIndex = state.loggings.findIndex((u) => u.id === action.logging.id);
      var loggings = state.loggings;
      loggings[loggingIndex] = action.logging;
      return {
        ...state,
        loggings: [...loggings],
      };
    case loggingActions.EDIT_LOGGING:
      return {
        ...state,
        logging: action.logging,
      };
    case loggingActions.GET_LOGGING_LIST:
      return {
        ...state,
        loggings: action.loggings,
        loggingsCount: action.loggingsCount,
      };
    case loggingActions.DELETE_LOGGING:
      return {
        ...state,
        loggings: action.loggings,
      };
    case loggingActions.LOGGING_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        logging: action.logging === null ? initState.logging : action.logging,
      };
    case loggingActions.CHANGE_LOGGING_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case loggingActions.CHANGE_LOGGING_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}

