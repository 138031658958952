const guideActions = {
    CREATE_GUIDE: 'CREATE_GUIDE',
    UPDATE_GUIDE: 'UPDATE_GUIDE',
    GET_GUIDE: 'GET_GUIDE',
    GET_GUIDE_LIST: 'GET_GUIDE_LIST',
    EDIT_GUIDE: 'EDIT_GUIDE',
    DELETE_GUIDE: 'DELETE_GUIDE',
    GUIDE_TOGGLE_HANDLE_MODAL: 'GUIDE_TOGGLE_HANDLE_MODAL',
    GUIDE_TOGGLE2_HANDLE_MODAL: 'GUIDE_TOGGLE2_HANDLE_MODAL',
    CHANGE_GUIDE_PAGINATION: 'CHANGE_GUIDE_PAGINATION',
    CHANGE_GUIDE_COUNT: 'CHANGE_GUIDE_COUNT',
    createGuide: guide => {
        return (dispatch, getState) => {
            dispatch({
                type: guideActions.CREATE_GUIDE,
                guides: [guide, ...getState().guide.guides],
            });
        };
    },
    updateGuide: guide => {
        return (dispatch, getState) => {
            dispatch({
                type: guideActions.UPDATE_GUIDE,
                guide,
            });
        };
    },
    editGuide: guide => {
        return (dispatch, getState) => {
            dispatch({
                type: guideActions.EDIT_GUIDE,
                guide,
            });
        };
    },
    getGuides: (guides, guidesCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: guideActions.GET_GUIDE_LIST,
                guides,
                guidesCount
            });
        };
    },
    deleteGuide: id => {
        return (dispatch, getState) => {
            var currentGuides = getState().guide.guides.filter(s => s.id !== id);
            dispatch({
                type: guideActions.DELETE_GUIDE,
                guides: currentGuides,
            });
        };
    },
    toggleModal: (guide = null) => ({
        type: guideActions.GUIDE_TOGGLE_HANDLE_MODAL,
        guide,
    }),
    toggleModal2: (guide = null) => ({
        type: guideActions.GUIDE_TOGGLE2_HANDLE_MODAL,
        guide,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: guideActions.CHANGE_GUIDE_PAGINATION,
                pagination,
            });
        };
    },
    updateGuidesCount: guidesCount => {
        return (dispatch, getState) => {
            dispatch({
                type: guideActions.CHANGE_GUIDE_COUNT,
                guidesCount,
            });
        };
    }

};
export default guideActions;