import erpUserSettingsActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  users: [],
  warehouses: [],
  customers: [],
  usersCount: 0,
  pagination: {
    currentPage: 1,
    descending: false,
    skip: 0,
    take: 10,
    search: '',
    sort: 'name'
  },
  user: {}
};

export default function erpUserSettingsReducer(state = initState, action) {
  switch (action.type) {
    case erpUserSettingsActions.ERP_USER_SETTINGS_SET_USER_SETTINGS:
      let userIndex = state.users.findIndex(u => u.id === action.user.id);
      let users = state.users;
      users[userIndex] = action.user;
      return {
        ...state,
        users
      };
    case erpUserSettingsActions.ERP_USER_SETTINGS_GET_WAREHOUSE_LIST:
      return {
        ...state,
        warehouses: action.warehouses
      }
    case erpUserSettingsActions.ERP_USER_SETTINGS_EDIT_USER:
      return {
        ...state,
        user: action.user,
      };
    case erpUserSettingsActions.ERP_USER_SETTINGS_GET_CUSTOMER_LIST:
      return {
        ...state,
        customers: action.customers,
      };
    case erpUserSettingsActions.ERP_USER_SETTINGS_GET_USER_LIST:
      return {
        ...state,
        users: action.users,
        usersCount: action.usersCount,
      };
    case erpUserSettingsActions.ERP_USER_SETTINGS_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        user: action.user === null ? initState.user : action.user,
      };
    case erpUserSettingsActions.ERP_USER_SETTINGS_CHANGE_USER_PAGINATION:
      return {
        ...state,
        pagination: {...action.pagination},
      };
    case erpUserSettingsActions.ERP_USER_SETTINGS_CHANGE_USER_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
