import alesta_CityActions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  alesta_Citys: [],
  alesta_CitysCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: "",
    sort: "id",
    descending: true,
  },
  alesta_City: {},
};

export default function alesta_CityReducer(state = initState, action) {
  switch (action.type) {
    case alesta_CityActions.CREATE_ALESTA_CITY:
      initState.alesta_City = {};
      return {
        ...state,
        alesta_Citys: action.alesta_Citys,
        alesta_City: initState.alesta_City,
      };
    case alesta_CityActions.UPDATE_ALESTA_CITY:
      var alesta_CityIndex = state.alesta_Citys.findIndex((u) => u.id === action.alesta_City.id);
      var alesta_Citys = state.alesta_Citys;
      alesta_Citys[alesta_CityIndex] = action.alesta_City;
      return {
        ...state,
        alesta_Citys: [...alesta_Citys],
      };
    case alesta_CityActions.EDIT_ALESTA_CITY:
      return {
        ...state,
        alesta_City: action.alesta_City,
      };
    case alesta_CityActions.GET_ALESTA_CITY_LIST:
      return {
        ...state,
        alesta_Citys: action.alesta_Citys,
        alesta_CitysCount: action.alesta_CitysCount,
      };
    case alesta_CityActions.DELETE_ALESTA_CITY:
      return {
        ...state,
        alesta_Citys: action.alesta_Citys,
      };
    case alesta_CityActions.ALESTA_CITY_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        alesta_City: action.alesta_City === null ? initState.alesta_City : action.alesta_City,
      };
    case alesta_CityActions.CHANGE_ALESTA_CITY_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case alesta_CityActions.CHANGE_ALESTA_CITY_COUNT:
      return {
        ...state,
        alesta_CitysCount: action.alesta_CitysCount,
      };
    default:
      return state;
  }
}

