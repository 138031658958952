const erpClientSettingsActions = {
  ERP_CLIENT_SETTINGS_SET_CLIENT_SETTINGS: 'ERP_CLIENT_SETTINGS_SET_CLIENT_SETTINGS',
  ERP_CLIENT_SETTINGS_EDIT_CLIENT: 'ERP_CLIENT_SETTINGS_EDIT_CLIENT',
  ERP_CLIENT_SETTINGS_GET_CLIENT_LIST: 'ERP_CLIENT_SETTINGS_GET_CLIENT_LIST',
  ERP_CLIENT_SETTINGS_TOGGLE_HANDLE_MODAL: 'ERP_CLIENT_SETTINGS_TOGGLE_HANDLE_MODAL',
  ERP_CLIENT_SETTINGS_CHANGE_CLIENT_PAGINATION: 'ERP_CLIENT_SETTINGS_CHANGE_CLIENT_PAGINATION',
  ERP_CLIENT_SETTINGS_CHANGE_CLIENT_COUNT: 'ERP_CLIENT_SETTINGS_CHANGE_CLIENT_COUNT',
  setClientSettings: client => {
    return (dispatch, getState) => {
      dispatch({
        type: erpClientSettingsActions.ERP_CLIENT_SETTINGS_SET_CLIENT_SETTINGS,
        client,
      });
    };
  },
  editClient: client => {
    return (dispatch, getState) => {
      dispatch({
        type: erpClientSettingsActions.ERP_CLIENT_SETTINGS_EDIT_CLIENT,
        client,
      });
    };
  },
  getClients: (clients, clientsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: erpClientSettingsActions.ERP_CLIENT_SETTINGS_GET_CLIENT_LIST,
        clients,
        clientsCount
      });
    };
  },
  toggleModal: (client = null) => ({
    type: erpClientSettingsActions.ERP_CLIENT_SETTINGS_TOGGLE_HANDLE_MODAL,
    client,
  }),
  changePagination: pagination => {
    return (dispatch, getState) => {
      dispatch({
        type: erpClientSettingsActions.ERP_CLIENT_SETTINGS_CHANGE_CLIENT_PAGINATION,
        pagination,
      });
    };
  },
};
export default erpClientSettingsActions;