import unitActions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  units: [],
  unitsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id',
    desc: true,
  },
  unit: {
  }
};

export default function unitReducer(state = initState, action) {
  switch (action.type) {
    case unitActions.CREATE_UNIT:
      initState.unit = {}
      return {
        ...state,
        units: action.units,
        unit: initState.unit
      };
    case unitActions.UPDATE_UNIT:
      var unitIndex = state.units.findIndex(u => u.id === action.unit.id);
      var units = state.units;
      units[unitIndex] = action.unit;
      return {
        ...state,
        units: [...units]
      };
    case unitActions.EDIT_UNIT:
      return {
        ...state,
        unit: action.unit,
      };
    case unitActions.GET_UNIT_LIST:
      return {
        ...state,
        units: action.units,
        unitsCount: action.unitsCount,
      };
    case unitActions.DELETE_UNIT:
      return {
        ...state,
        units: action.units,
      };
    case unitActions.UNIT_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        unit: action.unit === null ? initState.unit : action.unit,
      };
    case unitActions.CHANGE_UNIT_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case unitActions.CHANGE_UNIT_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
