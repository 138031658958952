const unitActions = {
    CREATE_UNIT: 'CREATE_UNIT',
    UPDATE_UNIT: 'UPDATE_UNIT',
    GET_UNIT: 'GET_UNIT',
    GET_UNIT_LIST: 'GET_UNIT_LIST',
    EDIT_UNIT: 'EDIT_UNIT',
    DELETE_UNIT: 'DELETE_UNIT',
    UNIT_TOGGLE_HANDLE_MODAL: 'UNIT_TOGGLE_HANDLE_MODAL',
    CHANGE_UNIT_PAGINATION: 'CHANGE_UNIT_PAGINATION',
    CHANGE_UNIT_COUNT: 'CHANGE_UNIT_COUNT',
    createUnit: unit => {
      return (dispatch, getState) => {
        dispatch({
          type: unitActions.CREATE_UNIT,
          units: [unit, ...getState().unit.units],
        });
      };
    },
    updateUnit: unit => {
      return (dispatch, getState) => {
        dispatch({
          type: unitActions.UPDATE_UNIT,
          unit,
        });
      };
    },
    editUnit: unit => {
      return (dispatch, getState) => {
        dispatch({
          type: unitActions.EDIT_UNIT,
          unit,
        });
      };
    },
    getUnits: (units, unitsCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: unitActions.GET_UNIT_LIST,
          units,
          unitsCount
        });
      };
    },
    deleteUnit: id => {
      return (dispatch, getState) => {
        var currentUnits = getState().unit.units.filter(s => s.id !== id);
        dispatch({
          type: unitActions.DELETE_UNIT,
          units: currentUnits,
        });
      };
    },
    toggleModal: (unit = null) => ({
      type: unitActions.UNIT_TOGGLE_HANDLE_MODAL,
      unit,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: unitActions.CHANGE_UNIT_PAGINATION,
          pagination,
        });
      };
    },
    updateUnitsCount: unitsCount => {
      return (dispatch, getState) => {
        dispatch({
          type: unitActions.CHANGE_UNIT_COUNT,
          unitsCount,
        });
      };
    }
  
  };
  export default unitActions;