import countryActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  countries: [],
  countriesCount: 0,
  pagination: {
    currentPage: 1,
    descending: false,
    skip: 0,
    take: 10,
    search: '',
    sort: 'name'
  },
  country: {
  }
};

export default function countryReducer(state = initState, action) {
  switch (action.type) {
    case countryActions.CREATE_COUNTRY:
      initState.country = {}
      return {
        ...state,
        countries: action.countries,
        country: initState.country
      };
    case countryActions.UPDATE_COUNTRY:
      let countries = state.countries.map(x => {
        if(x.id === action.country.id){
          return {
            ...x,
            ...action.country
          }

        }else {
          return x
        }
      });
      return {
        ...state,
        countries
      };
    case countryActions.EDIT_COUNTRY:
      return {
        ...state,
        country: action.country,
      };
    case countryActions.GET_COUNTRY_LIST:
      return {
        ...state,
        countries: action.countries,
        countriesCount: action.countriesCount,
      };
    case countryActions.DELETE_COUNTRY:
      return {
        ...state,
        countries: action.countries,
      };
    case countryActions.COUNTRY_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        country: action.country === null ? initState.country : action.country,
      };
    case countryActions.CHANGE_COUNTRY_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case countryActions.CHANGE_COUNTRY_COUNT:
      return {
        ...state,
        countriesCount: action.countriesCount,
      };
    default:
      return state;
  }
}
