const purchaseActions = {
  PURCHASES_GET_SEARCH_PRODUCT_LIST: 'PURCHASES_GET_SEARCH_PRODUCT_LIST',
  PURCHASES_GET_WAREHOUSE_LIST: 'PURCHASES_GET_WAREHOUSE_LIST',
  PURCHASES_GET_CUSTOMER_LIST: 'PURCHASES_GET_CUSTOMER_LIST',
  PURCHASES_GET_MODAL_WAREHOUSE_PRODUCT_LIST: 'PURCHASES_GET_MODAL_WAREHOUSE_PRODUCT_LIST',
  PURCHASES_GET_PURCHASES_PRODUCT_LIST: 'PURCHASES_GET_PURCHASES_PRODUCT_LIST',
  PURCHASES_RESET_FIELDS: 'PURCHASES_RESET_FIELDS',
  PURCHASES_ADD_PURCHASES_PRODUCT: 'PURCHASES_ADD_PURCHASES_PRODUCT',
  PURCHASES_REMOVE_PURCHASES_PRODUCT: 'PURCHASES_REMOVE_PURCHASES_PRODUCT',
  PURCHASES_UPDATE_PURCHASES_PRODUCT: 'PURCHASES_UPDATE_PURCHASES_PRODUCT',
  PURCHASES_UPDATE_PURCHASES_TOTAL_PRICE: 'PURCHASES_UPDATE_PURCHASES_TOTAL_PRICE',
  PURCHASES_UPDATE_OLD_PURCHASES_TOTAL_PRICE: 'PURCHASES_UPDATE_OLD_PURCHASES_TOTAL_PRICE',
  PURCHASES_TOGGLE_STOCK_MODAL: "PURCHASES_TOGGLE_STOCK_MODAL",
  getSearchProducts: (searchProducts) => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_GET_SEARCH_PRODUCT_LIST,
        searchProducts
      });
    };
  },
  getPurchasesProducts: (purchasesProducts) => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_GET_PURCHASES_PRODUCT_LIST,
        purchasesProducts
      });
    };
  },
  getWarehouses: (warehouses) => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_GET_WAREHOUSE_LIST,
        warehouses
      });
    };
  },
  getCustomers: (customers) => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_GET_CUSTOMER_LIST,
        customers
      });
    };
  },
  resetFields: () => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_RESET_FIELDS
      });
    };
  },
  getModalWarehouseProducts: (warehouseProducts) => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_GET_MODAL_WAREHOUSE_PRODUCT_LIST,
        warehouseProducts
      });
    };
  },
  addPurchaseProduct: (product) => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_ADD_PURCHASES_PRODUCT,
        product
      })
    }
  },
  updatePurchaseProduct: (product) => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_UPDATE_PURCHASES_PRODUCT,
        product
      })
    }
  },
  removePurchaseProduct: (product) => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_REMOVE_PURCHASES_PRODUCT,
        product
      })
    }
  },
  updateTotalPurchasesPrice: (totalPurchasesPrice) => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_UPDATE_PURCHASES_TOTAL_PRICE,
        totalPurchasesPrice
      })
    }
  },
  updateOldTotalPurchasesPrice: (oldTotalPurchasesPrice) => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_UPDATE_OLD_PURCHASES_TOTAL_PRICE,
        oldTotalPurchasesPrice
      })
    }
  },
  toggleModal: () => {
    return (dispatch, getState) => {
      dispatch({
        type: purchaseActions.PURCHASES_TOGGLE_STOCK_MODAL
      })
    }
  }
};
export default purchaseActions;