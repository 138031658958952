const roleGroupActions = {
    CREATE_ROLEGROUP: 'CREATE_ROLEGROUP',
    UPDATE_ROLEGROUP: 'UPDATE_ROLEGROUP',
    GET_ROLEGROUP: 'GET_ROLEGROUP',
    GET_ROLEGROUP_LIST: 'GET_ROLEGROUP_LIST',
    EDIT_ROLEGROUP: 'EDIT_ROLEGROUP',
    DELETE_ROLEGROUP: 'DELETE_ROLEGROUP',
    ROLEGROUP_TOGGLE_HANDLE_MODAL: 'ROLEGROUP_TOGGLE_HANDLE_MODAL',
    CHANGE_ROLEGROUP_PAGINATION: 'CHANGE_ROLEGROUP_PAGINATION',
    CHANGE_ROLEGROUP_COUNT: 'CHANGE_ROLEGROUP_COUNT',
    createRoleGroup: roleGroup => {
      return (dispatch, getState) => {
        dispatch({
          type: roleGroupActions.CREATE_ROLEGROUP,
          roleGroups: [roleGroup, ...getState().roleGroup.roleGroups],
        });
      };
    },
    updateRoleGroup: roleGroup => {
      return (dispatch, getState) => {
        dispatch({
          type: roleGroupActions.UPDATE_ROLEGROUP,
          roleGroup,
        });
      };
    },
    editRoleGroup: roleGroup => {
      return (dispatch, getState) => {
        dispatch({
          type: roleGroupActions.EDIT_ROLEGROUP,
          roleGroup,
        });
      };
    },
    getRoleGroups: (roleGroups, roleGroupsCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: roleGroupActions.GET_ROLEGROUP_LIST,
          roleGroups,
          roleGroupsCount
        });
      };
    },
    deleteRoleGroup: id => {
      return (dispatch, getState) => {
        var currentRoleGroups = getState().roleGroup.roleGroups.filter(s => s.id !== id);
        dispatch({
          type: roleGroupActions.DELETE_ROLEGROUP,
          roleGroups: currentRoleGroups,
        });
      };
    },
    toggleModal: (roleGroup = null) => ({
      type: roleGroupActions.ROLEGROUP_TOGGLE_HANDLE_MODAL,
      roleGroup,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: roleGroupActions.CHANGE_ROLEGROUP_PAGINATION,
          pagination,
        });
      };
    },
    updateRoleGroupsCount: roleGroupsCount => {
      return (dispatch, getState) => {
        dispatch({
          type: roleGroupActions.CHANGE_ROLEGROUP_COUNT,
          roleGroupsCount,
        });
      };
    }
  
  };
  export default roleGroupActions;