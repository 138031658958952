const evidenceActions = {
    CREATE_EVIDENCE: 'CREATE_EVIDENCE',
    UPDATE_EVIDENCE: 'UPDATE_EVIDENCE',
    GET_EVIDENCE: 'GET_EVIDENCE',
    GET_EVIDENCE_LIST: 'GET_EVIDENCE_LIST',
    EDIT_EVIDENCE: 'EDIT_EVIDENCE',
    DELETE_EVIDENCE: 'DELETE_EVIDENCE',
    EVIDENCE_TOGGLE_HANDLE_MODAL: 'EVIDENCE_TOGGLE_HANDLE_MODAL',
    CHANGE_EVIDENCE_PAGINATION: 'CHANGE_EVIDENCE_PAGINATION',
    CHANGE_EVIDENCE_COUNT: 'CHANGE_EVIDENCE_COUNT',
    createEvidence: evidence => {
      return (dispatch, getState) => {
        dispatch({
          type: evidenceActions.CREATE_EVIDENCE,
          evidences: [evidence, ...getState().evidence.evidences],
        });
      };
    },
    updateEvidence: evidence => {
      return (dispatch, getState) => {
        dispatch({
          type: evidenceActions.UPDATE_EVIDENCE,
          evidence,
        });
      };
    },
    editEvidence: evidence => {
      return (dispatch, getState) => {
        dispatch({
          type: evidenceActions.EDIT_EVIDENCE,
          evidence,
        });
      };
    },
    getEvidences: (evidences, evidencesCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: evidenceActions.GET_EVIDENCE_LIST,
          evidences,
          evidencesCount
        });
      };
    },
    deleteEvidence: id => {
      return (dispatch, getState) => {
        var currentEvidences = getState().evidence.evidences.filter(s => s.id !== id);
        dispatch({
          type: evidenceActions.DELETE_EVIDENCE,
          evidences: currentEvidences,
        });
      };
    },
    toggleModal: (evidence = null) => ({
      type: evidenceActions.EVIDENCE_TOGGLE_HANDLE_MODAL,
      evidence,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: evidenceActions.CHANGE_EVIDENCE_PAGINATION,
          pagination,
        });
      };
    },
    updateEvidencesCount: evidencesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: evidenceActions.CHANGE_EVIDENCE_COUNT,
          evidencesCount,
        });
      };
    }
  
  };
  export default evidenceActions;