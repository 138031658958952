const feedbackActions = {
    CREATE_FEEDBACK: 'CREATE_FEEDBACK',
    UPDATE_FEEDBACK: 'UPDATE_FEEDBACK',
    GET_FEEDBACK: 'GET_FEEDBACK',
    GET_FEEDBACK_LIST: 'GET_FEEDBACK_LIST',
    EDIT_FEEDBACK: 'EDIT_FEEDBACK',
    DELETE_FEEDBACK: 'DELETE_FEEDBACK',
    FEEDBACK_TOGGLE_HANDLE_MODAL: 'FEEDBACK_TOGGLE_HANDLE_MODAL',
    FEEDBACK_TOGGLE2_HANDLE_MODAL: 'FEEDBACK_TOGGLE2_HANDLE_MODAL',
    CHANGE_FEEDBACK_PAGINATION: 'CHANGE_FEEDBACK_PAGINATION',
    CHANGE_FEEDBACK_COUNT: 'CHANGE_FEEDBACK_COUNT',
    createFeedBack: feedback => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackActions.CREATE_FEEDBACK,
                feedbacks: [feedback, ...getState().feedback.feedbacks],
            });
        };
    },
    updateFeedBack: feedback => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackActions.UPDATE_FEEDBACK,
                feedback,
            });
        };
    },
    editFeedBack: feedback => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackActions.EDIT_FEEDBACK,
                feedback,
            });
        };
    },
    getFeedBacks: (feedbacks, feedbacksCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackActions.GET_FEEDBACK_LIST,
                feedbacks,
                feedbacksCount
            });
        };
    },
    deleteFeedBack: id => {
        return (dispatch, getState) => {
            var currentFeedBacks = getState().feedback.feedbacks.filter(s => s.id !== id);
            dispatch({
                type: feedbackActions.DELETE_FEEDBACK,
                feedbacks: currentFeedBacks,
            });
        };
    },
    toggleModal: (feedback = null) => ({
        type: feedbackActions.FEEDBACK_TOGGLE_HANDLE_MODAL,
        feedback,
    }),
    toggleModal2: (feedback = null) => ({
        type: feedbackActions.FEEDBACK_TOGGLE2_HANDLE_MODAL,
        feedback,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackActions.CHANGE_FEEDBACK_PAGINATION,
                pagination,
            });
        };
    },
    updateFeedBacksCount: feedbacksCount => {
        return (dispatch, getState) => {
            dispatch({
                type: feedbackActions.CHANGE_FEEDBACK_COUNT,
                feedbacksCount,
            });
        };
    }

};
export default feedbackActions;