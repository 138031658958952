const placeFBActions = {
  CREATE_PLACEFB: "CREATE_PLACEFB",
  UPDATE_PLACEFB: "UPDATE_PLACEFB",
  GET_PLACEFB: "GET_PLACEFB",
  GET_PLACEFB_LIST: "GET_PLACEFB_LIST",
  EDIT_PLACEFB: "EDIT_PLACEFB",
  DELETE_PLACEFB: "DELETE_PLACEFB",
  PLACEFB_TOGGLE_HANDLE_MODAL: "PLACEFB_TOGGLE_HANDLE_MODAL",
  CHANGE_PLACEFB_PAGINATION: "CHANGE_PLACEFB_PAGINATION",
  CHANGE_PLACEFB_COUNT: "CHANGE_PLACEFB_COUNT",
  createPlaceFB: (placeFB) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeFBActions.CREATE_PLACEFB,
        placeFBs: [placeFB, ...getState().placeFB.placeFBs],
      });
    };
  },
  updatePlaceFB: (placeFB) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeFBActions.UPDATE_PLACEFB,
        placeFB,
      });
    };
  },
  editPlaceFB: (placeFB) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeFBActions.EDIT_PLACEFB,
        placeFB,
      });
    };
  },
  getPlaceFBs: (placeFBs, placeFBsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeFBActions.GET_PLACEFB_LIST,
        placeFBs,
        placeFBsCount,
      });
    };
  },
  deletePlaceFB: (id) => {
    return (dispatch, getState) => {
      var currentPlaceFBs = getState().placeFB.placeFBs.filter((s) => s.id !== id);
      dispatch({
        type: placeFBActions.DELETE_PLACEFB,
        placeFBs: currentPlaceFBs,
      });
    };
  },
  toggleModal: (placeFB = null) => ({
    type: placeFBActions.PLACEFB_TOGGLE_HANDLE_MODAL,
    placeFB,
  }),
  changePagination: (pagination) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeFBActions.CHANGE_PLACEFB_PAGINATION,
        pagination,
      });
    };
  },
  updatePlaceFBsCount: (placeFBsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: placeFBActions.CHANGE_PLACEFB_COUNT,
        placeFBsCount,
      });
    };
  },
};
export default placeFBActions;

