import tagActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  tags: [],
  tagsCount: 0,
  pagination: {
    currentPage: 1,
    descending: false,
    skip: 0,
    take: 10,
    search: '',
    sort: 'name'
  },
  tag: {
  }
};

export default function tagReducer(state = initState, action) {
  switch (action.type) {
    case tagActions.CREATE_TAG:
      initState.tag = {}
      return {
        ...state,
        tags: action.tags,
        tag: initState.tag
      };
    case tagActions.UPDATE_TAG:
      let tagIndex = state.tags.findIndex(u => u.id === action.tag.id);
      let tags = state.tags;
      tags[tagIndex] = action.tag;
      return {
        ...state,
        tags
      };
    case tagActions.EDIT_TAG:
      return {
        ...state,
        tag: action.tag,
      };
    case tagActions.GET_TAG_LIST:
      return {
        ...state,
        tags: action.tags,
        tagsCount: action.tagsCount,
      };
    case tagActions.DELETE_TAG:
      return {
        ...state,
        tags: action.tags,
      };
    case tagActions.TAG_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        tag: action.tag === null ? initState.tag : action.tag,
      };
    case tagActions.CHANGE_TAG_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case tagActions.CHANGE_TAG_COUNT:
      return {
        ...state,
        tagsCount: action.tagsCount,
      };
    default:
      return state;
  }
}
