import React, { useState } from "react";
import NotificationContent from "../../containers/Feedback/Notification/Notification.styles";
import notifications from "@iso/components/Feedback/Notification";

export default function CustomNotification(type, key, description, message, onClose) {
  notifications[type]({
    key: key,
    message,
    description: description && <NotificationContent>{description}</NotificationContent>,
    onClose,
  });
}

