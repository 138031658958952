const announcementActions = {
    CREATE_ANNOUNCEMENT: 'CREATE_ANNOUNCEMENT',
    UPDATE_ANNOUNCEMENT: 'UPDATE_ANNOUNCEMENT',
    GET_ANNOUNCEMENT: 'GET_ANNOUNCEMENT',
    GET_ANNOUNCEMENT_LIST: 'GET_ANNOUNCEMENT_LIST',
    EDIT_ANNOUNCEMENT: 'EDIT_ANNOUNCEMENT',
    DELETE_ANNOUNCEMENT: 'DELETE_ANNOUNCEMENT',
    ANNOUNCEMENT_TOGGLE_HANDLE_MODAL: 'ANNOUNCEMENT_TOGGLE_HANDLE_MODAL',
    CHANGE_ANNOUNCEMENT_PAGINATION: 'CHANGE_ANNOUNCEMENT_PAGINATION',
    CHANGE_ANNOUNCEMENT_COUNT: 'CHANGE_ANNOUNCEMENT_COUNT',
    createAnnouncement: announcement => {
      return (dispatch, getState) => {
        dispatch({
          type: announcementActions.CREATE_ANNOUNCEMENT,
          announcements: [announcement, ...getState().announcement.announcements],
        });
      };
    },
    updateAnnouncement: announcement => {
      return (dispatch, getState) => {
        dispatch({
          type: announcementActions.UPDATE_ANNOUNCEMENT,
          announcement,
        });
      };
    },
    editAnnouncement: announcement => {
      return (dispatch, getState) => {
        dispatch({
          type: announcementActions.EDIT_ANNOUNCEMENT,
          announcement,
        });
      };
    },
    getAnnouncements: (announcements, announcementsCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: announcementActions.GET_ANNOUNCEMENT_LIST,
          announcements,
          announcementsCount
        });
      };
    },
    deleteAnnouncement: id => {
      return (dispatch, getState) => {
        var currentAnnouncements = getState().announcement.announcements.filter(s => s.id !== id);
        dispatch({
          type: announcementActions.DELETE_ANNOUNCEMENT,
          announcements: currentAnnouncements,
        });
      };
    },
    toggleModal: (announcement = null) => ({
      type: announcementActions.ANNOUNCEMENT_TOGGLE_HANDLE_MODAL,
      announcement,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: announcementActions.CHANGE_ANNOUNCEMENT_PAGINATION,
          pagination,
        });
      };
    },
    updateAnnouncementsCount: announcementsCount => {
      return (dispatch, getState) => {
        dispatch({
          type: announcementActions.CHANGE_ANNOUNCEMENT_COUNT,
          announcementsCount,
        });
      };
    }
  
  };
  export default announcementActions;