import contractActions from './actions';

const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    contracts: [],
    contractsCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: ''
    },
    contract: {
    }
};

export default function contractReducer(state = initState, action) {
    switch (action.type) {
        case contractActions.CREATE_CONTRACT:
            initState.contract = {}
            return {
                ...state,
                contracts: action.contracts,
                contract: initState.contract
            };
        case contractActions.UPDATE_CONTRACT:
            var contractIndex = state.contracts.findIndex(u => u.id === action.contract.id);
            var contracts = state.contracts;
            contracts[contractIndex] = action.contract;
            return {
                ...state,
                contracts: [...contracts]
            };
        case contractActions.EDIT_CONTRACT:
            return {
                ...state,
                contract: action.contract,
            };
        case contractActions.GET_CONTRACT_LIST:
            return {
                ...state,
                contracts: action.contracts,
                contractsCount: action.contractsCount,
            };
        case contractActions.DELETE_CONTRACT:
            return {
                ...state,
                contracts: action.contracts,
            };
        case contractActions.CONTRACT_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                contract: action.contract === null ? initState.contract : action.contract,
            };
        case contractActions.CHANGE_CONTRACT_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case contractActions.CHANGE_CONTRACT_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}
