const userActions = {
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER',
    GET_USER: 'GET_USER',
    GET_USER_LIST: 'GET_USER_LIST',
    EDIT_USER: 'EDIT_USER',
    DELETE_USER: 'DELETE_USER',
    USER_TOGGLE_HANDLE_MODAL: 'USER_TOGGLE_HANDLE_MODAL',
    CHANGE_USER_PAGINATION: 'CHANGE_USER_PAGINATION',
    CHANGE_USER_COUNT: 'CHANGE_USER_COUNT',
    createUser: user => {
      return (dispatch, getState) => {
        dispatch({
          type: userActions.CREATE_USER,
          users: [user, ...getState().user.users],
        });
      };
    },
    updateUser: user => {
      return (dispatch, getState) => {
        dispatch({
          type: userActions.UPDATE_USER,
          user,
        });
      };
    },
    editUser: user => {
      return (dispatch, getState) => {
        dispatch({
          type: userActions.EDIT_USER,
          user,
        });
      };
    },
    getUsers: (users, usersCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: userActions.GET_USER_LIST,
          users,
          usersCount
        });
      };
    },
    deleteUser: id => {
      return (dispatch, getState) => {
        var currentUsers = getState().user.users.filter(s => s.id !== id);
        dispatch({
          type: userActions.DELETE_USER,
          users: currentUsers,
        });
      };
    },
    toggleModal: (user = null) => ({
      type: userActions.USER_TOGGLE_HANDLE_MODAL,
      user,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: userActions.CHANGE_USER_PAGINATION,
          pagination,
        });
      };
    },
    updateUsersCount: usersCount => {
      return (dispatch, getState) => {
        dispatch({
          type: userActions.CHANGE_USER_COUNT,
          usersCount,
        });
      };
    }
  
  };
  export default userActions;