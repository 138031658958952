const alesta_CategoryActions = {
    CREATE_ALESTA_CATEGORY: 'CREATE_ALESTA_CATEGORY',
    UPDATE_ALESTA_CATEGORY: 'UPDATE_ALESTA_CATEGORY',
    GET_ALESTA_CATEGORY: 'GET_ALESTA_CATEGORY',
    GET_ALESTA_CATEGORY_LIST: 'GET_ALESTA_CATEGORY_LIST',
    EDIT_ALESTA_CATEGORY: 'EDIT_ALESTA_CATEGORY',
    DELETE_ALESTA_CATEGORY: 'DELETE_ALESTA_CATEGORY',
    ALESTA_CATEGORY_TOGGLE_HANDLE_MODAL: 'ALESTA_CATEGORY_TOGGLE_HANDLE_MODAL',
    CHANGE_ALESTA_CATEGORY_PAGINATION: 'CHANGE_ALESTA_CATEGORY_PAGINATION',
    CHANGE_ALESTA_CATEGORY_COUNT: 'CHANGE_ALESTA_CATEGORY_COUNT',
    createAlesta_Category: alesta_Category => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CategoryActions.CREATE_ALESTA_CATEGORY,
          alesta_Categorys: [alesta_Category, ...getState().alesta_Category.alesta_Categorys],
        });
      };
    },
    updateAlesta_Category: alesta_Category => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CategoryActions.UPDATE_ALESTA_CATEGORY,
          alesta_Category,
        });
      };
    },
    editAlesta_Category: alesta_Category => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CategoryActions.EDIT_ALESTA_CATEGORY,
          alesta_Category,
        });
      };
    },
    getAlesta_Categorys: (alesta_Categorys, alesta_CategorysCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CategoryActions.GET_ALESTA_CATEGORY_LIST,
          alesta_Categorys,
          alesta_CategorysCount
        });
      };
    },
    deleteAlesta_Category: id => {
      return (dispatch, getState) => {
        var currentAlesta_Categorys = getState().alesta_Category.alesta_Categorys.filter(s => s.id !== id);
        dispatch({
          type: alesta_CategoryActions.DELETE_ALESTA_CATEGORY,
          alesta_Categorys: currentAlesta_Categorys,
        });
      };
    },
    toggleModal: (alesta_Category = null) => ({
      type: alesta_CategoryActions.ALESTA_CATEGORY_TOGGLE_HANDLE_MODAL,
      alesta_Category,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CategoryActions.CHANGE_ALESTA_CATEGORY_PAGINATION,
          pagination,
        });
      };
    },
    updateAlesta_CategorysCount: alesta_CategorysCount => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_CategoryActions.CHANGE_ALESTA_CATEGORY_COUNT,
          alesta_CategorysCount,
        });
      };
    }
  
  };
  export default alesta_CategoryActions;