const alesta_CityActions = {
  CREATE_ALESTA_CITY: "CREATE_ALESTA_CITY",
  UPDATE_ALESTA_CITY: "UPDATE_ALESTA_CITY",
  GET_ALESTA_CITY: "GET_ALESTA_CITY",
  GET_ALESTA_CITY_LIST: "GET_ALESTA_CITY_LIST",
  EDIT_ALESTA_CITY: "EDIT_ALESTA_CITY",
  DELETE_ALESTA_CITY: "DELETE_ALESTA_CITY",
  ALESTA_CITY_TOGGLE_HANDLE_MODAL: "ALESTA_CITY_TOGGLE_HANDLE_MODAL",
  CHANGE_ALESTA_CITY_PAGINATION: "CHANGE_ALESTA_CITY_PAGINATION",
  CHANGE_ALESTA_CITY_COUNT: "CHANGE_ALESTA_CITY_COUNT",
  createAlesta_City: (alesta_City) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_CityActions.CREATE_ALESTA_CITY,
        alesta_Citys: [alesta_City, ...getState().alesta_City.alesta_Citys],
      });
    };
  },
  updateAlesta_City: (alesta_City) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_CityActions.UPDATE_ALESTA_CITY,
        alesta_City,
      });
    };
  },
  editAlesta_City: (alesta_City) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_CityActions.EDIT_ALESTA_CITY,
        alesta_City,
      });
    };
  },
  getAlesta_Citys: (alesta_Citys, alesta_CitysCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_CityActions.GET_ALESTA_CITY_LIST,
        alesta_Citys,
        alesta_CitysCount,
      });
    };
  },
  deleteAlesta_City: (id) => {
    return (dispatch, getState) => {
      var currentAlesta_Citys = getState().alesta_City.alesta_Citys.filter((s) => s.id !== id);
      dispatch({
        type: alesta_CityActions.DELETE_ALESTA_CITY,
        alesta_Citys: currentAlesta_Citys,
      });
    };
  },
  toggleModal: (alesta_City = null) => ({
    type: alesta_CityActions.ALESTA_CITY_TOGGLE_HANDLE_MODAL,
    alesta_City,
  }),
  changePagination: (pagination) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_CityActions.CHANGE_ALESTA_CITY_PAGINATION,
        pagination,
      });
    };
  },
  updateAlesta_CitysCount: (alesta_CitysCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_CityActions.CHANGE_ALESTA_CITY_COUNT,
        alesta_CitysCount,
      });
    };
  },
};
export default alesta_CityActions;
