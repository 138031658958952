import productimageActions from './actions';
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    productimages: [],
    productimagesCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    productimage: {
    }
};

export default function productimageReducer(state = initState, action) {
    switch (action.type) {
        case productimageActions.CREATE_PRODUCTIMAGE:
            initState.productimage = {}
            return {
                ...state,
                productimages: action.productimages,
                productimage: initState.productimage
            };
        case productimageActions.UPDATE_PRODUCTIMAGE:
            var productimageIndex = state.productimages.findIndex(u => u.id === action.productimage.id);
            var productimages = state.productimages;
            productimages[productimageIndex] = action.productimage;
            return {
                ...state,
                productimages: [...productimages]
            };
        case productimageActions.EDIT_PRODUCTIMAGE:
            return {
                ...state,
                productimage: action.productimage,
            };
        case productimageActions.GET_PRODUCTIMAGE_LIST:
            return {
                ...state,
                productimages: action.productimages,
                productimagesCount: action.productimagesCount,
            };
        case productimageActions.DELETE_PRODUCTIMAGE:
            return {
                ...state,
                productimages: action.productimages,
            };
        case productimageActions.PRODUCTIMAGE_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                productimage: action.productimage === null ? initState.productimage : action.productimage,
            };
        case productimageActions.PRODUCTIMAGE_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                productimage: action.productimage === null ? initState.productimage : action.productimage,

            };
        case productimageActions.CHANGE_PRODUCTIMAGE_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case productimageActions.CHANGE_PRODUCTIMAGE_COUNT:
            return {
                ...state,
                productimagesCount: action.productimagesCount,
            };
        default:
            return state;
    }
}