const orderDetailActions = {
    CREATE_ORDER_DETAIL: 'CREATE_ORDER_DETAIL',
    UPDATE_ORDER_DETAIL: 'UPDATE_ORDER_DETAIL',
    GET_ORDER_DETAIL: 'GET_ORDER_DETAIL',
    GET_ORDER_DETAIL_LIST: 'GET_ORDER_DETAIL_LIST',
    EDIT_ORDER_DETAIL: 'EDIT_ORDER_DETAIL',
    DELETE_ORDER_DETAIL: 'DELETE_ORDER_DETAIL',
    ORDER_DETAIL_TOGGLE_HANDLE_MODAL: 'ORDER_DETAIL_TOGGLE_HANDLE_MODAL',
    CHANGE_ORDER_DETAIL_PAGINATION: 'CHANGE_ORDER_DETAIL_PAGINATION',
    CHANGE_ORDER_DETAIL_COUNT: 'CHANGE_ORDER_DETAIL_COUNT',
    createOrderDetail: orderDetail => {
      return (dispatch, getState) => {
        dispatch({
          type: orderDetailActions.CREATE_ORDER_DETAIL,
          orderDetails: [orderDetail, ...getState().orderDetail.orderDetails],
        });
      };
    },
    updateOrderDetail: orderDetail => {
      return (dispatch, getState) => {
        dispatch({
          type: orderDetailActions.UPDATE_ORDER_DETAIL,
          orderDetail,
        });
      };
    },
    editOrderDetail: orderDetail => {
      return (dispatch, getState) => {
        dispatch({
          type: orderDetailActions.EDIT_ORDER_DETAIL,
          orderDetail,
        });
      };
    },
    getOrderDetails: (orderDetails, orderDetailsCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: orderDetailActions.GET_ORDER_DETAIL_LIST,
          orderDetails,
          orderDetailsCount
        });
      };
    },
    deleteOrderDetail: id => {
      return (dispatch, getState) => {
        let currentCountries = getState().orderDetail.orderDetails.filter(s => s.id !== id);
        dispatch({
          type: orderDetailActions.DELETE_ORDER_DETAIL,
          orderDetails: currentCountries,
        });
      };
    },
    toggleModal: (orderDetail = null) => ({
      type: orderDetailActions.ORDER_DETAIL_TOGGLE_HANDLE_MODAL,
      orderDetail,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: orderDetailActions.CHANGE_ORDER_DETAIL_PAGINATION,
          pagination,
        });
      };
    },
    updateOrderDetailsCount: orderDetailsCount => {
      return (dispatch, getState) => {
        dispatch({
          type: orderDetailActions.CHANGE_ORDER_DETAIL_COUNT,
          orderDetailsCount,
        });
      };
    }
  
  };
  export default orderDetailActions;