import deviceActions from './actions';
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    devices: [],
    devicesCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    device: {
    }
};

export default function deviceReducer(state = initState, action) {
    switch (action.type) {
        case deviceActions.CREATE_DEVICE:
            initState.device = {}
            return {
                ...state,
                devices: action.devices,
                device: initState.device
            };
        case deviceActions.UPDATE_DEVICE:
            var deviceIndex = state.devices.findIndex(u => u.id === action.device.id);
            var devices = state.devices;
            devices[deviceIndex] = action.device;
            return {
                ...state,
                devices: [...devices]
            };
        case deviceActions.EDIT_DEVICE:
            return {
                ...state,
                device: action.device,
            };
        case deviceActions.GET_DEVICE_LIST:
            return {
                ...state,
                devices: action.devices,
                devicesCount: action.devicesCount,
            };
        case deviceActions.DELETE_DEVICE:
            return {
                ...state,
                devices: action.devices,
            };
        case deviceActions.DEVICE_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                device: action.device === null ? initState.device : action.device,
            };
        case deviceActions.DEVICE_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                device: action.device === null ? initState.device : action.device,

            };
        case deviceActions.CHANGE_DEVICE_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case deviceActions.CHANGE_DEVICE_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}