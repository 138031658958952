const countryActions = {
    CREATE_COUNTRY: 'CREATE_COUNTRY',
    UPDATE_COUNTRY: 'UPDATE_COUNTRY',
    GET_COUNTRY: 'GET_COUNTRY',
    GET_COUNTRY_LIST: 'GET_COUNTRY_LIST',
    EDIT_COUNTRY: 'EDIT_COUNTRY',
    DELETE_COUNTRY: 'DELETE_COUNTRY',
    COUNTRY_TOGGLE_HANDLE_MODAL: 'COUNTRY_TOGGLE_HANDLE_MODAL',
    CHANGE_COUNTRY_PAGINATION: 'CHANGE_COUNTRY_PAGINATION',
    CHANGE_COUNTRY_COUNT: 'CHANGE_COUNTRY_COUNT',
    createCountry: country => {
      return (dispatch, getState) => {
        dispatch({
          type: countryActions.CREATE_COUNTRY,
          countries: [country, ...getState().country.countries],
        });
      };
    },
    updateCountry: country => {
      return (dispatch, getState) => {
        dispatch({
          type: countryActions.UPDATE_COUNTRY,
          country,
        });
      };
    },
    editCountry: country => {
      return (dispatch, getState) => {
        dispatch({
          type: countryActions.EDIT_COUNTRY,
          country,
        });
      };
    },
    getCountries: (countries, countriesCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: countryActions.GET_COUNTRY_LIST,
          countries,
          countriesCount
        });
      };
    },
    deleteCountry: id => {
      return (dispatch, getState) => {
        let currentCountries = getState().country.countries.filter(s => s.id !== id);
        dispatch({
          type: countryActions.DELETE_COUNTRY,
          countries: currentCountries,
        });
      };
    },
    toggleModal: (country = null) => ({
      type: countryActions.COUNTRY_TOGGLE_HANDLE_MODAL,
      country,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: countryActions.CHANGE_COUNTRY_PAGINATION,
          pagination,
        });
      };
    },
    updateCountriesCount: countriesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: countryActions.CHANGE_COUNTRY_COUNT,
          countriesCount,
        });
      };
    }
  
  };
  export default countryActions;