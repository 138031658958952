import customerActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  customers: [],
  customersCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: ''
  },
  customer: {
  }
};

export default function customerReducer(state = initState, action) {
  switch (action.type) {
    case customerActions.CREATE_CUSTOMER:
      initState.customer = {}
      return {
        ...state,
        customers: action.customers,
        customer: initState.customer
      };
    case customerActions.UPDATE_CUSTOMER:
      var customerIndex = state.customers.findIndex(u => u.id === action.customer.id);
      var customers = state.customers;
      customers[customerIndex] = action.customer;
      return {
        ...state,
        customers:[...customers]
      };
    case customerActions.EDIT_CUSTOMER:
      return {
        ...state,
        customer: action.customer,
      };
    case customerActions.GET_CUSTOMER_LIST:
      return {
        ...state,
        customers: action.customers,
        customersCount: action.customersCount,
      };
    case customerActions.DELETE_CUSTOMER:
      return {
        ...state,
        customers: action.customers,
      };
    case customerActions.CUSTOMER_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        customer: action.customer === null ? initState.customer : action.customer,
      };
    case customerActions.CHANGE_CUSTOMER_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case customerActions.CHANGE_CUSTOMER_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
