const alesta_WarehouseActions = {
    CREATE_ALESTA_WAREHOUSE: 'CREATE_ALESTA_WAREHOUSE',
    UPDATE_ALESTA_WAREHOUSE: 'UPDATE_ALESTA_WAREHOUSE',
    GET_ALESTA_WAREHOUSE: 'GET_ALESTA_WAREHOUSE',
    GET_ALESTA_WAREHOUSE_LIST: 'GET_ALESTA_WAREHOUSE_LIST',
    EDIT_ALESTA_WAREHOUSE: 'EDIT_ALESTA_WAREHOUSE',
    DELETE_ALESTA_WAREHOUSE: 'DELETE_ALESTA_WAREHOUSE',
    ALESTA_WAREHOUSE_TOGGLE_HANDLE_MODAL: 'ALESTA_WAREHOUSE_TOGGLE_HANDLE_MODAL',
    CHANGE_ALESTA_WAREHOUSE_PAGINATION: 'CHANGE_ALESTA_WAREHOUSE_PAGINATION',
    CHANGE_ALESTA_WAREHOUSE_COUNT: 'CHANGE_ALESTA_WAREHOUSE_COUNT',
    createAlesta_Warehouse: alesta_Warehouse => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_WarehouseActions.CREATE_ALESTA_WAREHOUSE,
          alesta_Warehouses: [alesta_Warehouse, ...getState().alesta_Warehouse.alesta_Warehouses],
        });
      };
    },
    updateAlesta_Warehouse: alesta_Warehouse => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_WarehouseActions.UPDATE_ALESTA_WAREHOUSE,
          alesta_Warehouse,
        });
      };
    },
    editAlesta_Warehouse: alesta_Warehouse => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_WarehouseActions.EDIT_ALESTA_WAREHOUSE,
          alesta_Warehouse,
        });
      };
    },
    getAlesta_Warehouses: (alesta_Warehouses, alesta_WarehousesCount) => {
      
      return (dispatch, getState) => {
        dispatch({
          type: alesta_WarehouseActions.GET_ALESTA_WAREHOUSE_LIST,
          alesta_Warehouses,
          alesta_WarehousesCount
        });
      };
    },
    deleteAlesta_Warehouse: id => {
      return (dispatch, getState) => {
        var currentAlesta_Warehouses = getState().alesta_Warehouse.alesta_Warehouses.filter(s => s.id !== id);
        dispatch({
          type: alesta_WarehouseActions.DELETE_ALESTA_WAREHOUSE,
          alesta_Warehouses: currentAlesta_Warehouses,
        });
      };
    },
    toggleModal: (alesta_Warehouse = null) => ({
      type: alesta_WarehouseActions.ALESTA_WAREHOUSE_TOGGLE_HANDLE_MODAL,
      alesta_Warehouse,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_WarehouseActions.CHANGE_ALESTA_WAREHOUSE_PAGINATION,
          pagination,
        });
      };
    },
    updateAlesta_WarehousesCount: alesta_WarehousesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: alesta_WarehouseActions.CHANGE_ALESTA_WAREHOUSE_COUNT,
          alesta_WarehousesCount,
        });
      };
    }
  
  };
  export default alesta_WarehouseActions;