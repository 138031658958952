import productActions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  products: [],
  tags: [],
  warehouses: [],
  unitTypes: [],
  productsCount: 0,
  pagination: {
    currentPage: 1,
    descending: false,
    skip: 0,
    take: 10,
    search: '',
    sort: 'name'
  },
  product: {
    productPrices: []
  }
};

export default function productReducer(state = initState, action) {
  switch (action.type) {
    case productActions.CREATE_PRODUCT:
      initState.product = {}
      return {
        ...state,
        products: action.products,
        product: initState.product
      };
    case productActions.UPDATE_PRODUCT:
      let products = state.products.map(x => {
        if(x.id !== action.product.id){
          return x
        }

        return {
          ...x,
          ...action.product,
        }
      });
      return {
        ...state,
        products
      };
    case productActions.EDIT_PRODUCT:
      return {
        ...state,
        product: action.product,
      };
    case productActions.GET_PRODUCT_TAG_LIST:
      return {
        ...state,
        tags: action.tags,
      };
    case productActions.GET_PRODUCT_UNIT_TYPE_LIST:
      return {
        ...state,
        unitTypes: action.unitTypes,
      };
    case productActions.GET_PRODUCT_LIST:
      return {
        ...state,
        products: action.products,
        productsCount: action.productsCount,
      };
    case productActions.GET_PRODUCT_WAREHOUSE_LIST:
      return {
        ...state,
        warehouses: action.warehouses
      };
    case productActions.DELETE_PRODUCT:
      return {
        ...state,
        products: action.products,
      };
    case productActions.RESET_PRODUCT:
      return {
        ...state,
        product: {
          productPrices: []
        },
      };
    case productActions.PRODUCT_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        product: action.product === null ? initState.product : action.product,
      };
    case productActions.CHANGE_PRODUCT_PAGINATION:
      return {
        ...state,
        pagination: {...action.pagination},
      };
    case productActions.CHANGE_PRODUCT_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
