import formActions from "./actions";
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  forms: [],
  formsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: "",
    sort: "id",
    desc: true,
  },
  form: {},
};

export default function formReducer(state = initState, action) {
  switch (action.type) {
    case formActions.CREATE_FORM:
      initState.form = {};
      return {
        ...state,
        forms: action.forms,
        form: initState.form,
      };
    case formActions.UPDATE_FORM:
      var formIndex = state.forms.findIndex((u) => u.id === action.form.id);
      var forms = state.forms;
      forms[formIndex] = action.form;
      return {
        ...state,
        forms: [...forms],
      };
    case formActions.EDIT_FORM:
      return {
        ...state,
        form: action.form,
      };
    case formActions.GET_FORM_LIST:
      return {
        ...state,
        forms: action.forms,
        formsCount: action.formsCount,
      };
    case formActions.DELETE_FORM:
      return {
        ...state,
        forms: action.forms,
      };
    case formActions.FORM_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        form: action.form === null ? initState.form : action.form,
      };
    case formActions.CHANGE_FORM_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case formActions.CHANGE_FORM_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}

