import appsActions from './actions';

const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    appss: [],
    appssCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: ''
    },
    apps: {
    }
};

export default function appsReducer(state = initState, action) {
    switch (action.type) {
        case appsActions.CREATE_APPS:
            initState.apps = {}
            return {
                ...state,
                appss: action.appss,
                apps: initState.apps
            };
        case appsActions.UPDATE_APPS:
            var appsIndex = state.appss.findIndex(u => u.id === action.apps.id);
            var appss = state.appss;
            appss[appsIndex] = action.apps;
            return {
                ...state,
                appss: [...appss]
            };
        case appsActions.EDIT_APPS:
            return {
                ...state,
                apps: action.apps,
            };
        case appsActions.GET_APPS_LIST:
            return {
                ...state,
                appss: action.appss,
                appssCount: action.appssCount,
            };
        case appsActions.DELETE_APPS:
            return {
                ...state,
                appss: action.appss,
            };
        case appsActions.APPS_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                apps: action.apps === null ? initState.apps : action.apps,
            };
        case appsActions.CHANGE_APPS_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case appsActions.CHANGE_APPS_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}
