import placeFBActions from "./actions";
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  placeFBs: [],
  placeFBsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: "",
    sort: "id",
    desc: true,
  },
  placeFB: {},
};

export default function placeFBReducer(state = initState, action) {
  switch (action.type) {
    case placeFBActions.CREATE_PLACEFB:
      initState.placeFB = {};
      return {
        ...state,
        placeFBs: action.placeFBs,
        placeFB: initState.placeFB,
      };
    case placeFBActions.UPDATE_PLACEFB:
      var placeFBIndex = state.placeFBs.findIndex((u) => u.id === action.placeFB.id);
      var placeFBs = state.placeFBs;
      placeFBs[placeFBIndex] = action.placeFB;
      return {
        ...state,
        placeFBs: [...placeFBs],
      };
    case placeFBActions.EDIT_PLACEFB:
      return {
        ...state,
        placeFB: action.placeFB,
      };
    case placeFBActions.GET_PLACEFB_LIST:
      return {
        ...state,
        placeFBs: action.placeFBs,
        placeFBsCount: action.placeFBsCount,
      };
    case placeFBActions.DELETE_PLACEFB:
      return {
        ...state,
        placeFBs: action.placeFBs,
      };
    case placeFBActions.PLACEFB_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        placeFB: action.placeFB === null ? initState.placeFB : action.placeFB,
      };
    case placeFBActions.CHANGE_PLACEFB_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case placeFBActions.CHANGE_PLACEFB_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}

