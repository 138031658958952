const warehouseProductTransferActions = {
  GET_TRANSFER_WAREHOUSE_PRODUCTS: 'GET_TRANSFER_WAREHOUSE_PRODUCTS',
  GET_TRANSFER_FROM_WAREHOUSE: 'GET_TRANSFER_FROM_WAREHOUSE',
  GET_TRANSFER_TO_WAREHOUSE: 'GET_TRANSFER_TO_WAREHOUSE',
  GET_TRANSFER_WAREHOUSES: 'GET_TRANSFER_WAREHOUSES',
  GET_TRANSFERS_LIST: 'GET_TRANSFERS_LIST',
  ADD_TRANSFER: 'ADD_TRANSFER',
  REMOVE_TRANSFER: 'REMOVE_TRANSFER',
  UPDATE_TRANSFER: 'UPDATE_TRANSFER',
  TRANSFER_TOGGLE_STOCK_MODAL: "TRANSFER_TOGGLE_STOCK_MODAL",
  getWarehouseProducts: (warehouseProducts) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductTransferActions.GET_TRANSFER_WAREHOUSE_PRODUCTS,
        warehouseProducts
      });
    };
  },
  getTransfers: (transfers) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductTransferActions.GET_TRANSFERS_LIST,
        transfers
      });
    };
  },
  getWarehouses: (warehouses) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductTransferActions.GET_TRANSFER_WAREHOUSES,
        warehouses
      });
    };
  },
  getTransferFromWarehouse: (transferFromWarehouse) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductTransferActions.GET_TRANSFER_FROM_WAREHOUSE,
        transferFromWarehouse
      });
    };
  },
  getTransferToWarehouse: (transferToWarehouse) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductTransferActions.GET_TRANSFER_TO_WAREHOUSE,
        transferToWarehouse
      });
    };
  },
  addTransfer: (transfer) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductTransferActions.ADD_TRANSFER,
        transfer
      })
    }
  },
  updateTransfer: (transfer) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductTransferActions.UPDATE_TRANSFER,
        transfer
      })
    }
  },
  removeTransfer: (transfer) => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductTransferActions.REMOVE_TRANSFER,
        transfer
      })
    }
  },
  toggleModal: () => {
    return (dispatch, getState) => {
      dispatch({
        type: warehouseProductTransferActions.TRANSFER_TOGGLE_STOCK_MODAL
      })
    }
  }
};
export default warehouseProductTransferActions;