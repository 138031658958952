const devicelogActions = {
    CREATE_DEVICELOG: 'CREATE_DEVICELOG',
    UPDATE_DEVICELOG: 'UPDATE_DEVICELOG',
    GET_DEVICELOG: 'GET_DEVICELOG',
    GET_DEVICELOG_LIST: 'GET_DEVICELOG_LIST',
    EDIT_DEVICELOG: 'EDIT_DEVICELOG',
    DELETE_DEVICELOG: 'DELETE_DEVICELOG',
    DEVICELOG_TOGGLE_HANDLE_MODAL: 'DEVICELOG_TOGGLE_HANDLE_MODAL',
    DEVICELOG_TOGGLE2_HANDLE_MODAL: 'DEVICELOG_TOGGLE2_HANDLE_MODAL',
    CHANGE_DEVICELOG_PAGINATION: 'CHANGE_DEVICELOG_PAGINATION',
    CHANGE_DEVICELOG_COUNT: 'CHANGE_DEVICELOG_COUNT',
    createDevicelog: devicelog => {
        return (dispatch, getState) => {
            dispatch({
                type: devicelogActions.CREATE_DEVICELOG,
                devicelogs: [devicelog, ...getState().devicelog.devicelogs],
            });
        };
    },
    updateDevicelog: devicelog => {
        return (dispatch, getState) => {
            dispatch({
                type: devicelogActions.UPDATE_DEVICELOG,
                devicelog,
            });
        };
    },
    editDevicelog: devicelog => {
        return (dispatch, getState) => {
            dispatch({
                type: devicelogActions.EDIT_DEVICELOG,
                devicelog,
            });
        };
    },
    getDevicelogs: (devicelogs, devicelogsCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: devicelogActions.GET_DEVICELOG_LIST,
                devicelogs,
                devicelogsCount
            });
        };
    },
    deleteDevicelog: id => {
        return (dispatch, getState) => {
            var currentDevicelogs = getState().devicelog.devicelogs.filter(s => s.id !== id);
            dispatch({
                type: devicelogActions.DELETE_DEVICELOG,
                devicelogs: currentDevicelogs,
            });
        };
    },
    toggleModal: (devicelog = null) => ({
        type: devicelogActions.DEVICELOG_TOGGLE_HANDLE_MODAL,
        devicelog,
    }),
    toggleModal2: (devicelog = null) => ({
        type: devicelogActions.DEVICELOG_TOGGLE2_HANDLE_MODAL,
        devicelog,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: devicelogActions.CHANGE_DEVICELOG_PAGINATION,
                pagination,
            });
        };
    },
    updateDevicelogsCount: devicelogsCount => {
        return (dispatch, getState) => {
            dispatch({
                type: devicelogActions.CHANGE_DEVICELOG_COUNT,
                devicelogsCount,
            });
        };
    }

};
export default devicelogActions;