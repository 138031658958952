import alesta_CampaignTypeActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  alesta_CampaignTypes: [],
  alesta_CampaignTypesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id',
    descending:true,
  },
  alesta_CampaignType: {
  }
};

export default function alesta_CampaignTypeReducer(state = initState, action) {
  switch (action.type) {
    case alesta_CampaignTypeActions.CREATE_ALESTA_CAMPAIGNTYPE :
      initState.alesta_CampaignType = {}
      return {
        ...state,
        alesta_CampaignTypes: action.alesta_CampaignTypes,
        alesta_CampaignType: initState.alesta_CampaignType
      };
    case alesta_CampaignTypeActions.UPDATE_ALESTA_CAMPAIGNTYPE :
      var alesta_CampaignTypeIndex = state.alesta_CampaignTypes.findIndex(u => u.id === action.alesta_CampaignType.id);
      var alesta_CampaignTypes = state.alesta_CampaignTypes;
      alesta_CampaignTypes[alesta_CampaignTypeIndex] = action.alesta_CampaignType;
      return {
        ...state,
        alesta_CampaignTypes:[...alesta_CampaignTypes]
      };
    case alesta_CampaignTypeActions.EDIT_ALESTA_CAMPAIGNTYPE :
      return {
        ...state,
        alesta_CampaignType: action.alesta_CampaignType,
      };
    case alesta_CampaignTypeActions.GET_ALESTA_CAMPAIGNTYPE_LIST:
      return {
        ...state,
        alesta_CampaignTypes: action.alesta_CampaignTypes,
        alesta_CampaignTypesCount: action.alesta_CampaignTypesCount,
      };
    case alesta_CampaignTypeActions.DELETE_ALESTA_CAMPAIGNTYPE :
      return {
        ...state,
        alesta_CampaignTypes: action.alesta_CampaignTypes,
      };
    case alesta_CampaignTypeActions.ALESTA_CAMPAIGNTYPE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        alesta_CampaignType: action.alesta_CampaignType === null ? initState.alesta_CampaignType : action.alesta_CampaignType,
      };
    case alesta_CampaignTypeActions.CHANGE_ALESTA_CAMPAIGNTYPE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case alesta_CampaignTypeActions.CHANGE_ALESTA_CAMPAIGNTYPE_COUNT:
      return {
        ...state,
        alesta_CampaignTypesCount: action.alesta_CampaignTypesCount,
      };
    default:
      return state;
  }
}
