const commentActions = {
    CREATE_COMMENT: 'CREATE_COMMENT',
    UPDATE_COMMENT: 'UPDATE_COMMENT',
    GET_COMMENT: 'GET_COMMENT',
    GET_COMMENT_LIST: 'GET_COMMENT_LIST',
    EDIT_COMMENT: 'EDIT_COMMENT',
    DELETE_COMMENT: 'DELETE_COMMENT',
    COMMENT_TOGGLE_HANDLE_MODAL: 'COMMENT_TOGGLE_HANDLE_MODAL',
    COMMENT_TOGGLE2_HANDLE_MODAL: 'COMMENT_TOGGLE2_HANDLE_MODAL',
    CHANGE_COMMENT_PAGINATION: 'CHANGE_COMMENT_PAGINATION',
    CHANGE_COMMENT_COUNT: 'CHANGE_COMMENT_COUNT',
    createComment: comment => {
        return (dispatch, getState) => {
            dispatch({
                type: commentActions.CREATE_COMMENT,
                comments: [comment, ...getState().comment.comments],
            });
        };
    },
    updateComment: comment => {
        return (dispatch, getState) => {
            dispatch({
                type: commentActions.UPDATE_COMMENT,
                comment,
            });
        };
    },
    editComment: comment => {
        return (dispatch, getState) => {
            dispatch({
                type: commentActions.EDIT_COMMENT,
                comment,
            });
        };
    },
    getComments: (comments, commentsCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: commentActions.GET_COMMENT_LIST,
                comments,
                commentsCount
            });
        };
    },
    deleteComment: id => {
        return (dispatch, getState) => {
            var currentComments = getState().comment.comments.filter(s => s.id !== id);
            dispatch({
                type: commentActions.DELETE_COMMENT,
                comments: currentComments,
            });
        };
    },
    toggleModal: (comment = null) => ({
        type: commentActions.COMMENT_TOGGLE_HANDLE_MODAL,
        comment,
    }),
    toggleModal2: (comment = null) => ({
        type: commentActions.COMMENT_TOGGLE2_HANDLE_MODAL,
        comment,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: commentActions.CHANGE_COMMENT_PAGINATION,
                pagination,
            });
        };
    },
    updateCommentsCount: commentsCount => {
        return (dispatch, getState) => {
            dispatch({
                type: commentActions.CHANGE_COMMENT_COUNT,
                commentsCount,
            });
        };
    }
}
export default commentActions;