import moduleActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  modules: [],
  modulesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: ''
  },
  module: {
    roleIds: []
  }
};

export default function moduleReducer(state = initState, action) {
  switch (action.type) {
    case moduleActions.CREATE_MODULE:
      initState.module = {}
      return {
        ...state,
        modules: action.modules,
        module: initState.module
      };
    case moduleActions.UPDATE_MODULE:
      var moduleIndex = state.modules.findIndex(u => u.id === action.module.id);
      var modules = state.modules;
      modules[moduleIndex] = action.module;
      return {
        ...state,
        modules
      };
    case moduleActions.EDIT_MODULE:
      return {
        ...state,
        module: action.module,
      };
    case moduleActions.GET_MODULE_LIST:
      return {
        ...state,
        modules: action.modules,
        modulesCount: action.modulesCount,
      };
    case moduleActions.DELETE_MODULE:
      return {
        ...state,
        modules: action.modules,
      };
    case moduleActions.MODULE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        module: action.module === null ? initState.module : action.module,
      };
    case moduleActions.CHANGE_MODULE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case moduleActions.CHANGE_MODULE_COUNT:
      return {
        ...state,
        modulesCount: action.modulesCount,
      };
    default:
      return state;
  }
}
