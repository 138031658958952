const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  CHANGE_LOCAL_USER: 'CHANGE_LOCAL_USER',
  SET_LOGIN_LOADER: 'SET_LOGIN_LOADER',
  SIGNUP_USER: 'SIGNUP_USER',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  IS_SIGNUP_SUCCESS: 'IS_SIGNUP_SUCCESS',
  setLoader: (loading) => ({
    type: actions.SET_LOGIN_LOADER,
    loading,
  }),
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (fields) => ({
    type: actions.LOGIN_REQUEST,
    payload: { fields },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  autoLogin: (user,token) => ({
    type: actions.LOGIN_SUCCESS,
    user,
    token
  }),
  changeLocalUser: (user) => ({
    type: actions.CHANGE_LOCAL_USER,
    user,
  }),
  signUpUser: (user) => ({
    type: actions.SIGNUP_USER,
    payload: user,
  }),
  signUpFailed: () => ({
    type: actions.IS_SIGNUP_SUCCESS,
    payload: false,
  }),
  signUp: (user) => ({
    type: actions.SIGNUP_REQUEST,
    payload: { user },
  }),
  isInRole: role => {
    return (dispatch, getState) => {
      var user = getState().Auth.user;
      return user && user.id > 0 && user.roles && user.roles.includes(role);
    };
  },
  isInModule: module => {
    return (dispatch, getState) => {
      var user = getState().Auth.user;
      return user && user.id > 0 && user.modules && user.modules.includes(module);
    };
  }
};
export default actions;
