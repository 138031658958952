import alesta_DocumentActions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  alesta_Documents: [],
  alesta_DocumentsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: "",
    sort: "id",
    descending: true,
  },
  alesta_Document: {},
};

export default function alesta_DocumentReducer(state = initState, action) {
  switch (action.type) {
    case alesta_DocumentActions.CREATE_ALESTA_DOCUMENT:
      initState.alesta_Document = {};
      return {
        ...state,
        alesta_Documents: action.alesta_Documents,
        alesta_Document: initState.alesta_Document,
      };
    case alesta_DocumentActions.UPDATE_ALESTA_DOCUMENT:
      var alesta_DocumentIndex = state.alesta_Documents.findIndex((u) => u.id === action.alesta_Document.id);
      var alesta_Documents = state.alesta_Documents;
      alesta_Documents[alesta_DocumentIndex] = action.alesta_Document;
      return {
        ...state,
        alesta_Documents: [...alesta_Documents],
      };
    case alesta_DocumentActions.EDIT_ALESTA_DOCUMENT:
      return {
        ...state,
        alesta_Document: action.alesta_Document,
      };
    case alesta_DocumentActions.GET_ALESTA_DOCUMENT_LIST:
      return {
        ...state,
        alesta_Documents: action.alesta_Documents,
        alesta_DocumentsCount: action.alesta_DocumentsCount,
      };
    case alesta_DocumentActions.DELETE_ALESTA_DOCUMENT:
      return {
        ...state,
        alesta_Documents: action.alesta_Documents,
      };
    case alesta_DocumentActions.ALESTA_DOCUMENT_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        alesta_Document: action.alesta_Document === null ? initState.alesta_Document : action.alesta_Document,
      };
    case alesta_DocumentActions.CHANGE_ALESTA_DOCUMENT_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case alesta_DocumentActions.CHANGE_ALESTA_DOCUMENT_COUNT:
      return {
        ...state,
        alesta_DocumentsCount: action.alesta_DocumentsCount,
      };
    default:
      return state;
  }
}

