import purchaseActions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  searchProducts: [],
  purchasesProducts: [],
  purchasesProductsCount: 0,
  modalWarehouseProducts: [],
  customers: [],
  warehouses: [],
  oldTotalPurchasesPrice: 0,
  totalPurchasesPrice: 0,
  pagination: {
    currentPage: 1,
    descending: false,
    skip: 0,
    take: 10,
    search: '',
    sort: 'name'
  }
};

export default function purchaseReducer(state = initState, action) {
  switch (action.type) {
    case purchaseActions.PURCHASES_GET_SEARCH_PRODUCT_LIST:
      return {
        ...state,
        searchProducts: action.searchProducts
      };
    case purchaseActions.PURCHASES_GET_PURCHASES_PRODUCT_LIST:
      return {
        ...state,
        purchasesProducts:  action.purchasesProducts
      };
    case purchaseActions.PURCHASES_RESET_FIELDS:
      return {
        ...state,
        purchasesProducts: [],
        searchProducts: [],
        modalWarehouseProducts: [],
        totalPurchasesPrice: 0,
        oldTotalPurchasesPrice: 0,
        purchasesProductsCount: 0
      };
    case purchaseActions.PURCHASES_GET_MODAL_WAREHOUSE_PRODUCT_LIST:
      return {
        ...state,
        modalWarehouseProducts: action.warehouseProducts
      };
    case purchaseActions.PURCHASES_GET_WAREHOUSE_LIST:
      return {
        ...state,
        warehouses: action.warehouses
      };
    case purchaseActions.PURCHASES_GET_CUSTOMER_LIST:
      return {
        ...state,
        customers: action.customers
      };
    case purchaseActions.PURCHASES_ADD_PURCHASES_PRODUCT:
      return {
        ...state,
        purchasesProducts: [action.product, ...state.purchasesProducts],
        purchasesProductsCount: (state.purchasesProductsCount + 1)
      };
    case purchaseActions.PURCHASES_UPDATE_PURCHASES_PRODUCT:
      let newPurchasesProducts = state.purchasesProducts.map(purchaseWarehouseProduct => {
        if (purchaseWarehouseProduct.id === action.product.id) {
          return Object.assign(purchaseWarehouseProduct, action.product)
        } else {
         return purchaseWarehouseProduct
        }
      })
      return {
        ...state,
        purchasesProducts: newPurchasesProducts
      };
    case purchaseActions.PURCHASES_REMOVE_PURCHASES_PRODUCT:
      let purchasesProducts = state.purchasesProducts.filter(purchase => purchase.id !== action.product.id)
      return {
        ...state,
        purchasesProducts,
        purchasesProductsCount: (state.purchasesProductsCount - 1)
      }
    case purchaseActions.PURCHASES_UPDATE_PURCHASES_TOTAL_PRICE:
      return {
        ...state,
        totalPurchasesPrice: action.totalPurchasesPrice
      }
    case purchaseActions.PURCHASES_UPDATE_OLD_PURCHASES_TOTAL_PRICE:
      return {
        ...state,
        oldTotalPurchasesPrice: action.oldTotalPurchasesPrice
      }
    case purchaseActions.PURCHASES_TOGGLE_STOCK_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive
      }
    default:
      return state;
  }
}
