import actions from './actions';
const initState = { idToken: null, user: { id: 0 } };
export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        user: action.user,
        loading: false
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        message: action.payload.message?? "User Not Found"
      };
    case actions.CHANGE_LOCAL_USER:
      return {
        ...state,
        user: action.user
      };
      case actions.SIGNUP_ERROR:
        return {
          ...state,
          loading: false
        };
    case actions.SET_LOGIN_LOADER:
      return {
        ...state,
        loading: action.loading
      };
    case actions.SIGNUP_USER:
      return {
        ...state,
        user: action.payload
      };
      case actions.IS_SIGNUP_SUCCESS:
        return {
          ...state,
          success: action.success,
          loading: false
        };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
