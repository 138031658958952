const appsActions = {
    CREATE_APPS: 'CREATE_APPS',
    UPDATE_APPS: 'UPDATE_APPS',
    GET_APPS: 'GET_APPS',
    GET_APPS_LIST: 'GET_APPS_LIST',
    EDIT_APPS: 'EDIT_APPS',
    DELETE_APPS: 'DELETE_APPS',
    APPS_TOGGLE_HANDLE_MODAL: 'APPS_TOGGLE_HANDLE_MODAL',
    CHANGE_APPS_PAGINATION: 'CHANGE_APPS_PAGINATION',
    CHANGE_APPS_COUNT: 'CHANGE_APPS_COUNT',
    createApps: apps => {
        return (dispatch, getState) => {
            dispatch({
                type: appsActions.CREATE_APPS,
                appss: [apps, ...getState().apps.appss],
            });
        };
    },
    updateApps: apps => {
        return (dispatch, getState) => {
            dispatch({
                type: appsActions.UPDATE_APPS,
                apps,
            });
        };
    },
    editApps: apps => {
        return (dispatch, getState) => {
            dispatch({
                type: appsActions.EDIT_APPS,
                apps,
            });
        };
    },
    getAppss: (appss, appssCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: appsActions.GET_APPS_LIST,
                appss,
                appssCount
            });
        };
    },
    deleteApps: id => {
        return (dispatch, getState) => {
            var currentAppss = getState().apps.appss.filter(s => s.id !== id);
            dispatch({
                type: appsActions.DELETE_APPS,
                appss: currentAppss,
            });
        };
    },
    toggleModal: (apps = null) => ({
        type: appsActions.APPS_TOGGLE_HANDLE_MODAL,
        apps,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: appsActions.CHANGE_APPS_PAGINATION,
                pagination,
            });
        };
    },
    updateAppssCount: appssCount => {
        return (dispatch, getState) => {
            dispatch({
                type: appsActions.CHANGE_APPS_COUNT,
                appssCount,
            });
        };
    }

};
export default appsActions;