const qualityfileActions = {
  CREATE_QUALITYFILE: "CREATE_QUALITYFILE",
  UPDATE_QUALITYFILE: "UPDATE_QUALITYFILE",
  GET_QUALITYFILE: "GET_QUALITYFILE",
  GET_QUALITYFILE_LIST: "GET_QUALITYFILE_LIST",
  EDIT_QUALITYFILE: "EDIT_QUALITYFILE",
  DELETE_QUALITYFILE: "DELETE_QUALITYFILE",
  QUALITYFILE_TOGGLE_HANDLE_MODAL: "QUALITYFILE_TOGGLE_HANDLE_MODAL",
  CHANGE_QUALITYFILE_PAGINATION: "CHANGE_QUALITYFILE_PAGINATION",
  CHANGE_QUALITYFILE_COUNT: "CHANGE_QUALITYFILE_COUNT",
  createQualityfile: (qualityfile) => {
    return (dispatch, getState) => {
      dispatch({
        type: qualityfileActions.CREATE_QUALITYFILE,
        qualityfiles: [qualityfile, ...getState().qualityfile.qualityfiles],
      });
    };
  },
  updateQualityfile: (qualityfile) => {
    return (dispatch, getState) => {
      dispatch({
        type: qualityfileActions.UPDATE_QUALITYFILE,
        qualityfile,
      });
    };
  },
  editQualityfile: (qualityfile) => {
    return (dispatch, getState) => {
      dispatch({
        type: qualityfileActions.EDIT_QUALITYFILE,
        qualityfile,
      });
    };
  },
  getQualityfiles: (qualityfiles, qualityfilesCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: qualityfileActions.GET_QUALITYFILE_LIST,
        qualityfiles,
        qualityfilesCount,
      });
    };
  },
  deleteQualityfile: (id) => {
    return (dispatch, getState) => {
      var currentQualityfiles = getState().qualityfile.qualityfiles.filter((s) => s.id !== id);
      dispatch({
        type: qualityfileActions.DELETE_QUALITYFILE,
        qualityfiles: currentQualityfiles,
      });
    };
  },
  toggleModal: (qualityfile = null) => ({
    type: qualityfileActions.QUALITYFILE_TOGGLE_HANDLE_MODAL,
    qualityfile,
  }),
  changePagination: (pagination) => {
    return (dispatch, getState) => {
      dispatch({
        type: qualityfileActions.CHANGE_QUALITYFILE_PAGINATION,
        pagination,
      });
    };
  },
  updateQualityfilesCount: (qualityfilesCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: qualityfileActions.CHANGE_QUALITYFILE_COUNT,
        qualityfilesCount,
      });
    };
  },
};
export default qualityfileActions;
