import sidebarmenuActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  sidebarmenus: [],
  allsidebarmenus: [],
  sidebarmenutrees: [],
  sidebarmenusCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: ''
  },
  sidebarmenu: {
  }
};

export default function sidebarmenuReducer(state = initState, action) {
  switch (action.type) {
    case sidebarmenuActions.CREATE_SIDEBARMENU:
      initState.sidebarmenu = {}
      return {
        ...state,
        sidebarmenus: action.sidebarmenus,
        sidebarmenu: initState.sidebarmenu
      };
    case sidebarmenuActions.UPDATE_SIDEBARMENU:
      var sidebarmenuIndex = state.sidebarmenus.findIndex(u => u.id === action.sidebarmenu.id);
      var sidebarmenus = state.sidebarmenus;
      sidebarmenus[sidebarmenuIndex] = action.sidebarmenu;
      return {
        ...state,
        sidebarmenus
      };
    case sidebarmenuActions.EDIT_SIDEBARMENU:
      return {
        ...state,
        sidebarmenu: action.sidebarmenu,
      };
    case sidebarmenuActions.GET_SIDEBARMENU_LIST:
      return {
        ...state,
        sidebarmenus: action.sidebarmenus,
        sidebarmenusCount: action.sidebarmenusCount,
      };
      case sidebarmenuActions.GET_ALLSIDEBARMENU_LIST:
        return {
          ...state,
          allsidebarmenus: action.sidebarmenus
        };
      case sidebarmenuActions.GET_SIDEBARMENU_TREE:
        return {
          ...state,
          sidebarmenutrees: action.sidebarmenus
        };
    case sidebarmenuActions.DELETE_SIDEBARMENU:
      return {
        ...state,
        sidebarmenus: action.sidebarmenus,
      };
    case sidebarmenuActions.SIDEBARMENU_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        sidebarmenu: action.sidebarmenu === null ? initState.sidebarmenu : action.sidebarmenu,
      };
    case sidebarmenuActions.CHANGE_SIDEBARMENU_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case sidebarmenuActions.CHANGE_SIDEBARMENU_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
