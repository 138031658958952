const retailSalesActions = {
  RETAIL_SALES_GET_SEARCH_WAREHOUSE_PRODUCT_LIST: 'RETAIL_SALES_GET_SEARCH_WAREHOUSE_PRODUCT_LIST',
  RETAIL_SALES_GET_MODAL_WAREHOUSE_PRODUCT_LIST: 'RETAIL_SALES_GET_MODAL_WAREHOUSE_PRODUCT_LIST',
  RETAIL_SALES_GET_RETAIL_SALES_WAREHOUSE_PRODUCT_LIST: 'RETAIL_SALES_GET_RETAIL_SALES_WAREHOUSE_PRODUCT_LIST',
  RETAIL_SALES_RESET_RETAIL_SALES_WAREHOUSE_PRODUCT_LIST: 'RETAIL_SALES_RESET_RETAIL_SALES_WAREHOUSE_PRODUCT_LIST',
  RETAIL_SALES_ADD_RETAIL_SALES_WAREHOUSE_PRODUCT: 'RETAIL_SALES_ADD_RETAIL_SALES_WAREHOUSE_PRODUCT',
  RETAIL_SALES_REMOVE_RETAIL_SALES_WAREHOUSE_PRODUCT: 'RETAIL_SALES_REMOVE_RETAIL_SALES_WAREHOUSE_PRODUCT',
  RETAIL_SALES_UPDATE_RETAIL_SALES_WAREHOUSE_PRODUCT: 'RETAIL_SALES_UPDATE_RETAIL_SALES_WAREHOUSE_PRODUCT',
  RETAIL_SALES_UPDATE_RETAIL_SALES_TOTAL_PRICE: 'RETAIL_SALES_UPDATE_RETAIL_SALES_TOTAL_PRICE',
  RETAIL_SALES_UPDATE_OLD_RETAIL_SALES_TOTAL_PRICE: 'RETAIL_SALES_UPDATE_OLD_RETAIL_SALES_TOTAL_PRICE',
  RETAIL_SALES_TOGGLE_STOCK_MODAL: "RETAIL_SALES_TOGGLE_STOCK_MODAL",
  getSearchWarehouseProducts: (searchWarehouseProducts) => {
    return (dispatch, getState) => {
      dispatch({
        type: retailSalesActions.RETAIL_SALES_GET_SEARCH_WAREHOUSE_PRODUCT_LIST,
        searchWarehouseProducts
      });
    };
  },
  getRetailSalesWarehouseProducts: (retailSalesWarehouseProducts) => {
    return (dispatch, getState) => {
      dispatch({
        type: retailSalesActions.RETAIL_SALES_GET_RETAIL_SALES_WAREHOUSE_PRODUCT_LIST,
        retailSalesWarehouseProducts
      });
    };
  },
  resetRetailSalesWarehouseProducts: () => {
    return (dispatch, getState) => {
      dispatch({
        type: retailSalesActions.RETAIL_SALES_RESET_RETAIL_SALES_WAREHOUSE_PRODUCT_LIST
      });
    };
  },
  getModalWarehouseProducts: (warehouseProducts) => {
    return (dispatch, getState) => {
      dispatch({
        type: retailSalesActions.RETAIL_SALES_GET_MODAL_WAREHOUSE_PRODUCT_LIST,
        warehouseProducts
      });
    };
  },
  addRetailSaleProduct: (warehouseProduct) => {
    return (dispatch, getState) => {
      dispatch({
        type: retailSalesActions.RETAIL_SALES_ADD_RETAIL_SALES_WAREHOUSE_PRODUCT,
        warehouseProduct
      })
    }
  },
  updateRetailSaleProduct: (warehouseProduct) => {
    return (dispatch, getState) => {
      dispatch({
        type: retailSalesActions.RETAIL_SALES_UPDATE_RETAIL_SALES_WAREHOUSE_PRODUCT,
        warehouseProduct
      })
    }
  },
  removeRetailSaleProduct: (warehouseProduct) => {
    return (dispatch, getState) => {
      dispatch({
        type: retailSalesActions.RETAIL_SALES_REMOVE_RETAIL_SALES_WAREHOUSE_PRODUCT,
        warehouseProduct
      })
    }
  },
  updateTotalRetailSalesPrice: (totalRetailSalesPrice) => {
    return (dispatch, getState) => {
      dispatch({
        type: retailSalesActions.RETAIL_SALES_UPDATE_RETAIL_SALES_TOTAL_PRICE,
        totalRetailSalesPrice
      })
    }
  },
  updateOldTotalRetailSalesPrice: (oldTotalRetailSalesPrice) => {
    return (dispatch, getState) => {
      dispatch({
        type: retailSalesActions.RETAIL_SALES_UPDATE_OLD_RETAIL_SALES_TOTAL_PRICE,
        oldTotalRetailSalesPrice
      })
    }
  },
  toggleModal: () => {
    return (dispatch, getState) => {
      dispatch({
        type: retailSalesActions.RETAIL_SALES_TOGGLE_STOCK_MODAL
      })
    }
  }
};
export default retailSalesActions;