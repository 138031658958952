import feedbackActions from './actions';
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    feedbacks: [],
    feedbacksCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    feedback: {
    }
};

export default function feedbackReducer(state = initState, action) {
    switch (action.type) {
        case feedbackActions.CREATE_FEEDBACK:
            initState.feedback = {}
            return {
                ...state,
                feedbacks: action.feedbacks,
                feedback: initState.feedback
            };
        case feedbackActions.UPDATE_FEEDBACK:
            var feedbackIndex = state.feedbacks.findIndex(u => u.id === action.feedback.id);
            var feedbacks = state.feedbacks;
            feedbacks[feedbackIndex] = action.feedback;
            return {
                ...state,
                feedbacks: [...feedbacks]
            };
        case feedbackActions.EDIT_FEEDBACK:
            return {
                ...state,
                feedback: action.feedback,
            };
        case feedbackActions.GET_FEEDBACK_LIST:
            return {
                ...state,
                feedbacks: action.feedbacks,
                feedbacksCount: action.feedbacksCount,
            };
        case feedbackActions.DELETE_FEEDBACK:
            return {
                ...state,
                feedbacks: action.feedbacks,
            };
        case feedbackActions.FEEDBACK_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                feedback: action.feedback === null ? initState.feedback : action.feedback,
            };
        case feedbackActions.FEEDBACK_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                feedback: action.feedback === null ? initState.feedback : action.feedback,

            };
        case feedbackActions.CHANGE_FEEDBACK_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case feedbackActions.CHANGE_FEEDBACK_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}