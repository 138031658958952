const moduleActions = {
    CREATE_MODULE: 'CREATE_MODULE',
    UPDATE_MODULE: 'UPDATE_MODULE',
    ASSIGN_MODULE: 'ASSIGN_MODULE',
    GET_MODULE: 'GET_MODULE',
    GET_MODULE_LIST: 'GET_MODULE_LIST',
    EDIT_MODULE: 'EDIT_MODULE',
    DELETE_MODULE: 'DELETE_MODULE',
    MODULE_TOGGLE_HANDLE_MODAL: 'MODULE_TOGGLE_HANDLE_MODAL',
    CHANGE_MODULE_PAGINATION: 'CHANGE_MODULE_PAGINATION',
    CHANGE_MODULE_COUNT: 'CHANGE_MODULE_COUNT',
    createModule: module => {
      return (dispatch, getState) => {
        dispatch({
          type: moduleActions.CREATE_MODULE,
          modules: [module, ...getState().module.modules],
        });
      };
    },
    updateModule: module => {
      return (dispatch, getState) => {
        dispatch({
          type: moduleActions.UPDATE_MODULE,
          module,
        });
      };
    },
    editModule: module => {
      return (dispatch, getState) => {
        dispatch({
          type: moduleActions.EDIT_MODULE,
          module,
        });
      };
    },
    getModules: (modules, modulesCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: moduleActions.GET_MODULE_LIST,
          modules,
          modulesCount
        });
      };
    },
    deleteModule: id => {
      return (dispatch, getState) => {
        var currentModules = getState().module.modules.filter(s => s.id !== id);
        dispatch({
          type: moduleActions.DELETE_MODULE,
          modules: currentModules,
        });
      };
    },
    toggleModal: (module = null) => ({
      type: moduleActions.MODULE_TOGGLE_HANDLE_MODAL,
      module,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: moduleActions.CHANGE_MODULE_PAGINATION,
          pagination,
        });
      };
    },
    updateModulesCount: modulesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: moduleActions.CHANGE_MODULE_COUNT,
          modulesCount,
        });
      };
    }
  
  };
  export default moduleActions;