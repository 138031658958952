import alesta_FicheTypeActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  alesta_FicheTypes: [],
  alesta_FicheTypesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: 'id',
    descending:true,
  },
  alesta_FicheType: {
  }
};

export default function alesta_FicheTypeReducer(state = initState, action) {
  switch (action.type) {
    case alesta_FicheTypeActions.CREATE_ALESTA_FICHETYPE :
      initState.alesta_FicheType = {}
      return {
        ...state,
        alesta_FicheTypes: action.alesta_FicheTypes,
        alesta_FicheType: initState.alesta_FicheType
      };
    case alesta_FicheTypeActions.UPDATE_ALESTA_FICHETYPE :
      var alesta_FicheTypeIndex = state.alesta_FicheTypes.findIndex(u => u.id === action.alesta_FicheType.id);
      var alesta_FicheTypes = state.alesta_FicheTypes;
      alesta_FicheTypes[alesta_FicheTypeIndex] = action.alesta_FicheType;
      return {
        ...state,
        alesta_FicheTypes:[...alesta_FicheTypes]
      };
    case alesta_FicheTypeActions.EDIT_ALESTA_FICHETYPE :
      return {
        ...state,
        alesta_FicheType: action.alesta_FicheType,
      };
    case alesta_FicheTypeActions.GET_ALESTA_FICHETYPE_LIST:
      return {
        ...state,
        alesta_FicheTypes: action.alesta_FicheTypes,
        alesta_FicheTypesCount: action.alesta_FicheTypesCount,
      };
    case alesta_FicheTypeActions.DELETE_ALESTA_FICHETYPE :
      return {
        ...state,
        alesta_FicheTypes: action.alesta_FicheTypes,
      };
    case alesta_FicheTypeActions.ALESTA_FICHETYPE_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        alesta_FicheType: action.alesta_FicheType === null ? initState.alesta_FicheType : action.alesta_FicheType,
      };
    case alesta_FicheTypeActions.CHANGE_ALESTA_FICHETYPE_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case alesta_FicheTypeActions.CHANGE_ALESTA_FICHETYPE_COUNT:
      return {
        ...state,
        alesta_FicheTypesCount: action.alesta_FicheTypesCount,
      };
    default:
      return state;
  }
}
