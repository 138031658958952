const trapdeviceActions = {
    CREATE_TRAPDEVICE: 'CREATE_TRAPDEVICE',
    UPDATE_TRAPDEVICE: 'UPDATE_TRAPDEVICE',
    GET_TRAPDEVICE: 'GET_TRAPDEVICE',
    GET_TRAPDEVICE_LIST: 'GET_TRAPDEVICE_LIST',
    EDIT_TRAPDEVICE: 'EDIT_TRAPDEVICE',
    DELETE_TRAPDEVICE: 'DELETE_TRAPDEVICE',
    TRAPDEVICE_TOGGLE_HANDLE_MODAL: 'TRAPDEVICE_TOGGLE_HANDLE_MODAL',
    TRAPDEVICE_TOGGLE2_HANDLE_MODAL: 'TRAPDEVICE_TOGGLE2_HANDLE_MODAL',
    CHANGE_TRAPDEVICE_PAGINATION: 'CHANGE_TRAPDEVICE_PAGINATION',
    CHANGE_TRAPDEVICE_COUNT: 'CHANGE_TRAPDEVICE_COUNT',
    createTrapDevice: trapdevice => {
        return (dispatch, getState) => {
            dispatch({
                type: trapdeviceActions.CREATE_TRAPDEVICE,
                trapdevices: [trapdevice, ...getState().trapdevice.trapdevices],
            });
        };
    },
    updateTrapDevice: trapdevice => {
        return (dispatch, getState) => {
            dispatch({
                type: trapdeviceActions.UPDATE_TRAPDEVICE,
                trapdevice,
            });
        };
    },
    editTrapDevice: trapdevice => {
        return (dispatch, getState) => {
            dispatch({
                type: trapdeviceActions.EDIT_TRAPDEVICE,
                trapdevice,
            });
        };
    },
    getTrapDevices: (trapdevices, trapdevicesCount) => {
        return (dispatch, getState) => {
            dispatch({
                type: trapdeviceActions.GET_TRAPDEVICE_LIST,
                trapdevices,
                trapdevicesCount
            });
        };
    },
    deleteTrapDevice: id => {
        return (dispatch, getState) => {
            var currentTrapDevices = getState().trapdevice.trapdevices.filter(s => s.id !== id);
            dispatch({
                type: trapdeviceActions.DELETE_TRAPDEVICE,
                trapdevices: currentTrapDevices,
            });
        };
    },
    toggleModal: (trapdevice = null) => ({
        type: trapdeviceActions.TRAPDEVICE_TOGGLE_HANDLE_MODAL,
        trapdevice,
    }),
    toggleModal2: (trapdevice = null) => ({
        type: trapdeviceActions.TRAPDEVICE_TOGGLE2_HANDLE_MODAL,
        trapdevice,
    }),
    changePagination: pagination => {
        return (dispatch, getState) => {
            dispatch({
                type: trapdeviceActions.CHANGE_TRAPDEVICE_PAGINATION,
                pagination,
            });
        };
    },
    updateTrapDevicesCount: trapdevicesCount => {
        return (dispatch, getState) => {
            dispatch({
                type: trapdeviceActions.CHANGE_TRAPDEVICE_COUNT,
                trapdevicesCount,
            });
        };
    }

};
export default trapdeviceActions;