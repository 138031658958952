import trapdeviceActions from './actions';
const initState = {
    isLoading: false,
    errorMessage: false,
    modalActive: false,
    modal2Active: false,
    trapdevices: [],
    trapdevicesCount: 0,
    pagination: {
        currentPage: 1,
        skip: 0,
        take: 10,
        search: '',
        sort: 'id',
        desc: true,

    },
    trapdevice: {
    }
};

export default function trapdeviceReducer(state = initState, action) {
    switch (action.type) {
        case trapdeviceActions.CREATE_TRAPDEVICE:
            initState.trapdevice = {}
            return {
                ...state,
                trapdevices: action.trapdevices,
                trapdevice: initState.trapdevice
            };
        case trapdeviceActions.UPDATE_TRAPDEVICE:
            var trapdeviceIndex = state.trapdevices.findIndex(u => u.id === action.trapdevice.id);
            var trapdevices = state.trapdevices;
            trapdevices[trapdeviceIndex] = action.trapdevice;
            return {
                ...state,
                trapdevices: [...trapdevices]
            };
        case trapdeviceActions.EDIT_TRAPDEVICE:
            return {
                ...state,
                trapdevice: action.trapdevice,
            };
        case trapdeviceActions.GET_TRAPDEVICE_LIST:
            return {
                ...state,
                trapdevices: action.trapdevices,
                trapdevicesCount: action.trapdevicesCount,
            };
        case trapdeviceActions.DELETE_TRAPDEVICE:
            return {
                ...state,
                trapdevices: action.trapdevices,
            };
        case trapdeviceActions.TRAPDEVICE_TOGGLE_HANDLE_MODAL:
            return {
                ...state,
                modalActive: !state.modalActive,
                trapdevice: action.trapdevice === null ? initState.trapdevice : action.trapdevice,
            };
        case trapdeviceActions.TRAPDEVICE_TOGGLE2_HANDLE_MODAL:
            return {
                ...state,
                modal2Active: !state.modal2Active,
                trapdevice: action.trapdevice === null ? initState.trapdevice : action.trapdevice,

            };
        case trapdeviceActions.CHANGE_TRAPDEVICE_PAGINATION:
            return {
                ...state,
                pagination: { ...action.pagination },
            };
        case trapdeviceActions.CHANGE_TRAPDEVICE_COUNT:
            return {
                ...state,
                productsCount: action.productsCount,
            };
        default:
            return state;
    }
}