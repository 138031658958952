import retailSalesActions from './actions';
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  searchWarehouseProducts: [],
  retailSalesWarehouseProducts: [],
  retailSalesWarehouseProductsCount: 0,
  modalWarehouseProducts: [],
  products: [],
  warehouses: [],
  oldTotalRetailSalesPrice: 0,
  totalRetailSalesPrice: 0,
  pagination: {
    currentPage: 1,
    descending: false,
    skip: 0,
    take: 10,
    search: '',
    sort: 'name'
  }
};

export default function retailSalesReducer(state = initState, action) {
  switch (action.type) {
    case retailSalesActions.RETAIL_SALES_GET_SEARCH_WAREHOUSE_PRODUCT_LIST:
      return {
        ...state,
        searchWarehouseProducts: action.searchWarehouseProducts
      };
    case retailSalesActions.RETAIL_SALES_GET_RETAIL_SALES_WAREHOUSE_PRODUCT_LIST:
      return {
        ...state,
        retailSalesWarehouseProducts:  action.retailSalesWarehouseProducts
      };
    case retailSalesActions.RETAIL_SALES_RESET_RETAIL_SALES_WAREHOUSE_PRODUCT_LIST:
      return {
        ...state,
        retailSalesWarehouseProducts: [],
        retailSalesWarehouseProductsCount: 0
      };
    case retailSalesActions.RETAIL_SALES_GET_MODAL_WAREHOUSE_PRODUCT_LIST:
      return {
        ...state,
        modalWarehouseProducts: action.warehouseProducts
      };
    case retailSalesActions.RETAIL_SALES_ADD_RETAIL_SALES_WAREHOUSE_PRODUCT:
      return {
        ...state,
        retailSalesWarehouseProducts: [action.warehouseProduct, ...state.retailSalesWarehouseProducts],
        retailSalesWarehouseProductsCount: (state.retailSalesWarehouseProductsCount + 1)
      };
    case retailSalesActions.RETAIL_SALES_UPDATE_RETAIL_SALES_WAREHOUSE_PRODUCT:
      let newRetailSalesWarehouseProducts = state.retailSalesWarehouseProducts.map(retailSaleWarehouseProduct => {
        if (retailSaleWarehouseProduct.id === action.warehouseProduct.id) {
          return Object.assign(retailSaleWarehouseProduct, action.warehouseProduct)
        } else {
         return retailSaleWarehouseProduct
        }
      })
      return {
        ...state,
        retailSalesWarehouseProducts: newRetailSalesWarehouseProducts
      };
    case retailSalesActions.RETAIL_SALES_REMOVE_RETAIL_SALES_WAREHOUSE_PRODUCT:
      let retailSalesWarehouseProducts = state.retailSalesWarehouseProducts.filter(retailSale => retailSale.id !== action.warehouseProduct.id)
      return {
        ...state,
        retailSalesWarehouseProducts,
        retailSalesWarehouseProductsCount: (state.retailSalesWarehouseProductsCount - 1)
      }
    case retailSalesActions.RETAIL_SALES_UPDATE_RETAIL_SALES_TOTAL_PRICE:
      return {
        ...state,
        totalRetailSalesPrice: action.totalRetailSalesPrice
      }
    case retailSalesActions.RETAIL_SALES_UPDATE_OLD_RETAIL_SALES_TOTAL_PRICE:
      return {
        ...state,
        oldTotalRetailSalesPrice: action.oldTotalRetailSalesPrice
      }
    case retailSalesActions.RETAIL_SALES_TOGGLE_STOCK_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive
      }
    default:
      return state;
  }
}
