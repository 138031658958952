import clientActions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  clients: [],
  clientsCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 10,
    search: '',
    sort: ''
  },
  client: {
  }
};

export default function clientReducer(state = initState, action) {
  switch (action.type) {
    case clientActions.CREATE_CLIENT:
      initState.client = {}
      return {
        ...state,
        clients: action.clients,
        client: initState.client
      };
    case clientActions.UPDATE_CLIENT:
      var clientIndex = state.clients.findIndex(u => u.id === action.client.id);
      var clients = state.clients;
      clients[clientIndex] = action.client;
      return {
        ...state,
        clients:[...clients]
      };
    case clientActions.EDIT_CLIENT:
      return {
        ...state,
        client: action.client,
      };
    case clientActions.GET_CLIENT_LIST:
      return {
        ...state,
        clients: action.clients,
        clientsCount: action.clientsCount,
      };
    case clientActions.DELETE_CLIENT:
      return {
        ...state,
        clients: action.clients,
      };
    case clientActions.CLIENT_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        client: action.client === null ? initState.client : action.client,
      };
    case clientActions.CHANGE_CLIENT_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case clientActions.CHANGE_CLIENT_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
