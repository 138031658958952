const alesta_DocumentActions = {
  CREATE_ALESTA_DOCUMENT: "CREATE_ALESTA_DOCUMENT",
  UPDATE_ALESTA_DOCUMENT: "UPDATE_ALESTA_DOCUMENT",
  GET_ALESTA_DOCUMENT: "GET_ALESTA_DOCUMENT",
  GET_ALESTA_DOCUMENT_LIST: "GET_ALESTA_DOCUMENT_LIST",
  EDIT_ALESTA_DOCUMENT: "EDIT_ALESTA_DOCUMENT",
  DELETE_ALESTA_DOCUMENT: "DELETE_ALESTA_DOCUMENT",
  ALESTA_DOCUMENT_TOGGLE_HANDLE_MODAL: "ALESTA_DOCUMENT_TOGGLE_HANDLE_MODAL",
  CHANGE_ALESTA_DOCUMENT_PAGINATION: "CHANGE_ALESTA_DOCUMENT_PAGINATION",
  CHANGE_ALESTA_DOCUMENT_COUNT: "CHANGE_ALESTA_DOCUMENT_COUNT",
  createAlesta_Document: (alesta_Document) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_DocumentActions.CREATE_ALESTA_DOCUMENT,
        alesta_Documents: [alesta_Document, ...getState().alesta_Document.alesta_Documents],
      });
    };
  },
  updateAlesta_Document: (alesta_Document) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_DocumentActions.UPDATE_ALESTA_DOCUMENT,
        alesta_Document,
      });
    };
  },
  editAlesta_Document: (alesta_Document) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_DocumentActions.EDIT_ALESTA_DOCUMENT,
        alesta_Document,
      });
    };
  },
  getAlesta_Documents: (alesta_Documents, alesta_DocumentsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_DocumentActions.GET_ALESTA_DOCUMENT_LIST,
        alesta_Documents,
        alesta_DocumentsCount,
      });
    };
  },
  deleteAlesta_Document: (id) => {
    return (dispatch, getState) => {
      var currentAlesta_Documents = getState().alesta_Document.alesta_Documents.filter((s) => s.id !== id);
      dispatch({
        type: alesta_DocumentActions.DELETE_ALESTA_DOCUMENT,
        alesta_Documents: currentAlesta_Documents,
      });
    };
  },
  toggleModal: (alesta_Document = null) => ({
    type: alesta_DocumentActions.ALESTA_DOCUMENT_TOGGLE_HANDLE_MODAL,
    alesta_Document,
  }),
  changePagination: (pagination) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_DocumentActions.CHANGE_ALESTA_DOCUMENT_PAGINATION,
        pagination,
      });
    };
  },
  updateAlesta_DocumentsCount: (alesta_DocumentsCount) => {
    return (dispatch, getState) => {
      dispatch({
        type: alesta_DocumentActions.CHANGE_ALESTA_DOCUMENT_COUNT,
        alesta_DocumentsCount,
      });
    };
  },
};
export default alesta_DocumentActions;

